// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.displayFlex {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.details-container {
  flex: 1;
  width: 100%;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;
  flex-wrap: wrap;
  width: 100%;
}

.title-label {
  flex: 1;
  font-size: 1.42rem;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/building-details/building-details.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AACF;;AAEA;EACE,OAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,aAAA;EACA,eAAA;EACA,WAAA;AACF;;AAEA;EACE,OAAA;EAEA,kBAAA;EACA,iBAAA;AAAF","sourcesContent":[".displayFlex {\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 100%;\r\n}\r\n  \r\n.details-container {\r\n  flex: 1;\r\n  width: 100%;\r\n}\r\n\r\n.button-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  gap: 20px;\r\n  padding: 10px;\r\n  flex-wrap: wrap;\r\n  width: 100%;\r\n}\r\n\r\n.title-label {\r\n  flex: 1;\r\n  // text-align: center;\r\n  font-size: 1.42rem;\r\n  font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
