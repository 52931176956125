/* eslint-disable max-len */
import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
// User Imports
import { Customer } from '../../model/Customer';
import { UserService } from 'src/app/service/user.service';
import { CustomerService } from '../../service/customer.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss'],
})
export class CustomerListComponent implements OnInit {
  filteredCustomers: Customer[];
  isSearchEnabled = false;
  viewMode = 'tile';
  isLoading = true;
  @Output() customerListEventEmitter = new EventEmitter<number>();

  // Routing Variables
  isDoorStickerRoute = false;

  // Class Constructor
  constructor(
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router
  ) {}

  /*
    @Description: Angular function to execute after component mount
    @Comments:
    @Coders:
  */
  ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      if (params.doorStickerRoute) {
        this.isDoorStickerRoute = true;
      }
    });

    this.route.paramMap.subscribe(async (params) => {
      this.loadUpCustomers();
    });
  }
  /*
    @Description: function to get customer Service to be used in html
    @Comments:
    @Coders:
  */
  getMyCustomerService(): CustomerService {
    return this.customerService;
  }

  async loadUpCustomers() {
    // console.log('The customers are:',this.customerService.getCustomers());
    this.customerService.setCustomers(
      await this.customerService.getCustomersForUser(this.userService.getUserCollectionObj()?.customers)
    );
    this.customerListEventEmitter.emit(this.customerService.getCustomers().length);
    this.isLoading = false;
  }

  /*
    @Description: Public Method to set Customer
    @Comments:
    @Coders: jaam111000
  */
  async setCustomer(customerObject: Customer) {
    const user = this.userService.getUserCollectionObj();
    this.customerService.setSelectedCustomer(customerObject);
    // Handle Routing Scenarios
    if (this.isDoorStickerRoute) {
      this.router.navigate([customerObject.id, 'buildings'], {
        relativeTo: this.route,
        queryParams: {
          doorStickerRoute: true,
        },
      });
      return;
    }
    this.router.navigate(['../', 'customer', customerObject.id, 'view'], { relativeTo: this.route });
  }

  /*
    @Description
    @Comments:
    @Coders: jaam111000
  */
  searchChange(event: any) {
    const searchTerm = event.toLowerCase();
    if (searchTerm.length > 0) {
      this.isSearchEnabled = true;
      // console.log('Filtering your dealers...', searchTerm);
      this.filteredCustomers = this.getMyCustomerService()
        .getCustomers()
        .filter((dealer) => dealer.name.toLowerCase().includes(searchTerm));
    } else {
      this.isSearchEnabled = false;
      this.filteredCustomers = [];
    }
  }

  /*
      @Description
      @Comments:
      @Coders: jaam111000
  */
  getCurrentCustomers() {
    return this.isSearchEnabled ? this.filteredCustomers : this.customerService.getCustomers();
  }

  /*
      @Description
      @Comments:
      @Coders: jaam111000
    */
  changeViewMode(isListView: boolean): void {
    if (isListView) {
      this.viewMode = 'list';
    } else {
      this.viewMode = 'tile';
    }
  }
}
