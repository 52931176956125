import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SegmentValue } from '@ionic/angular';

@Component({
  selector: 'app-list-panel',
  templateUrl: './list-panel.component.html',
  styleUrls: ['./list-panel.component.scss'],
})
export class ListPanelComponent implements OnInit {
  @Input() titleSearchBar?: string;
  @Input() displayFlag?: string = 'true';
  displayMenu = true;
  @Output() searchTermFilterEmitter = new EventEmitter<string>();
  @Output() changeViewModeEmitter = new EventEmitter<boolean>();
  isListView = false;
  constructor() {}

  ngOnInit() {
    if (this.displayFlag !== 'true') {
      this.displayMenu = false;
    }
  }

  /*
    @Description: Public Method to
    @Comments:
    @Coders:
  */
  onSearchChange(event: any) {
    const searchTerm = event.target.value.trim().toLowerCase();
    console.log('Term to search for...', searchTerm);
    this.searchTermFilterEmitter.emit(searchTerm);
  }

  /*
    @Description: Public Method to
    @Comments:
    @Coders:
  */
  changeViewMode(mode: MouseEvent): void {
    // console.log('Changing your mode...', mode);
    this.isListView = !this.isListView;
    this.changeViewModeEmitter.emit(this.isListView);
  }
}
