import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

// User Imports
import { CustomerUser } from 'src/app/model/User';
import { CustomerService } from 'src/app/service/customer.service';

@Component({
  selector: 'app-customer-users',
  templateUrl: './customer-users-list.component.html',
  styleUrls: ['./customer-users-list.component.scss'],
})
export class CustomerUsersListComponent implements OnInit {
  users: CustomerUser[];
  isLoading = true;
  constructor(private customerService: CustomerService, private route: ActivatedRoute) {}

  /*
    @Description: Angular function to execute after component mount
    @Comments:
    @Coders:
  */
  ngOnInit() {
    this.getUsersFromService();
  }

  /*
    @Description: Public Method get all users associated to dealer
    @Comments:
    @Coders: jaam111000
  */
  getUsersFromService() {
    this.route.paramMap.subscribe(async (params) => {
      await this.customerService
        .getAllUsersForCustomer(params.get('dealerID'), params.get('customerID'))
        .then((users) => {
          this.users = users;
          this.isLoading = false;
        });
    });
  }
}
