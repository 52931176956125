/* eslint-disable max-len */
import { MenuController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

// User Imports
import { Customer } from 'src/app/model/Customer';
import { Building } from 'src/app/model/Building';
import { UserService } from 'src/app/service/user.service';
import { DealerService } from 'src/app/service/dealer.service';
import { BuildingService } from 'src/app/service/building.service';
import { CustomerService } from 'src/app/service/customer.service';
import { SecurityService } from 'src/app/service/security.service';
import { getAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customers-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss'],
})
export class CustomerDetailsComponent implements OnInit {
  isLoading = true;
  buildings: Building[] = [];
  customer: Customer | null = null;
  canAccessCustomerMenu = false;
  customerDetailsIsExpanded = false;

  // Class construtor
  constructor(
    private customerService: CustomerService,
    private buildingService: BuildingService,
    private dealerService: DealerService,
    private userService: UserService,
    private securityService: SecurityService,
    private menuCtrl: MenuController,
    private router: Router
  ) {}

  // Angular function
  ngOnInit() {
    const seletedCustomer = this.customerService.getSelectedCustomer();
    if (seletedCustomer !== null) {
      this.customer = seletedCustomer;
      this.loadPermissions();
    } else {
      throw Error('No Customer Selected...');
    }

    // Cached Object Observable
    this.customerService.customerTemp$.subscribe((customer) => {
      this.customer = customer;
    });
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  getMyDealerService() {
    return this.dealerService;
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  getMyCustomerService() {
    return this.customerService;
  }

  /*
    @Description: Public Method
    @Comments: We refer to the menu using an ID * because multiple "start" menus exist.
    @Coders: jaam111000
  */
  openThirdMenu() {
    this.menuCtrl.open('third-menu');
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  async loadPermissions() {
    if (this.userService.isUserSmartDoorAdmin()) {
    } else {
      // Load Customer/User Permissions
      const uuid = getAuth().currentUser.uid;
      const userObj = await this.customerService.getCustomerUserDocument(
        this.dealerService.getSelectedDealer().id,
        this.customer.id,
        uuid
      );
      await this.customerService.loadCustomerUserSecurity(userObj);
    }
    this.loadUpCustomerDetails();
    this.isLoading = false;
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async loadUpCustomerDetails() {
    this.buildingService.getAllBuildings().then((data) => {
      this.buildings = data;
    });
    this.isLoading = false;
    this.canAccessCustomerMenu = this.customerService.canAccessCustomerMenu();
  }

  /*
    @Description: Public Method to set Building Object
    @Comments:
    @Coders: jaam111000
  */
  setBuilding(building: Building) {
    this.buildingService.setSelectedBuilding(building);
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  toggleCustomerDetails() {
    this.customerDetailsIsExpanded = !this.customerDetailsIsExpanded;
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async navigate(path: string[]) {
    await this.router.navigate(path);
  }
}
