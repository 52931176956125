import { Component, HostListener, OnInit } from '@angular/core';

// User Imports
import { SecurityService } from 'src/app/service/security.service';

@Component({
  selector: 'app-dealer-select',
  templateUrl: './dealer-select.component.html',
  styleUrls: ['./dealer-select.component.scss'],
})
export class DealerSelectComponent implements OnInit {
  searchText = '';
  isLoading = true;

  constructor(private securityService: SecurityService) {}

  ngOnInit() {
    if (this.securityService.isUserSignedIn()) {
      // this.route.data.subscribe((data) => {
      //   if (data.title2DisplayInPage !== undefined) {
      //     this.title2DisplayInPage = data.title2DisplayInPage;
      //   } else if (data.adminMenuCustomerADDCRUDFlag !== undefined) {
      //     this.adminMenuCustomerADDCRUDFlag = data.adminMenuCustomerADDCRUDFlag;
      //   } else if (data.adminMenuBuildingCRUDFlag !== undefined) {
      //     this.adminMenuBuildingCRUDFlag = data.adminMenuBuildingCRUDFlag;
      //   }
      // });
      this.isLoading = false;
    }
  }
}
