/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { getAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { DealerUserPolicy } from 'src/app/model/UserPrivacy';

// User Imports
import { DealerService } from 'src/app/service/dealer.service';
import { SecurityService } from 'src/app/service/security.service';

@Component({
  selector: 'app-dealer-users-ae',
  templateUrl: './dealer-users-ae.component.html',
  styleUrls: ['./dealer-users-ae.component.scss'],
})
export class DealerUsersAeComponent implements OnInit {
  permissionForm: FormGroup;
  isLoading = true;
  isEditMode = false;
  tempUser: any | null = null;
  tempDealerID = '';
  tempUUID = '';
  tempNewUserPermission: any; // Object
  userPolicies: DealerUserPolicy;

  // Constructor for Component
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dealerService: DealerService,
    private securityService: SecurityService,
    private toastController: ToastController
  ) {
    this.permissionForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      userPolicies: this.fb.group({
        canCreateDealerUsers: [false],
        canUpdateDealers: [false],
        canReadDealers: [true],
        canCreateCustomers: [false],
        canUpdateDealerUsers: [false],
        canCreateDealers: [false],
        canDeleteCustomers: [false],
        canUpdateCustomers: [false],
        canReadCustomers: [true],
        canReadDealerUsers: [true],
        canDeleteDealers: [false],
        canDeleteDealerUsers: [false],
        canCreateCustomerUsers: [false],
        canReadCustomerUsers: [true],
        canUpdateCustomerUsers: [false],
        canDeleteCustomerUsers: [false],
      }),
    });
  }

  // Angular function
  ngOnInit() {
    this.route.paramMap.subscribe(async (params) => {
      this.tempDealerID = params.get('dealerID');
      if (params.get('userID') != null) {
        // Dealer User exists
        this.tempUUID = params.get('userID');
        this.tempUser = await this.dealerService.getDealerUserDocument(
          this.dealerService.getSelectedDealer().id,
          params.get('userID')
        );
        this.isLoading = false;
        this.isEditMode = true;
        this.loadUserPermissions(this.tempUser.userPolicies);
      } else {
        this.loadDefaultPermissions();
      }
    });
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  async loadUserPermissions(userPolicies: any) {
    this.userPolicies = userPolicies;
    this.permissionForm.patchValue({
      firstName: this.tempUser.firstName,
      lastName: this.tempUser.lastName,
      email: this.tempUser.email,
      userPolicies: this.userPolicies,
    });
  }

  /*
      @Description:
      @Comments:
      @Coders: jaam111000
  */
  loadDefaultPermissions() {
    const userPolicy = this.dealerService.getDefaultDealerUserPolicies();
    this.permissionForm.patchValue({ userPolicies: userPolicy });
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  async onSubmit() {
    if (this.permissionForm.invalid) {
      console.log('Invalid Input for Creating Dealer User', this.permissionForm.invalid);
      this.presentErrorToast('top');
      return;
    }

    const tempUser2Add = {
      firstName: this.permissionForm.value.firstName,
      lastName: this.permissionForm.value.lastName,
      email: this.permissionForm.value.email,
      dealerID: this.tempDealerID,
      userPolicies: this.tempNewUserPermission,
      active: false,
    };

    try {
      this.presentInitialToast('top');

      if (this.isEditMode) {
        this.dealerService.updateDealerPolicies(this.tempDealerID, this.tempUUID, this.tempNewUserPermission);
        this.delay(500);
        this.presentLastToast('top');
      } else {
        this.dealerService
          .addUserToDealer(this.tempDealerID, getAuth().currentUser.uid, tempUser2Add)
          .then((result) => {
            this.delay(500);
            this.presentLastToast('top');
          });
      }
    } catch (error) {
      console.log('Error...', error);
      this.presentErrorToast('top');
    }
    this.resetForm();
  }

  deleteDealerUser() {
    console.log('Implement dealer user delete...');
  }

  /*
    @Description: Routine to invite user
    @Comments:
    @Coders: jaam111000
  */
  savePermissions() {
    const permissionsDat = this.permissionForm.get('userPolicies').value;
    this.tempNewUserPermission = permissionsDat;
  }

  resetForm(): void {
    this.permissionForm.reset();
  }

  // ========================= Toast Form Enhancement Feature========

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async presentErrorToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'User was not saved, try again or contact support team...',
      duration: 2500,
      position,
    });

    await toast.present();
  }

  /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
    */
  async presentInitialToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'User is saving...',
      duration: 1000,
      position,
    });

    await toast.present();
  }

  /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
    */
  async presentLastToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'User has been saved...',
      duration: 2500,
      position,
    });

    await toast.present();
  }

  // Helper method to create a delay
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
