import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BarcodeService } from 'src/app/service/barcode.service';

@Component({
  selector: 'app-door-sticker-link',
  templateUrl: './door-sticker-link.component.html',
  styleUrls: ['./door-sticker-link.component.scss'],
})
export class DoorStickerLinkComponent implements OnInit {
  constructor(private route: ActivatedRoute, private barcodeService: BarcodeService, private router: Router) {}

  ngOnInit() {
    // Subscribe to the route parameters
    this.route.paramMap.subscribe((params) => {
      this.barcodeService.setSelectedDoorStickerID(params.get('doorStickerID'));
      this.router.navigate(['door-sticker', this.barcodeService.getSelectedDoorStickerID(), 'dealers'], {
        queryParams: { doorStickerRoute: true },
      });
    });
  }
}
