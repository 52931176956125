import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { SecurityService } from 'src/app/service/security.service';

@Component({
  selector: 'app-tool-bar',
  templateUrl: './app-tool-bar.component.html',
  styleUrls: ['./app-tool-bar.component.scss'],
})
export class AppToolBarComponent implements OnInit {
  //Fields

  //Class Constructor
  constructor(private securityService: SecurityService, private menu: MenuController) {}

  ngOnInit() {}

  openMenu() {
    this.menu.toggle();
  }
}
