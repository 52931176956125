/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { collection, getDocs, Firestore, doc, getDoc, setDoc, updateDoc } from '@angular/fire/firestore';

// User Imports
import { Checklist, Question } from '../model/Checklist';
import { DoorLookups } from '../model/Lookups';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  // Members
  private doorLookup: DoorLookups;
  private checklist: Checklist;

  // Constructor
  constructor(private firestore: Firestore) {}

  // Methods
  getDoorLookups(): DoorLookups {
    return this.doorLookup;
  }

  async loadDoorLookups(): Promise<DoorLookups> {
    const querySnapshot = await getDoc(doc(this.firestore, 'lookups', '6aTjJbvHYIFozAX6GW5p'));
    this.doorLookup = querySnapshot.data() as DoorLookups;

    // Extra section to store new properties
    const lookUpNew = { panelTypes: [], doorManufacturers: [] };
    lookUpNew.panelTypes = [
      { id: '1', name: 'Aluminum', applicableDoorTypes: ['1'] },
      { id: '2', name: 'Non-insulated', applicableDoorTypes: ['1'] },
      { id: '3', name: 'Polystyrene insulated', applicableDoorTypes: ['1'] },
      { id: '4', name: 'Polyurethane insulated', applicableDoorTypes: ['1'] },
      { id: '5', name: 'Wood', applicableDoorTypes: ['1'] },
    ];

    lookUpNew.doorManufacturers = [
      //sectional doors
      { id: '1', name: 'Amarr', applicableDoorTypes: ['1', '2', '6'], applicablePanelTypes: ['1', '2', '3', '4'] },
      { id: '2', name: 'C.H.I.', applicableDoorTypes: ['1', '2', '6'], applicablePanelTypes: ['1', '2', '3', '4'] },
      { id: '3', name: 'Clopay', applicableDoorTypes: ['1', '2', '6'], applicablePanelTypes: ['1', '2', '3', '4'] },
      { id: '4', name: 'Garaga', applicableDoorTypes: ['1', '2'], applicablePanelTypes: ['1', '2', '3', '4'] },
      { id: '5', name: 'IDEAL', applicableDoorTypes: ['1', '2', '6'], applicablePanelTypes: ['1', '2', '3', '4'] },
      {
        id: '6',
        name: 'Northwest Door',
        applicableDoorTypes: ['1', '2'],
        applicablePanelTypes: ['1', '2', '3', '4', '5'],
      },
      {
        id: '7',
        name: 'Overhead Door Corp',
        applicableDoorTypes: ['1', '2', '3', '4', '5', '6'],
        applicablePanelTypes: ['1', '2', '3', '4'],
      },
      {
        id: '8',
        name: 'Raynor',
        applicableDoorTypes: ['1', '2', '3', '6'],
        applicablePanelTypes: ['1', '2', '3', '4'],
      },
      { id: '9', name: 'Richards-Wilcox', applicableDoorTypes: ['1', '2'], applicablePanelTypes: ['1', '2', '4'] },
      { id: '10', name: 'Steel-Craft', applicableDoorTypes: ['1', '2', '6'], applicablePanelTypes: ['2', '4'] },
      { id: '11', name: 'Upwardor', applicableDoorTypes: ['1', '2', '6'], applicablePanelTypes: ['2', '4'] },
      {
        id: '12',
        name: 'Wayne Dalton',
        applicableDoorTypes: ['1', '2', '3', '6'],
        applicablePanelTypes: ['1', '2', '3', '4'],
      },
      {
        id: '13',
        name: "Not listed / Don't know",
        applicableDoorTypes: ['1', '2', '3', '4', '5', '6'],
        applicablePanelTypes: ['1', '2', '3', '4', '5'],
      },

      // Residential Garage Door Manufacturers
      { id: '14', name: 'Delden', applicableDoorTypes: ['1', '2'], applicablePanelTypes: ['1', '2', '3', '4'] },
      { id: '15', name: 'Garex', applicableDoorTypes: ['1', '2'], applicablePanelTypes: ['1', '2', '3', '4'] },
      { id: '16', name: 'General Doors', applicableDoorTypes: ['1', '2'], applicablePanelTypes: ['1', '2', '3', '4'] },
      { id: '17', name: 'Haas', applicableDoorTypes: ['1', '2', '6'], applicablePanelTypes: ['1', '2', '3', '4'] },
      { id: '18', name: 'Hörmann', applicableDoorTypes: ['1', '2', '6'], applicablePanelTypes: ['1', '2', '3', '4'] },
      { id: '19', name: 'Lynx', applicableDoorTypes: ['1', '2'], applicablePanelTypes: ['1', '2', '3', '4'] },
      { id: '20', name: 'Mid-America', applicableDoorTypes: ['1', '2'], applicablePanelTypes: ['1', '2', '3', '4'] },
      {
        id: '21',
        name: 'Midland Garage Door',
        applicableDoorTypes: ['1', '2'],
        applicablePanelTypes: ['1', '2', '3', '4'],
      },
      {
        id: '22',
        name: 'North Central Door',
        applicableDoorTypes: ['1', '2'],
        applicablePanelTypes: ['1', '2', '3', '4'],
      },
      { id: '23', name: 'Safe-Way Door', applicableDoorTypes: ['1', '2'], applicablePanelTypes: ['1', '2', '3', '4'] },
      { id: '24', name: 'Windsor Door', applicableDoorTypes: ['1', '2'], applicablePanelTypes: ['1', '2', '3', '4'] },

      // High Performance Doors
      { id: '25', name: 'Cookson', applicableDoorTypes: ['3'], applicablePanelTypes: ['1', '2', '3'] },
      { id: '26', name: 'Cornell', applicableDoorTypes: ['3'], applicablePanelTypes: ['1', '2', '3'] },
      { id: '27', name: 'Midland', applicableDoorTypes: ['3'], applicablePanelTypes: ['1', '2', '3'] },
      { id: '28', name: 'SDI', applicableDoorTypes: ['3'], applicablePanelTypes: ['1', '2', '3'] },

      // Grille Manufacturers
      { id: '29', name: 'General checklist', applicableDoorTypes: ['7'], applicablePanelTypes: [] },

      // Shutter Manufacturers
      { id: '30', name: 'General checklist', applicableDoorTypes: ['8'], applicablePanelTypes: [] },

      // Rolling Steel Doors
      { id: '31', name: 'Albany', applicableDoorTypes: ['6'], applicablePanelTypes: [] },
      { id: '32', name: 'Hörmann', applicableDoorTypes: ['6'], applicablePanelTypes: [] },
      { id: '33', name: 'Jamison', applicableDoorTypes: ['6'], applicablePanelTypes: [] },
      { id: '34', name: 'Rytec', applicableDoorTypes: ['6'], applicablePanelTypes: [] },
      { id: '35', name: 'TMI', applicableDoorTypes: ['6'], applicablePanelTypes: [] },
      { id: '36', name: 'TNR', applicableDoorTypes: ['6'], applicablePanelTypes: [] },
      { id: '37', name: 'General checklist', applicableDoorTypes: ['6'], applicablePanelTypes: [] },
    ];
    // const combinedObj = { ...querySnapshot.data(), ...lookUpNew };
    // const docRef = doc(this.firestore, 'lookups', '6aTjJbvHYIFozAX6GW5p');
    // updateDoc(docRef, combinedObj);
    // console.log('Data was succesfully updated...');
    return this.doorLookup;
  }

  getChecklist(): Checklist {
    return this.checklist;
  }

  async downloadLookup() {
    const data = await this.loadDoorLookups();
    this.saveJsonToFile(data, 'lookup.json');
  }

  async downloadChecklist() {
    const data = await this.loadChecklist();
    this.saveJsonToFile(data, 'checklist.json');
  }

  async loadChecklist(): Promise<Checklist> {
    const cref = await getDoc(doc(this.firestore, 'checklist', 'CYjOEnMaOwZLUfoTAVDS'));
    this.checklist = cref.data() as Checklist;
    return cref.data() as Checklist;
  }

  saveJsonToFile(data: any, filename: string) {
    const jsonStr = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonStr], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  async uploadLookupToFirestore(jsonContent: any) {
    const docRef = doc(this.firestore, 'lookups', '6aTjJbvHYIFozAX6GW5p');
    try {
      await setDoc(docRef, jsonContent);
      console.log('Document successfully written!');
    } catch (error) {
      console.error('Error writing document:', error);
    }
  }

  async uploadChecklistToFirestore(jsonContent: any) {
    const docRef = doc(this.firestore, 'checklist', 'CYjOEnMaOwZLUfoTAVDS');
    try {
      await setDoc(docRef, jsonContent);
      console.log('Document successfully written!');
    } catch (error) {
      console.error('Error writing document:', error);
    }
  }

  // function createQuestions(): Checklist {
  //   return { questions:[
  //     {
  //       support: 'Check for secure attachment and proper alignment with the door section. Ensure there are no loose or missing fasteners and that the cable is properly attached.',
  //       text: 'Bottom brackets'
  //     },
  //     {
  //       support: 'Check for cracks, tears, or gaps in the sealing material. Make sure the weather-strip is properly attached and provides a tight seal when the door is closed.',
  //       text: 'Weather-strip (bottom, side, and top)'
  //     },
  //     {
  //       support: 'Inspect for loose or missing fasteners, and ensure the hinge pivot points move freely without binding. Confirm the hinge is properly aligned with the door section.',
  //       text: 'Hinges'
  //     },
  //     {
  //       support: 'Check for flat spots or worn bearings. Make sure the roller turns freely within the track and that there is no lateral movement.',
  //       text: 'Rollers'
  //     },
  //     {
  //       support: 'Inspect for fraying, kinking, or broken strands. Check for proper tension and alignment, and ensure the cable is securely fastened to the bottom bracket and drum.',
  //       text: 'Cables'
  //     },
  //     {
  //       support: 'Examine door sections for dents, cracks, or separation. Check the condition of gaskets or seals between sections, and confirm they create a tight seal when the door is closed.',
  //       text: 'Sections, joints, gaskets'
  //     },
  //     {
  //       support: 'Inspect struts for damage or deformation. Ensure they are properly aligned, securely fastened, and provide adequate reinforcement for the door sections.',
  //       text: 'Struts'
  //     },
  //     {
  //       support: 'Check for cracks, chips, or broken glass. Confirm the window frame is securely attached to the door section and that the glazing material forms a tight seal.',
  //       text: 'Windows'
  //     },
  //     {
  //       support: 'Check for damage, misalignment, or obstructions in the track. Ensure the track is securely fastened to the wall and properly aligned with the door sections.',
  //       text: 'Vertical track, brackets'
  //     },
  //     {
  //       support: 'Inspect the track for damage, misalignment, or obstructions. Ensure it is securely fastened to the wall or ceiling and properly aligned with the vertical track.',
  //       text: 'Horizontal track, brackets'
  //     },
  //     {
  //       support: 'Confirm the back hanging is securely fastened to the ceiling and properly supports the horizontal track.',
  //       text: 'Back hanging'
  //     },
  //     {
  //       support: 'Check the condition and tension of pusher springs. Inspect bumpers and stops for damage, and ensure they are securely fastened and properly positioned.',
  //       text: 'Pusher springs, bumpers, stops'
  //     },
  //     {
  //       support: 'Inspect the shaft for damage, bending, or excessive wear. Ensure the couplers are properly connected to the shaft and securely fastened.',
  //       text: 'Head Shaft Assembly'
  //     },
  //     {
  //       support: 'Check for damage or excessive wear on the drum grooves. Ensure the cable is properly wound around the drum and securely fastened.',
  //       text: 'Drums'
  //     },
  //     {
  //       support: 'Inspect for damage, distortion, or signs of fatigue. Confirm proper tension by checking the door balance and ensure the springs are securely fastened to the anchor bracket and center support.',
  //       text: 'Torsion springs'
  //     },
  //     {
  //       support: 'Verify the door is level when closed, and check the balance by disconnecting the operator and manually lifting the door halfway. The door should remain in place without assistance.',
  //       text: 'Door level, balance'
  //     },
  //     {
  //       support: 'Test the door for smooth operation by opening and closing it manually or with the operator. Make sure it moves without binding, excessive noise, or undue force.',
  //       text: 'Door operation'
  //     },
  //     {
  //       support: 'Ensure the operator is securely fastened to the mounting pad and that the pad is properly anchored to the wall.',
  //       text: 'Operator pad and anchors'
  //     },
  //     {
  //       support: 'Check the spreader bar for damage, deformation, or loose fasteners. Ensure it is properly connected to the head shaft and operator drive shaft.',
  //       text: 'Spreader bar'
  //     },
  //     {
  //       support: 'Inspect the clutch for signs of wear, damage, or slippage. Check and adjust the force settings if necessary to ensure safe operation.',
  //       text: 'Clutch system'
  //     },
  //     {
  //       support: 'Verify the open and close limits are properly set, and adjust as needed to ensure the door stops at the correct positions.',
  //       text: 'Limits'
  //     },
  //     {
  //       support: 'Inspect the chain for wear, damage, or stretching. Ensure it moves freely through the chain hoist, and that the hoist engages when pulled and disengages the motor when the door is in manual mode.',
  //       text: 'Manual chain hoist (sash chain)'
  //     },
  //     {
  //       support: 'Check the bushings for wear, damage, or deformation. Ensure they are properly installed and provide adequate support for the moving parts.',
  //       text: 'Bushings'
  //     },
  //     {
  //       support: 'Inspect the bearings for signs of wear, damage, or excessive noise. Ensure they are properly lubricated and securely fastened.',
  //       text: 'Bearings'
  //     },
  //     {
  //       support: 'Check the sprockets for wear, damage, or missing teeth. Ensure the chain is properly engaged with the sprocket and that there is adequate tension.',
  //       text: 'Sprockets'
  //     },
  //     {
  //       support: 'Inspect the chain for wear, damage, or stretching. Ensure the chain is properly tensioned and aligned with the sprocket.',
  //       text: 'Chain'
  //     },
  //     {
  //       support: 'Check the belts for wear, damage, or fraying. Ensure they are properly tensioned and aligned with the pulleys.',
  //       text: 'Belts'
  //     },
  //     {
  //       support: 'Inspect the brake for wear or damage, and ensure it engages and disengages correctly during operation. Check for proper adjustment and secure fastening.',
  //       text: 'Brake system'
  //     },
  //     {
  //       support: 'Ensure the photo eyes are properly aligned, clean, and securely fastened. Test their functionality by blocking the beam while the door is closing, confirming that the door reverses as expected.',
  //       text: 'Photo eyes'
  //     },
  //     {
  //       support: 'Inspect the safety edge for damage, wear, or proper attachment to the door\'s bottom edge. Test its functionality by activating the door edge while the door is closing, confirming that the door reverses as expected.',
  //       text: 'Safety edge'
  //     }
  //   ]};
}
