import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerUserPolicy } from 'src/app/model/UserPrivacy';

// User Imports
import { CustomerService } from 'src/app/service/customer.service';
import { DealerService } from 'src/app/service/dealer.service';
import { SecurityService } from 'src/app/service/security.service';
import { UserService } from 'src/app/service/user.service';
import { getAuth } from '@angular/fire/auth';
import { ToastController } from '@ionic/angular';
@Component({
  selector: 'app-customer-users-ae',
  templateUrl: './customer-users-ae.component.html',
  styleUrls: ['./customer-users-ae.component.scss'],
})
export class CustomerUsersAeComponent implements OnInit {
  userForm: FormGroup;
  isLoading = true;
  dealerID = '';
  customerID = '';
  uuID = '';
  createMode = false;
  tempNewUserPermission: any; // Object
  tempUser: any | null = null;
  displayTitle = '';
  userPolices: CustomerUserPolicy;
  getCustomerUserPolicies: any;

  // Constructor for Component
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dealerService: DealerService,
    private userService: UserService,
    private customerService: CustomerService,
    private securityService: SecurityService,
    private toastController: ToastController
  ) {
    this.userForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      userPolicies: this.formBuilder.group({
        canCreateCustomerUsers: [false],
        canReadCustomerUsers: [true],
        canUpdateCustomerUsers: [false],
        canDeleteCustomerUsers: [false],
        canCreateBuildings: [false],
        canDeleteBuildings: [false],
        canReadBuildings: [true],
        canUpdateBuildings: [false],
        canCreateDoors: [false],
        canDeleteDoors: [false],
        canReadDoors: [true],
        canUpdateDoors: [false],
        canReadInspections: [false],
        canUpdateInspections: [false],
        canDeleteInspections: [false],
        canCreateInspections: [false],
      }),
    });
  }

  // Angular function
  ngOnInit() {
    this.route.paramMap.subscribe(async (params) => {
      const user = this.userService.getUserCollectionObj();
      // console.log('Your params', params);
      this.dealerID = params.get('dealerID');
      this.customerID = params.get('customerID');
      this.uuID = params.get('uuid');
      if (this.uuID == null) {
        this.displayTitle = 'Adding User';
        this.createMode = true;
        this.tempNewUserPermission = null;
        this.loadDefaultPermissions();
      } else {
        this.tempUser = await this.customerService.getCustomerUserDocument(
          this.dealerID,
          this.customerID,
          getAuth().currentUser.uid
        );
        this.tempNewUserPermission = this.tempUser.userPolicies;
        this.loadUserPermissions();
      }
      this.isLoading = false;
    });
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  async loadUserPermissions() {
    this.userPolices = this.customerService.getCustomerUserPolicies();
    this.userForm.patchValue({
      email: this.tempUser.email,
      userPolicies: this.userPolices,
    });
  }

  /*
      @Description:
      @Comments:
      @Coders: jaam111000
    */
  loadDefaultPermissions() {
    const userPolicy = this.customerService.getDefaultCustomerPolicies();
    this.userForm.patchValue({ userPolicies: userPolicy });
  }

  /*
    @Description: Routine to invite user
    @Comments:
    @Coders: jaam111000
  */
  savePermissions() {
    const permissionsDat = this.userForm.get('userPolicies').value;
    this.tempNewUserPermission = permissionsDat;
    // console.log('Your new user permissions are:', this.tempNewUserPermission);
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  async onSubmit() {
    if (this.userForm.invalid) {
      console.log('Invalid Input for Creating Dealer', this.userForm.invalid);
      this.presentErrorToast('top');
      return;
    }

    const tempUser2Add = {
      email: this.userForm.value.email,
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      dealerID: this.dealerID,
      customerID: this.customerID,
      userPolicies: this.tempNewUserPermission,
      active: false,
    };

    try {
      this.presentInitialToast('top');
      if (this.createMode) {
        this.customerService.addUserToCustomer(this.dealerID, this.customerID, tempUser2Add).then(async (result) => {
          await this.delay(250); // Wait for 500ms to appreciate the first toast
          this.presentLastToast('top');
        });
        this.router.navigate(['./home']);
      } else {
        this.customerService.updateCustomerUserPermissions(
          this.dealerID,
          this.customerID,
          this.uuID,
          this.tempNewUserPermission
        );
        await this.delay(250); // Wait for 500ms to appreciate the first toast
        this.presentLastToast('top');
      }
    } catch (error) {
      console.log('errrr...', error);
      this.presentErrorToast('top');
    }
  }

  /*
    @Description:
    @Comments: TODO-Implement Customer-ae delete
    @Coders: jaam111000
  */
  deleteCustomerUser() {
    console.log('Implement delete....');
  }
  // ========================= Toast Form Enhancement Feature========

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async presentErrorToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'User was not saved, try again or contact support team...',
      duration: 2500,
      position,
    });

    await toast.present();
  }

  /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
    */
  async presentInitialToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'User is saving...',
      duration: 1000,
      position,
    });

    await toast.present();
  }

  /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
    */
  async presentLastToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'User has been saved...',
      duration: 2500,
      position,
    });

    await toast.present();
  }

  // Helper method to create a delay
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
