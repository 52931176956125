import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { Auth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;

  // Constructor for Class
  constructor(
    fb: UntypedFormBuilder,
    public alertController: AlertController,
    public auth: Auth,
    private router: Router
  ) {
    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  // Funtion to Handle Log In after login button is pressed
  async onLogin() {
    // Handle invalid input
    if (!this.loginForm.valid) {
      const alert = await this.alertController.create({
        message: 'Fill the form',
        buttons: ['OK'],
      });
      await alert.present();
    }

    try {
      const { user } = await signInWithEmailAndPassword(
        this.auth,
        this.loginForm.value.email,
        this.loginForm.value.password
      );
      // console.log('the user who is trying to sign-in..', user);
      this.router.navigateByUrl('/home');
    } catch (error) {
      console.log('Error while user tried to sign in...', error);
      const alert = await this.alertController.create({
        message: error.message,
        buttons: ['OK'],
      });
      await alert.present();
    }
  }

  // Funtion to Handle Log In after Continue with Google Button is pressed
  async onGoogleLogin() {
    const provider = new GoogleAuthProvider();
    const user = await signInWithPopup(this.auth, provider);
    console.log('the user who is trying to sign-in with Google...', user);
    this.router.navigateByUrl('/home');
  }

  // Callback method invoked only once when the directive is instantiated.
  ngOnInit() {}
}
