import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// User Imports
import { ToastController } from '@ionic/angular';
import { Customer } from 'src/app/model/Customer';
import { DealerService } from 'src/app/service/dealer.service';
import { CustomerService } from 'src/app/service/customer.service';
import { GeolocationService } from 'src/app/service/geolocation.service';
import { AddressGoogle } from 'src/app/model/AddressGoogle';

@Component({
  selector: 'app-customer-ae',
  templateUrl: './customer-ae.component.html',
  styleUrls: ['./customer-ae.component.scss'],
})
export class CustomerAeComponent implements OnInit {
  isCreateMode = false;
  tempCustomer: Customer;
  customerForm: UntypedFormGroup;
  customerID: string;

  // Google Places API
  suggestions: Array<any> = [];
  suggestedAddress = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private customerService: CustomerService,
    private dealerService: DealerService,
    private router: Router,
    private route: ActivatedRoute,
    private toastController: ToastController,
    private geolocationService: GeolocationService
  ) {}

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  ngOnInit() {
    this.buildForm();
    if (this.route.snapshot.queryParams.createRoute) {
      this.isCreateMode = true;
    }

    this.route.paramMap.subscribe(async (params) => {
      if (params.get('customerID') == null) {
        this.isCreateMode = true;
      } else {
        this.customerID = params.get('customerID');
        this.getCustomer2Edit();
      }
    });

    // Instantiate Temp Object
    this.tempCustomer = {
      id: '',
      name: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      notes: '',
      active: true,
      address: {
        displayName: '',
        formattedAddress: '',
        id: '',
        longitude: '',
        latitude: '',
        googleMapsUri: '',
      },
    };
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async presentInitialToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'Customer is saving...',
      duration: 1000,
      position,
    });

    await toast.present();
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async presentLastToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'Customer has been saved...',
      duration: 2500,
      position,
    });

    await toast.present();
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async presentErrorToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'Customer was not saved, try again or contact support team...',
      duration: 2500,
      position,
    });

    await toast.present();
  }

  // ======================= Google Places Feature ==========

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  onInput(event: any): void {
    const query = event.target.value;
    if (query.length > 0) {
      this.fetchSuggestionsFromService(query);
    } else {
      this.suggestions = [];
    }
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  async fetchSuggestionsFromService(event: string) {
    // this.suggestions = await this.geolocationService.fetchSuggestions(event);
    this.suggestions = await this.geolocationService.fetchSuggestionsAutoComplete(event);
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  async selectSuggestion(suggestion: any): Promise<void> {
    this.suggestions = [];
    this.suggestedAddress = suggestion.Eg.di['0'][2][0];
    this.customerForm.controls.address.setValue(this.suggestedAddress); // Update the form

    // TURN Suggestion Into Places
    const placeTemp = await this.geolocationService.convertToPlace(suggestion);

    // Store Other Address fields in Dealer Object
    this.tempCustomer.address.formattedAddress = placeTemp.place.formattedAddress;
    this.tempCustomer.address.displayName = placeTemp.place.displayName;
    this.tempCustomer.address.id = placeTemp.place.id;
    this.tempCustomer.address.longitude = placeTemp.place.location.lng();
    this.tempCustomer.address.latitude = placeTemp.place.location.lat();

    // Generate Google Maps URI using longitude and latitude
    // eslint-disable-next-line max-len
    this.tempCustomer.address.googleMapsUri = `https://www.google.com/maps/search/?api=1&query=${placeTemp.place.location.lat()},${placeTemp.place.location.lng()}`;
  }

  // ========================== End ================

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  getMyDealerService() {
    return this.dealerService;
  }
  /*
      @Description:
      @Comments:
      @Coders: jaam111000
    */
  getMyCustomerService() {
    return this.customerService;
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  buildForm(): void {
    this.customerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      address: ['', Validators.required],
      notes: [''],
    });
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  getCustomer2Edit(): boolean {
    this.tempCustomer = this.customerService.getSelectedCustomer();
    if (this.tempCustomer == null) {
      console.log('Error while getting your selected custonmer...');
      return false;
    }
    this.customerForm.setValue({
      name: this.tempCustomer.name,
      email: this.tempCustomer.email,
      phone: this.tempCustomer.phone,
      address: this.tempCustomer.address.formattedAddress,
      firstName: this.tempCustomer.firstName,
      lastName: this.tempCustomer.lastName,
      notes: this.tempCustomer.notes,
    });
    return true;
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async onSubmit() {
    this.presentInitialToast('top'); // Calling Toast to Show User Format Was Valid

    // Handle Form Inputs
    this.tempCustomer.name = this.customerForm.get('name').value;
    this.tempCustomer.email = this.customerForm.get('email').value;
    this.tempCustomer.phone = this.customerForm.get('phone').value;
    this.tempCustomer.firstName = this.customerForm.get('firstName').value;
    this.tempCustomer.lastName = this.customerForm.get('lastName').value;
    this.tempCustomer.notes = this.customerForm.get('notes').value;

    try {
      if (this.isCreateMode) {
        this.addCustomer(this.tempCustomer).then(async (result) => {
          await this.delay(250); // Wait for 500ms to appreciate the first toast
          this.presentLastToast('top'); // Calling Toast to show form was saved in backend without problems
        });
      } else {
        this.tempCustomer.id = this.customerID;
        await this.customerService
          .updateCustomer(this.tempCustomer, this.dealerService.getSelectedDealer().id, this.customerID)
          .then(async (result) => {
            await this.delay(250); // Wait for 500ms to appreciate the first toast
            this.presentLastToast('top'); // Calling Toast to show form was saved in backend without problems
          });
      }
    } catch (error) {
      console.log('Error when adding customer...', error);
      this.presentErrorToast('middle'); // Calling Toast to show inform about erorr
    }
  }

  /*
    @Description: Public Method to help appreciate toasts
    @Comments:
    @Coders: jaam111000
  */
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async addCustomer(customer: Customer): Promise<void> {
    const createdCustomer = await this.customerService.createCustomer(customer);
    this.router.navigate(['../', createdCustomer.id, 'view'], { relativeTo: this.route });
    customer.id = createdCustomer.id;
    this.customerService.setSelectedCustomer(customer);
    this.customerForm.reset();
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async deleteCustomer() {
    // Handle Form Inputs
    this.tempCustomer.name = this.customerForm.get('name').value;
    this.tempCustomer.email = this.customerForm.get('email').value;
    this.tempCustomer.phone = this.customerForm.get('phone').value;
    this.tempCustomer.firstName = this.customerForm.get('firstName').value;
    this.tempCustomer.lastName = this.customerForm.get('lastName').value;
    this.tempCustomer.notes = this.customerForm.get('notes').value;
    this.tempCustomer.active = false;

    await this.customerService.updateCustomer(
      this.tempCustomer,
      this.dealerService.getSelectedDealer().id,
      this.customerID
    );
    this.router.navigate(['./home']);
  }
  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async navigate(path: string[]) {
    await this.router.navigate(path);
  }
}
