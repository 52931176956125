import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InspectionItem } from 'src/app/model/SafetyInspection';

@Component({
  selector: 'app-inspection-item-status',
  templateUrl: './inspection-item-status.component.html',
  styleUrls: ['./inspection-item-status.component.scss'],
})
export class InspectionItemStatusComponent implements OnInit {
  //@Input() status: 'passed' | 'failed' | 'not-applicable' | 'unchecked' = 'unchecked';
  @Input() inspectionItem: InspectionItem; // 'passed' | 'failed' | 'not-applicable' | 'unchecked' = 'unchecked';
  @Input() canEdit: boolean;
  @Output() statusChange = new EventEmitter<InspectionItem>();

  constructor() {}

  ngOnInit(): void {}

  toggleStatus() {
    switch (this.inspectionItem.status) {
      case 'unchecked':
        this.inspectionItem.status = 'passed';
        break;
      case 'passed':
        this.inspectionItem.status = 'failed';
        break;
      case 'failed':
        this.inspectionItem.status = 'not-applicable';
        break;
      case 'not-applicable':
        this.inspectionItem.status = 'passed';
        break;
    }
    this.statusChange.emit(this.inspectionItem);
  }

  getIconName(): string {
    switch (this.inspectionItem.status) {
      case 'passed':
        return 'checkmark';
      case 'failed':
        return 'close';
      case 'not-applicable':
        return 'ban';
      default:
        return 'square-outline';
    }
  }
}
