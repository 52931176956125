import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth, connectAuthEmulator } from '@angular/fire/auth';
import { provideFirestore, getFirestore, connectFirestoreEmulator } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { HttpClientModule } from '@angular/common/http';

// User Components & Pages
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { HomeComponent } from './pages/home/home.component';
// import { BuildingListComponent } from './pages/admin-building-list/building-list.component';
import { BuildingAeComponent } from './pages/building-ae/building-ae.component';
// import { CustomerListComponent } from './pages/admin-customer-list/customer-list.component';
import { DealerSelectComponent } from './pages/dealer-select/dealer-select.component';
import { DoorListComponent } from './pages/door-list/door-list.component';
import { DoorDetailsComponent } from './pages/door-details/door-details.component';
import { SafetyInspectionComponent } from './pages/safety-inspection/safety-inspection.component';
import { InspectionItemComponent } from './pages/inspection-item/inspection-item.component';
import { InspectionItemStatusComponent } from './pages/inspection-item-status/inspection-item-status.component';
import { DealerUsersComponent } from './pages/dealer-users/dealer-users.component';
import { DealerDetailsComponent } from './pages/dealer-details/dealer-details.component';
import { CustomerDetailsComponent } from './pages/customer-details/customer-details.component';
import { QrScannerComponent } from './components/qr-scanner/qr-scanner.component';
import { CustomerUsersListComponent } from './pages/customer-users-list/customer-users-list.component';
import { CustomerListComponent } from './pages/customer-list/customer-list.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AdminComponent } from './pages/admin/admin.component';
import { AppToolBarComponent } from './components/app-tool-bar/app-tool-bar.component';
import { PictureModalComponent } from './pages/picture-modal/picture-modal.component';
import { AppSideBarComponent } from './components/app-side-bar/app-side-bar.component';
import { AdminStickerComponent } from './pages/admin-sticker/admin-sticker.component';
import { DoorStickerLinkComponent } from './pages/door-sticker-link/door-sticker-link.component';
import { DoorStickerLinkEndComponent } from './pages/door-sticker-link-end/door-sticker-link-end.component';
import { DoorAEComponent } from './pages/door-ae/door-ae.component';
import { DealerAeComponent } from './pages/dealer-ae/dealer-ae.component';
import { CustomerAeComponent } from './pages/customer-ae/customer-ae.component';
import { CustomerUsersAeComponent } from './pages/customer-users-ae/customer-users-ae.component';
import { DealerUsersAeComponent } from './pages/dealer-users-ae/dealer-users-ae.component';
import { ImageViewerComponent } from './pages/image-viewer/image-viewer.component';
import { ListPanelComponent } from './components/list-panel/list-panel.component';
import { BuildingListComponent } from './pages/building-list/building-list.component';
import { BuildingDetailsComponent } from './pages/building-details/building-details.component';
import { DealerListComponent } from './pages/dealer-list/dealer-list.component';
import { OpenAIService } from './service/openai.service';
import { ChatService } from './service/chat.service';
import { ChatFabComponent } from './components/chat-fab/chat-fab.component';

/*
    @description: App Module Class using Angular Module Decorator
    @comments:
    @coders:
*/
@NgModule({
  // This array lists all the components, directives, and pipes that belong to this module.
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    HomeComponent,
    BuildingAeComponent,
    DealerSelectComponent,
    DoorListComponent,
    DoorAEComponent,
    DoorDetailsComponent,
    SafetyInspectionComponent,
    InspectionItemComponent,
    InspectionItemStatusComponent,
    DealerUsersComponent,
    DealerDetailsComponent,
    CustomerDetailsComponent,
    QrScannerComponent,
    CustomerUsersListComponent,
    CustomerListComponent,
    AdminComponent,
    AppToolBarComponent,
    PictureModalComponent,
    AppSideBarComponent,
    AdminStickerComponent,
    DoorStickerLinkComponent,
    DoorStickerLinkEndComponent,
    DealerAeComponent,
    CustomerAeComponent,
    CustomerUsersAeComponent,
    DealerUsersAeComponent,
    ImageViewerComponent,
    ListPanelComponent,
    BuildingListComponent,
    BuildingDetailsComponent,
    DealerListComponent,
    ChatFabComponent,
  ],
  // This array contains other modules whose exported classes are needed by component templates
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.useEmulator) {
        connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
      }
      return auth;
    }),
    provideFirestore(() => {
      const firestore = getFirestore(); // To enable multi-db, write db name here
      if (environment.useEmulator) {
        connectFirestoreEmulator(firestore, '127.0.0.1', 8081);
      }
      return firestore;
    }),
    provideStorage(() => getStorage()),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DatePipe,
    OpenAIService,
    ChatService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
