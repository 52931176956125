import { Component, OnInit } from '@angular/core';
import { BarcodeService } from 'src/app/service/barcode.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin-sticker',
  templateUrl: './admin-sticker.component.html',
  styleUrls: ['./admin-sticker.component.scss'],
})
export class AdminStickerComponent implements OnInit {
  // Class Members
  form: FormGroup;

  // Class Constructor
  constructor(private formBuilder: FormBuilder, private barcodeService: BarcodeService) {
    this.form = this.formBuilder.group({
      numberOfStickers: [0, [Validators.required, Validators.min(1)]],
    });
  }

  ngOnInit() {
    // this.form.get('numberOfStickers').valueChanges.subscribe((value) => {
    //   console.log('Value Changed:', value);
    // });
  }

  // Function to call service to generate stickers after button press
  generateStickers() {
    if (this.form.valid) {
      const numberOfStickers = this.form.get('numberOfStickers').value;
      this.barcodeService.generateStickers(numberOfStickers);
      // Your logic to generate QR codes using the numberOfStickers value
    } else {
      console.log('Form is invalid');
    }
  }
}
