/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { getAuth } from '@angular/fire/auth';
import {
  addDoc,
  getDocs,
  setDoc,
  doc,
  collection,
  Firestore,
  DocumentReference,
  DocumentData,
  getDoc,
  updateDoc,
  documentId,
  query,
  where,
} from '@angular/fire/firestore';

// User Imports
import { Customer } from '../model/Customer';
import { CustomerUser } from '../model/User';
import { DealerService } from './dealer.service';
import { SecurityService } from './security.service';
import { UserService } from './user.service';
import { CustomerUserPolicy, DealerUserPolicy, UserPrivacyObject } from '../model/UserPrivacy';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly CUSTOMERS_COLLECTION = 'customers';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly NESTED_USERS_COLLECTION = 'users';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly NESTED_INVITATIONS_COLLECTION = 'tempInvites';

  // Class Variables
  private customer: Customer = null;
  private customers: Customer[] = [];

  // State Observable
  public customerSubject = new BehaviorSubject<any>(null);
  customerTemp$ = this.customerSubject.asObservable();

  // Constructor
  constructor(private firestore: Firestore, private dealerService: DealerService, private userService: UserService) {}

  /*
    @Description: Public Method to
    @Comments:
    @Coders:
  */
  setSelectedCustomer(id: Customer): void {
    this.customerSubject.next(id);
    this.customer = id;
  }

  /*
    @Description: Public Method to
    @Comments:
    @Coders:
  */
  getSelectedCustomer(): Customer {
    return this.customer;
  }
  /*
    @Description: Public Method to get Customers
    @Comments:
    @Coders:Rohan-16
  */
  getCustomers(): Customer[] {
    return this.customers;
  }
  /*
  @Description: Public Method to set customers
  @Comments:
  @Coders:
  */
  setCustomers(customers: Customer[]): void {
    this.customers = customers;
  }
  /*
  @Description: Public Method to Update customers
  @Comments:
  @Coders:
  */
  updateCustomers(customer: Customer): void {
    this.customers.push(customer);
  }

  /*
    @Description: Public Method to
    @Comments:
    @Coders: jaam111000
  */
  createCustomer(customer: Customer): Promise<DocumentReference<DocumentData>> {
    const tempDealerID = this.dealerService.getSelectedDealer().id;
    if (tempDealerID == null) {
      // console.log('get dealerID from route...');
      throw Error('No dealerID');
    }
    try {
      this.setSelectedCustomer(customer);
      // console.log('The customer you are trying to add is...', customer);
      const dealerRef = doc(this.firestore, DealerService.DEALERS_COLLECTION, tempDealerID);
      this.updateCustomers(customer);
      return addDoc(collection(dealerRef, CustomerService.CUSTOMERS_COLLECTION), customer);
    } catch (error) {
      console.log('Failed to create customer....', error);
    }
  }
  /*
    @Description: Public Method to retrieve all of our customers using dealerID
    @Comments:
    @Coders: AllanSmith
  */
  async getAllCustomers(): Promise<Customer[]> {
    try {
      // Get customers collection under the dealer
      const dealerRef = doc(
        this.firestore,
        DealerService.DEALERS_COLLECTION,
        this.dealerService.getSelectedDealer().id
      );
      const querySnapshot = await getDocs(collection(dealerRef, CustomerService.CUSTOMERS_COLLECTION));

      // Map query snapshot to Customer objects
      this.customers = querySnapshot.docs.map((tempDoc) => {
        const id = tempDoc.id;
        const data = tempDoc.data();
        data.id = id;
        return data;
      }) as Customer[];
      return this.customers;
    } catch (error) {
      // Handle error
      console.error('An error occurred while fetching customers:', error);
      throw error;
    }
  }

  /*
    @Description: Public Method called by Customer-List-Nested to get back customers depending on permissions level
    @Comments:
    @Coders: jaam111000
  */
  async getCustomersForUser(customerRefs: any): Promise<Customer[]> {
    try {
      if (this.userService.isUserSmartDoorAdmin()) {
        return await this.getAllCustomers();
      } else {
        if (this.dealerService.canReadCustomers() || customerRefs !== undefined) {
          return await this.getDealerCustomers(customerRefs);
        }
      }
    } catch (error) {
      console.log('Error in getting customers from service...', error);
    }
  }

  /*
    @Description: Public Method to get Permissions
    @Comments:
    @Coders: jaam111000
  */
  getCustomerUserPolicies(): CustomerUserPolicy {
    return this.userService.getUserCustomerPolicy();
  }
  /*
    @Description: Public Method to retrieve all of our customers using dealerID
    @Comments:
    @Coders: jaam111000
  */
  async getDealerCustomers(customerRefs: any[]): Promise<Customer[]> {
    const user = this.userService.getUserCollectionObj();
    let querySnapshot;
    try {
      const collectionRef = collection(
        this.firestore,
        DealerService.DEALERS_COLLECTION,
        this.dealerService.getSelectedDealer().id,
        CustomerService.CUSTOMERS_COLLECTION
      );
      if (user.customers) {
        const q = query(collectionRef, where(documentId(), 'in', customerRefs), where('active', '==', true));
        querySnapshot = await getDocs(q);
      } else {
        const q = query(collectionRef, where('active', '==', true));
        querySnapshot = await getDocs(q);
      }

      // Map query snapshot to Customer objects
      this.customers = querySnapshot.docs.map((tempDoc) => {
        const id = tempDoc.id;
        const data = tempDoc.data();
        data.id = id;
        return data;
      }) as Customer[];
      return this.customers;
    } catch (error) {
      console.log('Error while getting all dealer customers...', error);
    }
  }

  /*
        @Description: Public Method
        @Comments:
        @Coders: jaam111000
      */
  canUpdateCustomerUsers(): boolean {
    try {
      if (
        this.userService.isUserSmartDoorAdmin() ||
        this.userService.getUserCustomerPolicy()?.canUpdateCustomerUsers ||
        (this.userService.getUserCollectionObj().customers === undefined && this.dealerService.canUpdateCustomerUsers())
      ) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can update customer users', error);
    }
    return;
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  canCreateBuildings(): boolean {
    try {
      if (
        this.userService.isUserSmartDoorAdmin() ||
        this.userService.getUserCustomerPolicy()?.canCreateBuildings ||
        (this.userService.getUserCollectionObj().customers === undefined && this.dealerService.canCreateCustomers())
      ) {
        return true;
      }

      return false;
    } catch (error) {
      console.log('Error while checking if user can add buildings');
    }
  }
  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  canReadBuildings(): boolean {
    try {
      if (
        this.userService.isUserSmartDoorAdmin() ||
        this.userService.getUserCustomerPolicy()?.canReadBuildings ||
        (this.userService.getUserCollectionObj().customers === undefined && this.dealerService.canReadCustomers())
      ) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can read buildings');
    }
    return false;
  }
  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  canUpdateBuildings(): boolean {
    try {
      if (
        this.userService.isUserSmartDoorAdmin() ||
        this.userService.getUserCustomerPolicy()?.canUpdateBuildings ||
        (this.userService.getUserCollectionObj().customers === undefined && this.dealerService.canUpdateCustomers())
      ) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can update buildings');
    }
    return;
  }
  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  canDeleteBuildings(): boolean {
    try {
      if (
        this.userService.isUserSmartDoorAdmin() ||
        this.userService.getUserCustomerPolicy()?.canDeleteBuildings ||
        (this.userService.getUserCollectionObj().customers === undefined && this.dealerService.canDeleteCustomers())
      ) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can delete buildings');
    }
    return;
  }

  /*
    @Description: Public Method to
    @Comments:
    @Coders:
  */
  updateCustomer(customer: Customer, dealerID: string, customerID: string): Promise<void> {
    const customerRef = doc(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      dealerID,
      CustomerService.CUSTOMERS_COLLECTION,
      customerID
    );
    const selectedCustomer = setDoc(customerRef, customer, { merge: true });
    this.customerSubject.next(customer);
    this.setSelectedCustomer(customer);
    return selectedCustomer;
  }

  /*
    @Description: Function to get your Customer's users using the DealerID & CustomerID
    @Comments:
    @Coders: jaam111000
  */
  async getAllUsersForCustomer(dealerID: string, customerID: string): Promise<CustomerUser[]> {
    const docRef = collection(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      dealerID,
      CustomerService.CUSTOMERS_COLLECTION,
      customerID,
      CustomerService.NESTED_USERS_COLLECTION
    );
    const docSnap = await getDocs(docRef);
    return docSnap.docs.map((tempDoc) => {
      const id = tempDoc.id;
      const data = tempDoc.data();
      data.id = id;
      return data;
    }) as CustomerUser[];
  }

  /*
    @Description: Function to get your Customer's users using the DealerID & CustomerID & CustomUser.Email
    @Comments:
    @Coders: jaam111000
  */
  async getCustomerUserDocument(dealerID: string, customerID: string, customerUserID: string): Promise<any> {
    try {
      const docRef = doc(
        this.firestore,
        DealerService.DEALERS_COLLECTION,
        dealerID,
        CustomerService.CUSTOMERS_COLLECTION,
        customerID,
        CustomerService.NESTED_USERS_COLLECTION,
        customerUserID
      );
      const docSnap = (await getDoc(docRef)).data();
      return docSnap;
    } catch (error) {
      console.log('no user found in customer..', error);
    }
  }

  /*
    @Description: Function to get your Customer's users using the DealerID & CustomerID & CustomUser.Email
    @Comments:
    @Coders: jaam111000
  */
  async getCustomer(dealerID: string, customerID: string): Promise<Customer> {
    try {
      const docRef = doc(
        this.firestore,
        DealerService.DEALERS_COLLECTION,
        dealerID,
        CustomerService.CUSTOMERS_COLLECTION,
        customerID
      );
      const docSnap = (await getDoc(docRef)).data() as Customer;
      return docSnap;
    } catch (error) {
      console.log('no customer..', error);
    }
  }

  /*
    @Description: Function to add user to nested customer collection
    @Comments:
    @Coders: jaam111000
  */
  async addUserToCustomer(dealerID: string, customerID: string, document2Insert: any) {
    // const docRef = doc(
    //   this.firestore,
    //   DealerService.DEALERS_COLLECTION,
    //   dealerID,
    //   CustomerService.CUSTOMERS_COLLECTION,
    //   customerID,
    //   CustomerService.NESTED_USERS_COLLECTION,
    //   getAuth().currentUser.uid
    // );
    // return setDoc(docRef, document2Insert);
    const tempInvitesCollectionRef = collection(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      dealerID,
      CustomerService.CUSTOMERS_COLLECTION,
      customerID,
      CustomerService.NESTED_INVITATIONS_COLLECTION
    );
    try {
      const docRef = await addDoc(tempInvitesCollectionRef, document2Insert);
      console.log('Document successfully written with ID: ', docRef.id);
      return docRef.id;
    } catch (error) {
      console.error('Error adding document: ', error);
      throw error;
    }
  }

  /*
    @Description: Function to update user information

    @Coders: jaam111000
  */
  updateCustomerUserPermissions(dealerID: string, customerID: string, customerUserID: string, permissionObject: any) {
    const docRef = doc(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      dealerID,
      CustomerService.CUSTOMERS_COLLECTION,
      customerID,
      CustomerService.NESTED_USERS_COLLECTION,
      customerUserID
    );
    updateDoc(docRef, { userPolicies: permissionObject });
  }

  /*
    @Description: Function to filter list of customers depending if email is in customer user collection
     @Comments:
    @Coders: jaam111000
  */
  async filterCustomersForUser(customers: Customer[]): Promise<Customer[]> {
    const finalCustomers = [];
    for (const customer of customers) {
      if (!customer.active) {
        return;
      }
      const tempCustomerUserPool = await this.getAllUsersForCustomer(
        this.dealerService.getSelectedDealer().id,
        customer.id
      );

      let addCustomerToAR = false;
      for (const user of tempCustomerUserPool) {
        if (user.email === getAuth().currentUser.email) {
          addCustomerToAR = true;
          break;
        }
      }

      if (addCustomerToAR) {
        finalCustomers.push(customer);
      }
    }
    return finalCustomers;
  }

  /*
    @Description: Function to return customer from doc ref
     @Comments:
    @Coders: Rohan-16
  */
  async getCustomerFromDocRef(id: DocumentReference): Promise<any> {
    const customerDoc = id.path.split('/');
    try {
      const docRef = doc(this.firestore, customerDoc[0], customerDoc[1], customerDoc[2], customerDoc[3]);
      const docSnap = (await getDoc(docRef)).data();
      return docSnap;
    } catch (error) {
      console.log('Error in getting docs:', error);
    }
  }
  /*
    @Description: Function to get customer user
    @Comments:
    @Coders: Rohan-16 && jaam111000
  */
  async loadCustomerUserSecurity(customerUserObj: any): Promise<void> {
    try {
      if (customerUserObj) {
        this.userService.setUserCustomerPolicy(customerUserObj.userPolicies);
      }
    } catch (error) {
      console.log('Error loading up customer user security:', error);
    }
  }
  /*
    @Description: Public Method to get default policies
    @Comments:
    @Coders: jaam111000
  */
  getDefaultCustomerPolicies(): CustomerUserPolicy {
    return {
      // canCreateCustomers: false,
      // canUpdateCustomers: false,
      // canDeleteCustomers: false,
      // canReadCustomers: false,
      canCreateCustomerUsers: false,
      canReadCustomerUsers: false,
      canUpdateCustomerUsers: false,
      canDeleteCustomerUsers: false,
      canCreateBuildings: false,
      canDeleteBuildings: false,
      canReadBuildings: false,
      canUpdateBuildings: false,
      canCreateDoors: false,
      canDeleteDoors: false,
      canReadDoors: false,
      canUpdateDoors: false,
      canReadInspections: false,
      canUpdateInspections: false,
      canDeleteInspections: false,
      canCreateInspections: false,
    };
  }

  /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
    */
  canAccessCustomerMenu(): boolean {
    try {
      const canAccessBuildingAdd = this.canCreateBuildings();
      const canAccessBuildingViewAll = this.canUpdateBuildings();
      const canAccessCustomerUserAdd = this.dealerService.canCreateCustomerUsers();
      const canAccessCustomerUserViewAll = this.dealerService.canUpdateCustomerUsers();
      const canAccessCustomerEdit = this.dealerService.canUpdateCustomers();

      if (
        canAccessBuildingAdd ||
        canAccessBuildingViewAll ||
        canAccessCustomerUserAdd ||
        canAccessCustomerUserViewAll ||
        canAccessCustomerEdit
      ) {
        return true;
      }

      return false;
    } catch (error) {
      console.log('Error while checking if user can access dealer menu:', error);
    }
  }
}
