import { Auth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { doc, Firestore, addDoc, collection, updateDoc, getDoc, getDocs, Timestamp } from '@angular/fire/firestore';

// User Imports
import { LookupService } from './lookup.service';
import { OhdoorService } from './ohdoor.service';
import { DealerService } from './dealer.service';
import { BuildingService } from './building.service';
import { CustomerService } from './customer.service';
import { InspectionItem, SafetyInspection } from '../model/SafetyInspection';

@Injectable({
  providedIn: 'root',
})
export class InspectionService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly INSPECTION_COLLECTION = 'inspections';
  selectedInspections: SafetyInspection;

  cachedInspection4Menu: SafetyInspection;
  isMenuDisplayReady = false;
  currentIndex = 0; // Tracks the current item index

  // Class Constructor
  constructor(
    private firestore: Firestore,
    private lookupService: LookupService,
    private auth: Auth,
    private dealerService: DealerService,
    private customerService: CustomerService,
    private buildingService: BuildingService,
    private doorService: OhdoorService
  ) {}

  /*
    @Description: Public Method to
    @Comments:
    @Coders:
  */
  setSelectedInspection(safetyInsTemp: SafetyInspection): void {
    this.selectedInspections = safetyInsTemp;
  }

  /*
      @Description: Public Method to
      @Comments:
      @Coders:
    */
  getSelectedInspection(): SafetyInspection {
    return this.selectedInspections;
  }

  /*
    @Description: Public Method to create a new Safety Inspection
    @Comments:
    @Coders:
    @Return:  New safety inspection object
  */
  async createSafetyInspection(): Promise<SafetyInspection> {
    const curUser = this.auth.currentUser;
    const checklist = await this.lookupService.loadChecklist();
    //const inspectionId = this.createInspectionId();

    // create inspection item for each question
    const inspectionItems: InspectionItem[] = checklist.questions.map(
      (question) =>
        ({
          text: question.text,
          helpText: question.support,
          recommendations: '',
          status: 'unchecked',
          pics: [],
        } as InspectionItem)
    );

    // create new safety inspection object
    const newSafetyInspection: SafetyInspection = {
      id: '',
      date: Timestamp.now(),
      createdDate: Timestamp.now(),
      reOpenDate: null,
      completedDate: null,
      inspector: curUser.displayName,
      notes: '',
      isCompleted: false,
      inspectionItems,
      status: 'Started',
      workReportUrl: '',
      inspectionReportUrl: '',
      dealerName: this.dealerService.getSelectedDealer().name,
      dealerAddress: this.dealerService.getSelectedDealer().address.formattedAddress,
      dealerEmail: this.dealerService.getSelectedDealer().email,
      dealerPhone: this.dealerService.getSelectedDealer().contactPhone,
      customerName: this.customerService.getSelectedCustomer().name,
      customerAddress: this.customerService.getSelectedCustomer().address.formattedAddress,
      customerEmail: this.customerService.getSelectedCustomer().email,
      customerPhone: this.customerService.getSelectedCustomer().phone,
      buildingName: this.buildingService.getSelectedBuilding().name,
      buildingAddress: this.buildingService.getSelectedBuilding().address.formattedAddress,
      buildingNotes: this.buildingService.getSelectedBuilding().notes,
      doorId: this.doorService.getSelectedDoor().id,
      doorLocation: this.doorService.getSelectedDoor().location,
      doorModel: this.doorService.getSelectedDoor().doorModel,
      doorType: this.doorService.getSelectedDoor().doorType,
    };

    // add safety inspection to firebase
    // await firebase.firestore().collection('inspections').doc(inspectionId).set(newSafetyInspection);
    const inspec = await this.addInspection(newSafetyInspection);
    return inspec;
  }

  /*
    @Description: Public Method to add inspection to firebase
    @Comments:
    @Coders:
  */
  async addInspection(inspection: SafetyInspection): Promise<SafetyInspection> {
    const ref = await addDoc(
      collection(
        this.firestore,
        DealerService.DEALERS_COLLECTION,
        this.dealerService.getSelectedDealer().id,
        CustomerService.CUSTOMERS_COLLECTION,
        this.customerService.getSelectedCustomer().id,
        BuildingService.BUILDINGS_COLLECTION,
        this.buildingService.getSelectedBuilding().id,
        OhdoorService.OVER_HEAD_DOOR_COLLECTION,
        this.doorService.getSelectedDoor().id,
        InspectionService.INSPECTION_COLLECTION
      ),
      inspection
    );
    // return {
    //   id: ref.id,
    //   ...inspection,
    // } as SafetyInspection;
    inspection.id = ref.id;
    return inspection;
  }
  /*
    @Description: Public Method to update inspection in firebase
    @Comments:
    @Coders:
  */
  async updateInspection(inspection: Partial<SafetyInspection>, complete: boolean): Promise<void> {
    const ref = doc(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      this.dealerService.getSelectedDealer().id,
      CustomerService.CUSTOMERS_COLLECTION,
      this.customerService.getSelectedCustomer().id,
      BuildingService.BUILDINGS_COLLECTION,
      this.buildingService.getSelectedBuilding().id,
      OhdoorService.OVER_HEAD_DOOR_COLLECTION,
      this.doorService.getSelectedDoor().id,
      InspectionService.INSPECTION_COLLECTION,
      inspection.id
    );
    if (complete) {
      inspection.status = 'Completed';
    }else if (inspection.status === 'Expired'){
      inspection.status = 'Expired';
    }
    else if (inspection.status === 'Started'){
      inspection.status = 'Started';
    }
    return updateDoc(ref, inspection);
  }

  /*
    @Description: Public Method to delete inspection in firebase
    @Comments:
    @Coders:
  */
  async deleteInspection(inspectionId: string): Promise<void> {
    const ref = doc(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      this.dealerService.getSelectedDealer().id,
      CustomerService.CUSTOMERS_COLLECTION,
      this.customerService.getSelectedCustomer().id,
      BuildingService.BUILDINGS_COLLECTION,
      this.buildingService.getSelectedBuilding().id,
      OhdoorService.OVER_HEAD_DOOR_COLLECTION,
      this.doorService.getSelectedDoor().id,
      InspectionService.INSPECTION_COLLECTION,
      inspectionId
    );
    return updateDoc(ref, { isDeleted: true });
  }

  /*
    @Description: Public Method to get a specific inspection
    @Comments:
    @Coders: jaaam111000
  */
  async getInspection(inspectionId: string): Promise<SafetyInspection> {
    const dreff = doc(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      this.dealerService.getSelectedDealer().id,
      CustomerService.CUSTOMERS_COLLECTION,
      this.customerService.getSelectedCustomer().id,
      BuildingService.BUILDINGS_COLLECTION,
      this.buildingService.getSelectedBuilding().id,
      OhdoorService.OVER_HEAD_DOOR_COLLECTION,
      this.doorService.getSelectedDoor().id,
      InspectionService.INSPECTION_COLLECTION,
      inspectionId
    );
    const rdoc = await getDoc(dreff);
    const d = rdoc.data();
    d.id = rdoc.id;
    return d as SafetyInspection;
  }

  /*
    @Description: Public Method to get all safety inspection
    @Comments:
    @Coders:
  */
  async getAllInspections(): Promise<SafetyInspection[]> {
    const dref = collection(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      this.dealerService.getSelectedDealer().id,
      CustomerService.CUSTOMERS_COLLECTION,
      this.customerService.getSelectedCustomer().id,
      BuildingService.BUILDINGS_COLLECTION,
      this.buildingService.getSelectedBuilding().id,
      OhdoorService.OVER_HEAD_DOOR_COLLECTION,
      this.doorService.getSelectedDoor().id,
      InspectionService.INSPECTION_COLLECTION
    );
    const rdoc = await getDocs(dref);
    return rdoc.docs.map((tempDoc) => {
      const id = tempDoc.id;
      const data = tempDoc.data();
      data.id = id;
      return data;
    }) as SafetyInspection[];
  }

  /*
    @Description: Public Method to get all safety inspection after QR Scan
    @Comments:
    @Coders:
  */
  async getAllinspectionsAfterScan(
    dealerID: string,
    customerID: string,
    buildingID: string,
    doorID: string
  ): Promise<SafetyInspection[]> {
    const dref = collection(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      dealerID,
      CustomerService.CUSTOMERS_COLLECTION,
      customerID,
      BuildingService.BUILDINGS_COLLECTION,
      buildingID,
      OhdoorService.OVER_HEAD_DOOR_COLLECTION,
      doorID,
      InspectionService.INSPECTION_COLLECTION
    );
    const rdoc = await getDocs(dref);
    return rdoc.docs.map((tempDoc) => {
      const id = tempDoc.id;
      const data = tempDoc.data();
      data.id = id;
      return data;
    }) as SafetyInspection[];
  }
  /*
    @Description: Public Method to check if we can edit inspection
    @Comments:
    @Coders:Rohan-16 && jaam111000
  */
  canEditInspection(inspection: SafetyInspection): boolean {
    if (inspection === undefined) {
      return false;
    }
    try {
      if (inspection.status === 'Started' || inspection.status === 'Re-Opened') {
        return true;
      }
      return false;
    } catch (err) {
      console.log('Error while checking if user can update inspections:', err);
    }
    return;
  }
  /*
    @Description: Public Method to check if we can edit inspection
    @Comments:
    @Coders:Rohan-16
  */
  checkInspectionExpiry = async (inspection: SafetyInspection) => {
    const todaysDate = new Date();
    const inspectionDate = new Date(inspection.createdDate.seconds * 1000 + inspection.date.nanoseconds / 1000000);
    const diff = Math.abs(todaysDate.getTime() - inspectionDate.getTime());
    const days = diff / (1000 * 60 * 60 * 24);
    if (days > 3) {
      inspection.status = 'Expired';
    }
    await this.updateInspection(inspection,false); //false is for the complete flag
  };

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  storeTempInspection4Menu(tempInspection: SafetyInspection) {
    this.cachedInspection4Menu = tempInspection;
    this.isMenuDisplayReady = true;
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  getCachedInspection4Menu(): SafetyInspection {
    return this.cachedInspection4Menu;
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  getIsMenuDisplayReady(): boolean {
    return this.isMenuDisplayReady;
  }

  /*
    @Description: Function to redirect to an index
    @Comments:
    @Coders: jaam111000
  */
  setIndex(index: number) {
    this.currentIndex = index; // Set current index to the button's index
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  getIndex(): number {
    return this.currentIndex;
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  increaseIndex() {
    this.currentIndex++;
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  decreaseIndex() {
    this.currentIndex--;
  }
}
