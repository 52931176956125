// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --ion-background-color: #f4f4f9;
}

.link-card {
  --background: white;
  --box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  --border-radius: 12px;
  transition: transform 0.3s, box-shadow 0.3s;
}
.link-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

ion-card-header {
  --background: #e0e0e0;
  --ion-card-title-color: #333;
  padding: 20px;
  border-bottom: 1px solid #ccc;
}

ion-card-title {
  font-weight: bold;
  font-size: 1.2em;
  color: #333;
}

ion-grid {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

ion-row {
  display: flex;
  justify-content: center;
}

ion-col {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/door-sticker-link/door-sticker-link.component.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;AACJ;;AAEE;EACE,mBAAA;EACA,2CAAA;EACA,qBAAA;EACA,2CAAA;AACJ;AACI;EACE,2BAAA;EACA,yCAAA;AACN;;AAGE;EACE,qBAAA;EACA,4BAAA;EACA,aAAA;EACA,6BAAA;AAAJ;;AAGE;EACE,iBAAA;EACA,gBAAA;EACA,WAAA;AAAJ;;AAGE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAJ;;AAGE;EACE,aAAA;EACA,uBAAA;AAAJ;;AAGE;EACE,aAAA;EACA,uBAAA;AAAJ","sourcesContent":["ion-content {\r\n    --ion-background-color: #f4f4f9;\r\n  }\r\n  \r\n  .link-card {\r\n    --background: white;\r\n    --box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\r\n    --border-radius: 12px;\r\n    transition: transform 0.3s, box-shadow 0.3s;\r\n  \r\n    &:hover {\r\n      transform: translateY(-5px);\r\n      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\r\n    }\r\n  }\r\n  \r\n  ion-card-header {\r\n    --background: #e0e0e0;\r\n    --ion-card-title-color: #333;\r\n    padding: 20px;\r\n    border-bottom: 1px solid #ccc;\r\n  }\r\n  \r\n  ion-card-title {\r\n    font-weight: bold;\r\n    font-size: 1.2em;\r\n    color: #333;\r\n  }\r\n  \r\n  ion-grid {\r\n    height: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n  }\r\n  \r\n  ion-row {\r\n    display: flex;\r\n    justify-content: center;\r\n  }\r\n  \r\n  ion-col {\r\n    display: flex;\r\n    justify-content: center;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
