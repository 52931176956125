export const environment = {
  production: false,
  useEmulator: false,
  baseApiUrl: 'https://smart-door-334013.firebaseapp.com/',
  invite: 'api/invite/',
  firebaseConfig: {
    apiKey: 'AIzaSyCTZ1sAxesRFoiTBt3SIe9Yv_HOWZYKG_E',
    authDomain: 'smart-door-334013.firebaseapp.com',
    projectId: 'smart-door-334013',
    storageBucket: 'smart-door-334013.appspot.com',
    messagingSenderId: '300608050761',
    appId: '1:300608050761:web:8c18170976c40e7c559198',
  },
  mapKey: 'AIzaSyB1wjOBBqEVazusN6vBrrRQW5veUBUtKfY',
  openAIKey:
    // eslint-disable-next-line max-len
    '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',
};
