import { getAuth } from '@angular/fire/auth';
import { SegmentValue } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// User Imports
import { Dealer } from 'src/app/model/Dealer';
import { UserService } from 'src/app/service/user.service';
import { DealerService } from 'src/app/service/dealer.service';

@Component({
  selector: 'app-dealer-list',
  templateUrl: './dealer-list.component.html',
  styleUrls: ['./dealer-list.component.scss'],
})
export class DealerListComponent implements OnInit {
  viewMode: SegmentValue = 'tile';
  isSearchEnabled = false;
  tempDealers: Dealer[] = [];
  filteredDealers: Dealer[] = [];

  // Routing Variables
  isDoorStickerRoute = false;

  constructor(
    private dealerService: DealerService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      if (params.doorStickerRoute) {
        this.isDoorStickerRoute = true;
      }
    });

    this.dealerService.getDealersForSignedInUser(getAuth().currentUser.uid).then(async (dealers) => {
      this.tempDealers = dealers;
      // Automatic Routing depending on number of dealerRefs
      if (this.tempDealers.length === 1 && !this.userService.isUserSmartDoorAdmin() && !this.isDoorStickerRoute) {
        this.dealerService.setSelectedDealer(this.tempDealers[0]);
        this.router.navigate([this.tempDealers[0].id, 'view'], { relativeTo: this.route });
        return;
      }
    });
  }

  /*
    @Description
    @Comments:
    @Coders: jaam111000
  */
  getCurrentDealers() {
    return this.isSearchEnabled ? this.filteredDealers : this.tempDealers;
  }

  /*
    @Description: Public Method to set dealer
    @Comments:
    @Coders:
  */
  async setDealer(dealerObject: Dealer) {
    this.dealerService.setSelectedDealer(dealerObject);
    if (this.isDoorStickerRoute) {
      this.router.navigate([dealerObject.id, 'customers'], {
        relativeTo: this.route,
        queryParams: { doorStickerRoute: true },
      });
    } else {
      this.router.navigate([dealerObject.id, 'view'], { relativeTo: this.route });
    }
  }

  /*
    @Description
    @Comments:
    @Coders: jaam111000
  */
  changeViewMode(isListView: boolean): void {
    if (isListView) {
      this.viewMode = 'list';
    } else {
      this.viewMode = 'tile';
    }
  }

  /*
    @Description
    @Comments:
    @Coders: jaam111000
  */
  searchChange(event: any) {
    const searchTerm = event.toLowerCase();
    if (searchTerm.length > 0) {
      this.isSearchEnabled = true;
      this.filteredDealers = this.tempDealers.filter(
        (dealer) =>
          dealer.name.toLowerCase().includes(searchTerm) || dealer.firstName.toLowerCase().includes(searchTerm)
      );
    } else {
      this.isSearchEnabled = false;
      this.filteredDealers = [];
    }
  }
}
