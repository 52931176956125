// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scanner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.frame {
  border: 2px solid #000;
  border-radius: 10px;
  overflow: hidden;
}

video {
  width: 100%;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/qr-scanner/qr-scanner.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACF;;AAEA;EACE,sBAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":[".scanner-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 100%;\r\n}\r\n\r\n.frame {\r\n  border: 2px solid #000;\r\n  border-radius: 10px;\r\n  overflow: hidden;\r\n}\r\n\r\nvideo {\r\n  width: 100%;\r\n  height: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
