/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* eslint-disable no-trailing-spaces */
import { Injectable, Injector } from '@angular/core';
import {
  collection,
  doc,
  DocumentData,
  Firestore,
  updateDoc,
  deleteDoc,
  getDocs,
  getDoc,
  query,
  addDoc,
  DocumentReference,
  where,
  setDoc,
  CollectionReference,
  documentId,
} from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';

// User Modules
import { User, DealerUser } from '../model/User';
import { Dealer } from '../model/Dealer';
import { FileUploadService } from './fileUploadService';
import { DealerUserPolicy } from '../model/UserPrivacy';
import { UserService } from './user.service';
@Injectable({
  providedIn: 'root',
})
export class DealerService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly DEALERS_COLLECTION = 'dealers';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly USERS_COLLECTION = 'users';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly TEMPINVITE_COLLECTION = 'tempInvites';

  public isDealerSelected = false;

  // State Observable
  public dealerSubject = new BehaviorSubject<any>(null);
  dealerTemp$ = this.dealerSubject.asObservable();

  // Members of class
  private dealers: Dealer[] = [];
  private selectedDealer: Dealer = null;

  // Constructor for Class
  constructor(
    private readonly firestore: Firestore,
    private userService: UserService,
    private fileUploadService: FileUploadService
  ) {}

  // ================================= Start of Class Member Methods ====================

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  getDealers(): Dealer[] {
    return this.dealers;
  }

  /*
    @Description:
    @Comments:
    @Coders:
  */
  setDealers(curDealer: Dealer[]): void {
    this.dealers = curDealer;
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  getSelectedDealer(): Dealer {
    return this.selectedDealer;
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  setSelectedDealer(selectedTempDealer: Dealer) {
    this.dealerSubject.next(selectedTempDealer);
    this.selectedDealer = selectedTempDealer;
    this.isDealerSelected = true;
  }

  /*
    @Description: Cohesive method to return dealers for signed in user
    @Comments:
    @Coders: jaam111000
  */
  async getDealersForSignedInUser(uuid: string): Promise<Dealer[]> {
    const userObj = await this.userService.getUserFromCollection(uuid);
    const amIAdmin = this.userService.isUserSmartDoorAdmin();
    if (amIAdmin) {
      return await this.getAllDealersForAdmin();
    } else {
      const dealersIDsAR = userObj.dealers.map((dealer) => dealer.id);
      return this.getDealersBasedOnIDs(dealersIDsAR);
    }
  }

  /*
    @Description:
    @Comments:
    @Coders: rohan-16 && jaam111000
  */
  async loadDealerUserSecurity(dealerUserId: string): Promise<void> {
    const tempUserInDealer = await this.getDealerUserDocument(this.getSelectedDealer().id, dealerUserId);
    if (!tempUserInDealer) {
      this.userService.setUserDealerPolicy(this.getDefaultDealerUserPolicies());
    } else {
      this.userService.setUserDealerPolicy(tempUserInDealer.userPolicies);
    }
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async canReadDealers(): Promise<boolean> {
    try {
      if (this.userService.getUserDealerPolicy().canReadDealers) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can read dealers');
    }
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  canCreateDealers(): boolean {
    if (this.userService.isUserSmartDoorAdmin()) {
      return true;
    }
    return false;
  }
  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  canDeleteDealers(): boolean {
    try {
      if (this.userService.isUserSmartDoorAdmin() || this.userService.getUserDealerPolicy().canDeleteDealers) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can delete dealers');
    }
    return false;
  }
  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  canUpdateDealers(): boolean {
    try {
      if (this.userService.isUserSmartDoorAdmin() || this.userService.getUserDealerPolicy().canUpdateDealers) {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error, 'Error while checking if user can update dealers');
    }
    return false;
  }
  /*
    @Description: Public Method to check if current user can create dealerUsers
    @Comments:
    @Coders: Rohan-16
  */
  canCreateDealerUsers(): boolean {
    try {
      if (this.userService.isUserSmartDoorAdmin() || this.userService.getUserDealerPolicy().canCreateDealerUsers) {
        return true;
      }
    } catch (error) {
      console.log('Error while checking if dealer user can read users');
    }
    return false;
  }

  /*
      @Description: Public Method to check if current user can read dealerUsers
      @Comments:
      @Coders: jaam111000
    */
  canReadDealerUsers(): boolean {
    try {
      if (this.userService.isUserSmartDoorAdmin() || this.userService.getUserDealerPolicy().canReadDealerUsers) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if dealer user can read users');
    }
  }
  /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
    */
  canUpdateDealerUsers(): boolean {
    try {
      if (this.userService.isUserSmartDoorAdmin() || this.userService.getUserDealerPolicy().canUpdateDealerUsers) {
        return true;
      }
    } catch (error) {
      console.log('Error while checking if dealer user can update users');
    }
    return false;
  }

  /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
    */
  canDeleteDealerUsers(): boolean {
    try {
      if (this.userService.isUserSmartDoorAdmin() || this.userService.getUserDealerPolicy().canDeleteDealerUsers) {
        return true;
      }
    } catch (error) {
      console.log('Error while checking if dealer user can delete dealers');
    }
    return false;
  }
  /*
    @Description: Public Method
    @Comments: TODO-J: Check to see if those permissions are there
    @Coders: jaam111000
  */
  canManageCustomers(): boolean {
    // console.log('The user policies are:',this.getSelectedDealer());
    // console.log('The dealerRef is',this.userService.getUserCollectionObj().customers);
    try {
      if (
        this.userService.isUserSmartDoorAdmin() ||
        this.userService.getUserDealerPolicy()?.canUpdateCustomers ||
        this.userService.getUserDealerPolicy()?.canCreateCustomers
      ) {
        return true;
      }
    } catch (error) {
      console.log('Error while checking if dealer user can manage customers');
    }
    return false;
  }
  /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
    */
  canReadCustomers(): boolean {
    try {
      if (
        this.userService.isUserSmartDoorAdmin() ||
        this.userService.getUserDealerPolicy()?.canReadCustomers ||
        this.userService.getUserCollectionObj().customers
      ) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can add dealers');
    }
    return;
  }
  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  canCreateCustomers(): boolean {
    try {
      if (this.userService.isUserSmartDoorAdmin() || this.userService.getUserDealerPolicy().canCreateCustomers) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can create customers', error);
    }
    return;
  }

  /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
    */
  canDeleteCustomers(): boolean {
    try {
      if (this.userService.isUserSmartDoorAdmin() || this.userService.getUserDealerPolicy().canDeleteCustomers) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can delete customers', error);
    }
    return;
  }
  /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
    */
  canUpdateCustomers(): boolean {
    try {
      if (this.userService.isUserSmartDoorAdmin() || this.userService.getUserDealerPolicy().canUpdateCustomers) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can update customers', error);
    }
    return;
  }
  /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
     */
  canCreateCustomerUsers(): boolean {
    try {
      if (
        this.userService.isUserSmartDoorAdmin() ||
        this.userService.getUserDealerPolicy()?.canCreateCustomerUsers ||
        this.userService.getUserCustomerPolicy()?.canCreateCustomerUsers
      ) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can create customer users', error);
    }
    return;
  }
  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  canUpdateCustomerUsers(): boolean {
    try {
      if (this.userService.isUserSmartDoorAdmin() || this.userService.getUserDealerPolicy()?.canUpdateCustomerUsers) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can update customer users', error);
    }
    return;
  }

  /*
    @Description: Public Method to fetch dealers based on the references that user object contains
    @Comments:
    @Coders: jaam111000
  */
  async getDealersBasedOnIDs(dealerIDsAR: string[]): Promise<Dealer[]> {
    try {
      const collectionRef = collection(this.firestore, DealerService.DEALERS_COLLECTION);
      const q = query(collectionRef, where(documentId(), 'in', dealerIDsAR), where('active', '==', true));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((tempDoc) => {
        const id = tempDoc.id;
        const data = tempDoc.data();
        data.id = id;
        return data;
      }) as Dealer[];
    } catch (error) {
      console.log('There was an error while fetching dealers based on users reference...', error);
    }
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  async getAllDealersForAdmin(): Promise<Dealer[]> {
    const collectionRef: CollectionReference<DocumentData> = collection(
      this.firestore,
      DealerService.DEALERS_COLLECTION
    );
    try {
      const queryRef = query(collectionRef, where('active', '==', true));
      const snap = await getDocs(queryRef);
      return snap.docs.map((tempDoc) => {
        const id = tempDoc.id;
        const data = tempDoc.data();
        data.id = id;
        return data;
      }) as Dealer[];
    } catch (error) {
      console.log('Error while fetching all of your dealers...', error);
    }
  }

  /*
    @Description: Function to get your Dealer using the documentID
    @Comments: This method only returns the nested field of the Dealer object
    TODO: Is this ID the one matching to the selected dealer
    @Coders: AllanSmith
  */
  async getById(id: string): Promise<Dealer> {
    // Check the cache
    const docRef = doc(this.firestore, DealerService.DEALERS_COLLECTION, id);
    const docSnap = await getDoc(docRef);
    const dealer = docSnap.data() as Dealer;
    return dealer;
  }
  /*
    @Description: Function to get your Dealer's users using the DealerID
    @Comments:
    @Coders: jaam111000
  */
  async getAllUsersForDealer(dealerID: string): Promise<User[]> {
    const docRef = collection(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      dealerID,
      DealerService.USERS_COLLECTION
    );
    const docSnap = await getDocs(docRef);
    return docSnap.docs.map((tempDoc) => {
      const id = tempDoc.id;
      const data = tempDoc.data();
      data.id = id;
      return data;
    }) as User[];
  }

  /*
    @Description: Function to get your Dealer using the documentID, nestedCollectionName & userID
    @Comments:
    @Coders: jaam111000
  */
  async getDealerUserDocument(dealerID: string, userIDToFilter: string): Promise<any> {
    try {
      const docRef = doc(
        this.firestore,
        DealerService.DEALERS_COLLECTION,
        dealerID,
        DealerService.USERS_COLLECTION,
        userIDToFilter
      );
      const docSnap = (await getDoc(docRef)).data();
      return docSnap;
    } catch (error) {
      console.log('no user found in dealer..', error);
    }
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  updateDealerPolicies(dealerID: string, userID: string, userPermissions: DealerUserPolicy): DealerUserPolicy {
    const docRef = doc(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      dealerID,
      DealerService.USERS_COLLECTION,
      userID
    );
    updateDoc(docRef, { userPolicies: userPermissions });
    return userPermissions;
  }

  /*
    @Description:
    @Comments:
    @Coders:
  */
  create(dealer: Dealer): Promise<DocumentReference<DocumentData>> {
    const collectionRef = collection(this.firestore, DealerService.DEALERS_COLLECTION);
    return addDoc(collectionRef, dealer);
  }
  /*
    @Description:
    @Comments: setDoc does not return the uploaded & edited object
    @Coders: jaam111000
  */
  async update(id: string, dealer: Dealer): Promise<void> {
    const docRef = doc(this.firestore, DealerService.DEALERS_COLLECTION, id);
    dealer.id = id;
    this.dealerSubject.next(dealer);
    this.setSelectedDealer(dealer);
    const selectedCustomer = await setDoc(docRef, dealer, { merge: true });
    return selectedCustomer;
  }

  /*
    @Description:
    @Comments:
    @Coders:
  */
  delete(id: string): Promise<void> {
    const docRef = doc(this.firestore, DealerService.DEALERS_COLLECTION, id);
    return deleteDoc(docRef);
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  addUserToDealer(dealerId: string, userId: string, tempUserObject: any) {
    const usersCollectionRef = collection(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      dealerId,
      DealerService.TEMPINVITE_COLLECTION
    );
    return addDoc(usersCollectionRef, tempUserObject);
    // const docRef = doc(this.firestore, DealerService.DEALERS_COLLECTION, dealerId, 'users', userId);
    // return setDoc(docRef, tempUserObject);
  }

  /*
    @Description:ADD dealer logo to dealer
    @Comments:
    @Coders: Rohan-16
  */
  async addDealerLogo(event: any, dealerName: string): Promise<string> {
    const file = event.target.files[0];
    if (!file) {
      throw new Error('No file provided');
    }
    const path = `${DealerService.DEALERS_COLLECTION}_logo/${dealerName}`;
    const reader = new FileReader();
    const fileReadPromise = new Promise<string>((resolve, reject) => {
      reader.onload = async (loadEvent: any) => {
        const dataUrl = loadEvent.target.result;
        if (!dataUrl) {
          reject('Failed to load the image');
          return;
        }
        try {
          const downloadUrl = await this.fileUploadService.uploadImage(path, dataUrl, `photo_${Date.now()}`);
          resolve(downloadUrl);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
    reader.readAsDataURL(file);
    return fileReadPromise;
  }

  /*
    @Description:
    @Comments:
    @Coders: Rohan-16
  */
  async getDealerUser(id: string): Promise<DealerUser> {
    return await this.getDealerUserDocument(this.selectedDealer.id, id);
  }

  /*
    @Description: Public Method to get default policies for Dealer/User Add
    @Comments:
    @Coders: jaam111000
  */
  getDefaultDealerUserPolicies(): DealerUserPolicy {
    return {
      canCreateDealerUsers: false,
      canUpdateDealers: false,
      canReadDealers: true,
      canCreateCustomers: false,
      canUpdateDealerUsers: false,
      canCreateDealers: false,
      canDeleteCustomers: false,
      canUpdateCustomers: false,
      canReadCustomers: true,
      canReadDealerUsers: true,
      canDeleteDealers: false,
      canDeleteDealerUsers: false,
      canCreateCustomerUsers: false,
      canReadCustomerUsers: true,
      canUpdateCustomerUsers: false,
      canDeleteCustomerUsers: false,
    };
  }

  /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
    */
  canAccessDealerMenu(): boolean {
    try {
      const canAccessCustomerAdd = this.canCreateCustomers();
      const canAccessCustomerViewAll = this.canUpdateCustomers();
      const canAccessDealerUserAdd = this.canCreateDealerUsers();
      const canAccessDealerUserViewAll = this.canUpdateDealerUsers();
      const canAccessDealerEdit = this.canUpdateDealers();

      if (
        canAccessCustomerAdd ||
        canAccessCustomerViewAll ||
        canAccessDealerUserAdd ||
        canAccessDealerUserViewAll ||
        canAccessDealerEdit
      ) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can access dealer menu');
    }
  }
}
