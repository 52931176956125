import { Component, OnInit } from '@angular/core';
import { OverHeadDoor } from 'src/app/model/OverHeadDoor';
import { DealerService } from 'src/app/service/dealer.service';
import { OhdoorService } from 'src/app/service/ohdoor.service';
import { BarcodeService } from 'src/app/service/barcode.service';
import { BuildingService } from 'src/app/service/building.service';
import { CustomerService } from 'src/app/service/customer.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-door-sticker-link-end',
  templateUrl: './door-sticker-link-end.component.html',
  styleUrls: ['./door-sticker-link-end.component.scss'],
})
export class DoorStickerLinkEndComponent implements OnInit {
  dealerSelected: string;
  customerSelected: string;
  buildingSelected: string;
  doorSelectedLocation: string;
  doorSelectedID: string;
  // tempDoors: OverHeadDoor[];

  // Component Constructor
  constructor(
    private dealerService: DealerService,
    private customerService: CustomerService,
    private buildingService: BuildingService,
    private doorService: OhdoorService,
    private barcodeService: BarcodeService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // this.getTempDoors();
    this.route.paramMap.subscribe(async (params) => {
      this.doorSelectedID = params.get('doorID');
      if (this.doorSelectedID && this.doorService.getSelectedDoor() == null) {
        try {
          const doorGet = await this.doorService.getDoorAfterQRScan(
            this.dealerService.getSelectedDealer().id,
            this.customerService.getSelectedCustomer().id,
            this.buildingService.getSelectedBuilding().id,
            this.doorSelectedID
          );
          doorGet.id = this.doorSelectedID;
          // console.log('This is the door we get you back...', doorGet);
          this.doorService.setSelectedDoor(doorGet);
        } catch (error) {
          console.log('Error while fetching your door after create', error);
        }
      }
      this.getSelectedNames();
    });
  }

  getSelectedNames() {
    this.dealerSelected = this.dealerService.getSelectedDealer().name;
    this.customerSelected = this.customerService.getSelectedCustomer().name;
    this.buildingSelected = this.buildingService.getSelectedBuilding().address.formattedAddress;
    this.doorSelectedLocation = this.doorService.getSelectedDoor().location;
  }

  // getTempDoors() {
  //   this.doorService.getAllDoors().then((tempDoors) => {
  //     this.tempDoors = tempDoors;
  //   });
  // }

  link() {
    // Handle the link action
    console.log('Link button clicked');
    // Add logic to link the QR code to the parameters
    const obj2Store = {
      dealerID: this.dealerService.getSelectedDealer().id,
      customerID: this.customerService.getSelectedCustomer().id,
      buildingID: this.buildingService.getSelectedBuilding().id,
      doorID: this.doorService.getSelectedDoor().id,
    };
    this.barcodeService.storeCompleteQRDocInFirebase(obj2Store);
    this.router.navigateByUrl('/home');
  }
}
