import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { ToastController, IonAccordionGroup, ModalController } from '@ionic/angular';

// User Imports
import { DoorLookups } from 'src/app/model/Lookups';
import { Operator, OverHeadDoor, SpringDetails, Window } from 'src/app/model/OverHeadDoor';
import { FileUploadService } from 'src/app/service/fileUploadService';
import { LookupService } from 'src/app/service/lookup.service';
import { OhdoorService } from 'src/app/service/ohdoor.service';

import { AlertController } from '@ionic/angular';
import { QrScannerComponent } from 'src/app/components/qr-scanner/qr-scanner.component';

@Component({
  selector: 'app-door-ae',
  templateUrl: './door-ae.component.html',
  styleUrls: ['./door-ae.component.scss'],
})
export class DoorAEComponent implements OnInit {
  @ViewChild('accordionGroup', { static: false }) accordionGroup: IonAccordionGroup;

  // Class Variables
  lookUpServiceDoorMap: DoorLookups;
  door: OverHeadDoor;
  doorForm: FormGroup;
  doorID: string;
  createFlag = false;
  doorLookUpIsReady = false;

  // Door Picture Feature
  photo: string | null = null;
  downloadUrls: string[] | null = [];

  // Expansion panel flags
  electricallyOperatedIsExpanded = false;

  isDoorStickerRoute = false;

  // Making Width & Height Inputs in Imperial
  sectionalDoorSelectedFlag = false;
  isDoorModelHiddenFlag = false;
  feetOptions: number[] = [];
  inchOptions: number[] = [];
  inchOptionsDiameter: string[] = [];

  // Sections completion tracking
  completedSections: Set<string> = new Set();
  selectedSegment = 'generalTab'; // The First Expanded Tab
  tempIndex = 0;
  sections = [
    'doorID',
    'environment',
    'operationFrequency',
    'manufactureDate',
    'doorTypes',
    'panelType',

    'manufacturer',
    'model',
    'doorSize',
    'liftConfiguration',
    'tracks',
    'rollers',
    'hinge',
    'shaft',
    'drumCables',
    'springs',
    'modeOfOperation',
    'keyed',
    'safetyDevices',
  ];
  sectionMap = {
    // Section A
    doorID: 0,
    environment: 1,
    operationFrequency: 2,
    manufactureDate: 3,
    // Section B
    doorTypes: 4,
    panelType: 5,
    manufacturer: 6,
    model: 7,
    doorSize: 8,
    liftConfiguration: 9,
    tracks: 10,
    rollers: 11,
    hinge: 12,
    // Section C
    shaft: 12,
    drumCables: 13,
    springs: 14,
    // Section D
    modeOfOperation: 15,
    // Section E
    keyed: 16,
    safetyDevices: 17,
  };

  // New variables for dynamic manufacturer and model lookup
  manufacturers: { id: string; name: string }[] = [];
  modelsByManufacturer: { id: string; name: string }[] = [];

  // Class Constructor
  constructor(
    private fb: FormBuilder,
    private ohdService: OhdoorService,
    private lookupService: LookupService,
    private router: Router,
    private route: ActivatedRoute,
    private fileUploadService: FileUploadService,
    private toastController: ToastController,
    private modalController: ModalController,
    private alertController: AlertController
  ) {}

  // Getter for springDetails FormArray
  get springDetails(): FormArray {
    return this.doorForm.get('springDetails') as FormArray;
  }

  // Getter for windows FormArray
  get windows(): FormArray {
    return this.doorForm.get('windows') as FormArray;
  }

  /**
   * Handles changes to the operator type
   *
   * @param event The change event
   */
  handleOperatorChange(event: any) {
    if (event.detail.value === 'Electric operator') {
      this.electricallyOperatedIsExpanded = true;
    } else {
      this.electricallyOperatedIsExpanded = false;
    }
    this.onSectionComplete('modeOfOperation');
  }

  /**
   * Handles the completion of a section
   *
   * @param sectionName The name of the completed section
   */
  onSectionComplete(sectionName: string) {
    this.completedSections.add(sectionName);
    this.saveDoor(true);

    // Business Logic for Different foor Types
    // console.log(sectionName);
    if (sectionName === 'doorTypes') {
      // Sectional Doors vs. Others
      if (this.doorForm.value.doorTypes === '1') {
        this.sectionalDoorSelectedFlag = true;
      } else {
        this.sectionalDoorSelectedFlag = false;
        this.manufacturers = this.lookUpServiceDoorMap.doorManufacturers.filter((manufacturer) =>
          manufacturer.applicableDoorTypes.includes(this.doorForm.value.doorTypes)
        );
      }
      // console.log(this.manufacturers);
    }
    // For Sectional Doors we require panel type to filter manufacturer
    else if (sectionName === 'panelType') {
      // console.log(this.doorForm.value.doorPanelType);
      this.manufacturers = this.lookUpServiceDoorMap.doorManufacturers.filter(
        (manufacturer) =>
          manufacturer.applicableDoorTypes.includes(this.doorForm.value.doorTypes) &&
          manufacturer.applicablePanelTypes.includes(this.doorForm.value.doorPanelType)
      );
      // console.log(this.manufacturers);
    } else if (sectionName === 'manufacturer') {
      // console.log('Selected manu..', this.doorForm.value.doorManufacturer);

      // Sectional Doors
      if (this.sectionalDoorSelectedFlag) {
        this.modelsByManufacturer = this.lookUpServiceDoorMap.doorModels.filter(
          (model) =>
            model.doorTypeId.includes(this.doorForm.value.doorTypes) &&
            model.panelTypeId === this.doorForm.value.doorPanelType &&
            model.manufacturerId === this.doorForm.value.doorManufacturer
        );
      } else {
        this.modelsByManufacturer = this.lookUpServiceDoorMap.doorModels.filter(
          (model) =>
            model.doorTypeId.includes(this.doorForm.value.doorTypes) &&
            model.manufacturerId === this.doorForm.value.doorManufacturer
        );
      }
      // console.log('your door models are...', this.modelsByManufacturer);
    }
    // UX Effects
    this.expandNextSection(sectionName);
    this.handleTopTransition();
  }

  /**
   * Expands the next section after the current one is completed
   *
   * @param currentSection The name of the current section
   */
  expandNextSection(currentSection: string) {
    let currentIndex = this.sections.indexOf(currentSection);
    if (currentIndex < this.sections.length - 1) {
      // Logic
      if (!this.sectionalDoorSelectedFlag && currentSection === 'doorTypes') {
        currentIndex++;
      } else if (currentSection === 'manufacturer' && this.modelsByManufacturer.length === 0) {
        currentIndex++;
        this.isDoorModelHiddenFlag = true;
        console.log('Your model is hidden');
      }

      const nextSection = this.sections[currentIndex + 1];

      this.tempIndex = this.sections.indexOf(nextSection);

      // Find the accordion value for the next section
      const accordionValue = nextSection;

      // Expand the next accordion
      if (this.accordionGroup) {
        this.accordionGroup.value = accordionValue;
      }
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      if (params.doorStickerRoute) {
        this.isDoorStickerRoute = true;
      }
    });

    this.fetchDoorDataFromLookUpService();
    this.buildForm();
    this.route.paramMap.subscribe(async (params) => {
      if (params.get('doorID') == null) {
        this.createFlag = true;
      } else {
        this.doorID = params.get('doorID');
        this.door = await this.ohdService.read(this.doorID);
        if (this.door) {
          this.patchFormValues();
        }
      }
    });
    this.initializeOptions();
  }

  /**
   * Initializes options for feet, inches, and inch diameters
   */
  initializeOptions() {
    // Define feet options from 4 to 100
    this.feetOptions = Array.from({ length: 97 }, (_, i) => i + 4);

    // Define inches options from 0 to 11
    this.inchOptions = Array.from({ length: 12 }, (_, i) => i);

    // Other array
    this.inchOptionsDiameter = ['2', '2 5/8', '3 3/4', '6'];
  }

  /**
   * Returns the OHDoor service
   */
  getMyDoorService() {
    return this.ohdService;
  }

  /**
   * Builds the form with initial values
   */
  buildForm() {
    this.doorForm = this.fb.group({
      doorLocation: [''],
      doorTypes: [''],
      doorPanelType: [''],

      doorType: [''],
      doorManufacturer: [''],
      doorModel: [''],
      doorClassification: [''],
      doorWidthFeet: [''],
      doorWidthInches: [''],
      doorHeightFeet: [''],
      doorHeightInches: [''],
      jambWallMountingMaterial: [''],
      liftSystem: [''],
      trackWidth: [''],
      trackHardware: [''],
      trackMountingMethod: [''],
      torsionSpringType: [''],
      cableSize: [''],
      cableLength: [''],
      shaftDimension: [''],
      rollerLength: [''],
      rollerSize: [''],
      drumSize: [''],
      springDetails: this.fb.array([]),
      accessories: this.fb.group({
        keyed: [false],
        pusherSprings: [false],
        bumperSprings: [false],
        stops: [false],
        breakawaySections: [false],
        exhaustPort: [false],
        liftHandle: [false],
        locks: [false],
        passDoor: [false],
        quickCloseFixture: [false],
        trackGuards: [false],
        noAccessories: [false],
        safetyDevices: this.fb.group({
          cableTensionDevice: [false],
          failSafeBottom: [false],
          springFailureDevice: [false],
          noSafetyDevice: [false],
        }),
      }),
      environmentCondition: [''],
      dailyOperationFrequency: [''],
      glazingType: [''],
      windows: this.fb.array([]),
      operator: this.fb.group({
        type: [''],
        modeOfOperation: [''],
        manufacturer: [''],
        horsepower: [''],
        electrical: [''],
        model: [''],
        generationType: [''],
        wallStationControl: [''],
        controlWiringType: [''],
        primaryEntrapmentDevice: [''],
        primaryPhotoEyeType: [''],
        secondaryEntrapmentDevices: [''],
        secondarySensingEdgeType: [''],
        accessories: [''],
      }),
      pictureUrl: [''],
      hingeType: [''],
      hingeSetup: [''],
      dateOfMfr: ['2024-08-22T01:22:00'],
    });

    // Add listener for doorManufacturer changes
    // this.doorForm.get('doorManufacturer').valueChanges.subscribe((manufacturerId) => {
    //   this.updateModelOptions(manufacturerId);
    // });
  }

  /**
   * Updates model options based on selected manufacturer
   *
   * @param manufacturerId The selected manufacturer ID
   */
  updateModelOptions(manufacturerId: string) {
    // const models = this.modelsByManufacturer[manufacturerId] || [];
    // this.doorForm.get('doorModel').setValue('');
    // this.doorForm.get('doorModel').enable();
    // if (models.length === 0) {
    //   this.doorForm.get('doorModel').disable();
    // }
    // Expand the model section after updating options
    // this.onSectionComplete('manufacturer');
  }

  /**
   * Patches form values with existing door data
   */
  patchFormValues() {
    this.doorForm.patchValue({
      doorLocation: this.door.location,
      doorManufacturer: this.door.doorManufacturer,
      doorPanelType: this.door?.panelType || '',
      doorModel: this.door.doorModel,
      doorClassification: this.door.doorClassification,
      doorType: this.door.doorType,
      doorTypes: this.door.doorTypes,
      doorWidthFeet: this.door.doorWidthFeet,
      doorWidthInches: this.door.doorWidthInches,
      doorHeightFeet: this.door.doorHeightFeet,
      doorHeightInches: this.door.doorHeightInches,
      jambWallMountingMaterial: this.door.jambWallMountingMaterial,
      liftSystem: this.door.liftSystem,
      trackWidth: this.door.trackWidth,
      trackHardware: this.door.trackHardware,
      trackMountingMethod: this.door.trackMountingMethod,
      torsionSpringType: this.door.torsionSpringType,
      cableSize: this.door.cableSize,
      cableLength: this.door.cableLength,
      shaftDimension: this.door.shaftDimension,
      rollerLength: this.door.rollerLength,
      rollerSize: this.door.rollerSize,
      drumSize: this.door.drumSize,
      springDetails: this.door.springDetails,
      accessories: {
        keyed: this.door.accessories.keyed,
        pusherSprings: this.door.accessories?.pusherSprings || false,
        bumperSprings: this.door.accessories?.bumperSprings || false,
        stops: this.door.accessories?.stops || false,
        breakawaySections: this.door.accessories?.breakawaySections || false,
        exhaustPort: this.door.accessories?.exhaustPort || false,
        liftHandle: this.door.accessories?.liftHandle || false,
        locks: this.door.accessories?.locks || false,
        passDoor: this.door.accessories?.passDoor || false,
        quickCloseFixture: this.door.accessories?.quickCloseFixture || false,
        trackGuards: this.door.accessories?.trackGuards || false,
        noAccessories: this.door.accessories?.noAccessories || false,
        safetyDevices: {
          cableTensionDevice: this.door?.safetyDevices?.cableTensionDevice || false,
          failSafeBottom: this.door?.safetyDevices?.failSafeBottom || false,
          springFailureDevice: this.door?.safetyDevices?.springFailureDevice || false,
          noSafetyDevice: this.door?.safetyDevices?.noSafetyDevice || false,
        },
      },
      environmentCondition: this.door.environmentCondition,
      dailyOperationFrequency: this.door.dailyOperationFrequency,
      windows: this.door.windows || [],
      operator: {
        modeOfOperation: this.door.operator.modeOfOperation || '',
        type: this.door.operator.type || '',
        manufacturer: this.door.operator.manufacturer || '',
        horsepower: this.door.operator.horsepower || '',
        electrical: this.door.operator.electrical || '',
        model: this.door.operator.model || '',
        generationType: this.door.operator.generationType || '',
        wallStationControl: this.door.operator.wallStationControl || '',
        controlWiringType: this.door.operator.controlWiringType || '',
        primaryEntrapmentDevice: this.door.operator.primaryEntrapmentDevice || '',
        primaryPhotoEyeType: this.door.operator.primaryPhotoEyeType || '',
        secondaryEntrapmentDevices: this.door.operator.secondaryEntrapmentDevices || '',
        secondarySensingEdgeType: this.door.operator.secondarySensingEdgeType || '',
        accessories: this.door.operator.accessories || '',
        dateOfMfr: this.door.operator.dateOfMfr || '',
      },
      hingeType: this.door?.hingeType || '',
      hingeSetup: this.door?.hingeSetup || '',
      dateOfMfr: this.door.dateOfMfr || '2023-11-02T01:22:00',
    });
    this.setSpringDetails(this.door.springDetails);
    this.setWindowDetails(this.door.windows);
    this.checkElectricallyOperated(this.door.operator.modeOfOperation);

    // Door Picture Feature
    this.downloadUrls = this.door.pictureUrl;
  }

  /**
   * Handles date changes
   *
   * @param event The date change event
   */
  onDateChange(event: any): void {
    const date = event.detail.value;
    this.doorForm.get('dateOfMfr').setValue(date);
    this.onSectionComplete('manufactureDate');
  }

  /**
   * Sets spring details in the form
   *
   * @param details The spring details to set
   */
  setSpringDetails(details: SpringDetails[] = []) {
    const detailsFGs = details.map((detail) => this.fb.group(detail));
    const detailsFormArray = this.fb.array(detailsFGs);
    this.doorForm.setControl('springDetails', detailsFormArray);
  }

  /**
   * Adds a new spring detail to the form
   */
  addSpringDetails() {
    this.springDetails.push(
      this.fb.group({
        length: [null, Validators.required],
        lengthNested: [null, Validators.required],
        innerDiameter: [null, Validators.required],
        springQuantity: [null, Validators.required],
        leftHandWoundSprings: [null, Validators.required],
        rightHandWoundSprings: [null, Validators.required],
      })
    );
  }

  /**
   * Removes a spring detail from the form
   *
   * @param i The index of the spring detail to remove
   */
  removeSpringDetails(i: number) {
    this.springDetails.removeAt(i);
  }

  /**
   * Handles changes to spring quantity
   *
   * @param index The index of the changed spring detail
   */
  onSpringQuantityChange(index: number): void {
    const detail = this.springDetails.at(index);
    const springQuantity = detail.get('springQuantity')?.value;
    const leftHandWoundSprings = detail.get('leftHandWoundSprings')?.value || 0;
    const rightHandWoundSprings = detail.get('rightHandWoundSprings')?.value || 0;

    if (leftHandWoundSprings + rightHandWoundSprings > springQuantity) {
      if (leftHandWoundSprings > rightHandWoundSprings) {
        detail.get('rightHandWoundSprings')?.setValue(springQuantity - leftHandWoundSprings);
      } else {
        detail.get('leftHandWoundSprings')?.setValue(springQuantity - rightHandWoundSprings);
      }
    } else {
      detail.get('rightHandWoundSprings')?.setValue(springQuantity - leftHandWoundSprings);
    }
  }

  /**
   * Handles changes to left hand wound springs
   *
   * @param index The index of the changed spring detail
   */
  onLeftHandWoundSpringsChange(index: number): void {
    const detail = this.springDetails.at(index);
    const springQuantity = detail.get('springQuantity')?.value;
    const leftHandWoundSprings = detail.get('leftHandWoundSprings')?.value;

    if (leftHandWoundSprings > springQuantity) {
      detail.get('leftHandWoundSprings')?.setValue(springQuantity);
      detail.get('rightHandWoundSprings')?.setValue(0);
    } else {
      detail.get('rightHandWoundSprings')?.setValue(springQuantity - leftHandWoundSprings);
    }
  }

  /**
   * Handles changes to right hand wound springs
   *
   * @param index The index of the changed spring detail
   */
  onRightHandWoundSpringsChange(index: number): void {
    const detail = this.springDetails.at(index);
    const springQuantity = detail.get('springQuantity')?.value;
    const rightHandWoundSprings = detail.get('rightHandWoundSprings')?.value;

    if (rightHandWoundSprings > springQuantity) {
      detail.get('rightHandWoundSprings')?.setValue(springQuantity);
      detail.get('leftHandWoundSprings')?.setValue(0);
    } else {
      detail.get('leftHandWoundSprings')?.setValue(springQuantity - rightHandWoundSprings);
    }
  }

  /**
   * Sets window details in the form
   *
   * @param windows The window details to set
   */
  setWindowDetails(windows: Window[] = []) {
    const windowsTemp = windows.map((window) => this.fb.group(window));
    const windowsFormArray = this.fb.array(windowsTemp);
    this.doorForm.setControl('windows', windowsFormArray);
  }

  /**
   * Adds a new window to the form
   */
  addWindow() {
    this.windows.push(
      this.fb.group({
        glazingType: [''],
      })
    );
  }

  /**
   * Removes a window from the form
   *
   * @param i The index of the window to remove
   */
  removeWindow(i: number) {
    this.windows.removeAt(i);
  }

  /**
   * Checks if the door is electrically operated
   */
  checkElectricallyOperated(modeOfOperation: string) {
    if (modeOfOperation === 'Electric operator') {
      this.electricallyOperatedIsExpanded = true;
    }
  }

  /**
   * Takes a picture using the device camera
   */
  async takePicture() {
    const parts = this.router.url.split('/');
    parts.pop();
    const url = parts.join('/') + '/';
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
    });
    this.photo = image.dataUrl;
    const path = `${url.slice(1)}/doorPicture/`;

    // Picture Feature
    if (image.dataUrl) {
      this.downloadUrls.push(await this.fileUploadService.uploadImage(path, image.dataUrl, `photo_${Date.now()}.jpeg`));
    }
  }

  /**
   * Removes an image from the door
   *
   * @param index The index of the image to remove
   */
  removeImage(index: number): void {
    const downloadURL = this.downloadUrls[index];
    this.downloadUrls.splice(index, 1);
    this.fileUploadService.deletePic(downloadURL);
  }

  /**
   * Fetches door data from the lookup service
   */
  async fetchDoorDataFromLookUpService() {
    this.lookUpServiceDoorMap = await this.lookupService.loadDoorLookups();
    this.doorLookUpIsReady = true;

    // Set manufacturers and models
    // this.manufacturers = this.lookUpServiceDoorMap.doorManufacturers || [];
    // this.modelsByManufacturer = {};

    // this.lookUpServiceDoorMap.doorModels.forEach((model) => {
    //   if (!this.modelsByManufacturer[model.manufacturerId]) {
    //     this.modelsByManufacturer[model.manufacturerId] = [];
    //   }
    //   this.modelsByManufacturer[model.manufacturerId].push(model.name);
    // });
  }

  /**
   * Saves the door data
   */
  async saveDoor(autoSaveFlag: boolean) {
    // const tempVal = !this.allRequiredSectionsCompleted();
    // if (this.doorForm.invalid || tempVal) {
    //   console.log('Invalid Input for door or incomplete sections', this.doorForm.invalid);
    //   this.presentErrorToast('top');
    //   return;
    // }

    const door: OverHeadDoor = this.prepareDoorObject();

    try {
      this.presentInitialToast('top');
      if (!this.doorID) {
        door.safetyInspections = [];
        await this.ohdService.create(door).then(async (result) => {
          this.delay(250);
          this.presentLastToast('top');
          this.doorID = result.id;

          // Handle Auto Routing for Create
          if (!autoSaveFlag) {
            if (!this.isDoorStickerRoute) {
              this.router.navigate(['../', result.id, 'view'], { relativeTo: this.route });
            } else {
              this.router.navigate(['../', 'link'], { relativeTo: this.route });
            }
          } else {
            // this.onSectionComplete('doorID'); // Add door id
            // console.log('Auto save...', this.doorID);
            door.doorID = this.doorID;
            // console.log('Your door object with id is...', door);
            this.ohdService.setSelectedDoor(door);
            await this.ohdService.update(this.doorID, door).then((result) => {
              // this.delay(250);
              // this.presentLastToast('top');
            });
          }
        });
      } else {
        door.safetyInspections = this.ohdService.getSelectedDoor().safetyInspections;
        await this.ohdService.update(this.doorID, door).then((result) => {
          this.delay(250);
          this.presentLastToast('top');
        });
        if (autoSaveFlag) {
          console.log('Auto save...');
        }
      }
    } catch (error) {
      this.presentErrorToast('top');
      console.log('Error...', error);
    }
  }

  /**
   * Checks if all required sections are completed
   *
   * @returns True if all required sections are completed, false otherwise
   */
  allRequiredSectionsCompleted(): boolean {
    const requiredSections = [
      'doorID', // Add Door ID to required sections
      'environment',
      'operationFrequency',
      'manufactureDate',
      'doorTypes',
      'doorType',
      'manufacturer',
      'model',
      'doorSize',
      'liftConfiguration',
      'tracks',
      'rollers',
      'hinge',
      'shaft',
      'drumCables',
      'springs',
      'modeOfOperation',
    ];

    // Debug: Log the completed sections and the required sections
    // console.log('Completed Sections:', this.completedSections);
    // console.log('Required Sections:', requiredSections);

    const allCompleted = requiredSections.every((section) => {
      const isCompleted = this.completedSections.has(section);

      // Debug: Log the status of each section
      // console.log(`Section "${section}" completed:`, isCompleted);

      return isCompleted;
    });

    // Debug: Log the final result
    // console.log('All required sections completed:', allCompleted);

    return allCompleted;
  }

  /**
   * Prepares the door object from form values
   *
   * @returns The prepared OverHeadDoor object
   */
  prepareDoorObject(): OverHeadDoor {
    return {
      doorID: this.doorID || '',
      location: this.doorForm.value.doorLocation || '',
      doorTypes: this.doorForm.value.doorTypes,
      panelType: this.doorForm.value.doorPanelType || '',
      doorManufacturer: this.doorForm.value.doorManufacturer,
      doorModel: this.doorForm.value.doorModel,
      doorClassification: this.doorForm.value.doorClassification,
      doorType: this.doorForm.value.doorType,
      doorWidthFeet: this.doorForm.value.doorWidthFeet,
      doorWidthInches: this.doorForm.value.doorWidthInches,
      doorHeightFeet: this.doorForm.value.doorHeightFeet,
      doorHeightInches: this.doorForm.value.doorHeightInches,
      jambWallMountingMaterial: this.doorForm.value.jambWallMountingMaterial || '',
      liftSystem: this.doorForm.value.liftSystem || '',
      trackWidth: this.doorForm.value.trackWidth || '',
      trackHardware: this.doorForm.value.trackHardware || '',
      trackMountingMethod: this.doorForm.value.trackMountingMethod || '',
      torsionSpringType: this.doorForm.value.torsionSpringType || '',
      cableSize: this.doorForm.value.cableSize || '',
      cableLength: this.doorForm.value.cableLength || '',
      shaftDimension: this.doorForm.value.shaftDimension || '',
      rollerLength: this.doorForm.value.rollerLength || '',
      rollerSize: this.doorForm.value.rollerSize || '',
      drumSize: this.doorForm.value.drumSize || '',
      springDetails: this.doorForm.value.springDetails || '',
      accessories: this.doorForm.value.accessories,
      environmentCondition: this.doorForm.value.environmentCondition || '',
      dailyOperationFrequency: this.doorForm.value.dailyOperationFrequency || '',
      active: true,
      windows: this.doorForm.value.windows.map((window) => ({ glazingType: window.glazingType })),
      safetyDevices: this.doorForm.value.accessories.safetyDevices,
      operator: this.doorForm.value.operator,
      pictureUrl: this.downloadUrls,
      hingeType: this.doorForm.value.hingeType || '',
      hingeSetup: this.doorForm.value.hingeSetup || '',
      dateOfMfr: this.doorForm.value.dateOfMfr || '',
    };
  }

  // ============================== Navigation Step by Step Feature ==================

  toggleElectricallyOperatedOptions(event: any) {
    this.electricallyOperatedIsExpanded = event.detail.checked;
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  checkSpringNumber(index: number): void {
    const detail = this.springDetails.at(index);
    const springQuantity = detail.get('springQuantity')?.value;
    if (springQuantity === 0 || !springQuantity) {
      console.log('We do not have value 1...');
      return;
    }
    const leftHandWoundSprings = detail.get('leftHandWoundSprings')?.value;
    const rightHandWoundSprings = detail.get('rightHandWoundSprings')?.value;

    if (leftHandWoundSprings + rightHandWoundSprings !== springQuantity) {
      console.log('Error..');
      this.showAlert('Mismatch', 'The total number of springs does not match the defined quantity.');
      // detail.get('leftHandWoundSprings')?.setValue(springQuantity);
      // detail.get('rightHandWoundSprings')?.setValue(0);
    } else {
      console.log('Great job..');
      // detail.get('rightHandWoundSprings')?.setValue(springQuantity - leftHandWoundSprings);
    }
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  async showAlert(header: string, message: string): Promise<void> {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  // onRightHandWoundSpringsChange(index: number): void {
  //   const detail = this.springDetails.at(index);
  //   const springQuantity = detail.get('springQuantity')?.value;
  //   const rightHandWoundSprings = detail.get('rightHandWoundSprings')?.value;

  //   if (rightHandWoundSprings > springQuantity) {
  //     detail.get('rightHandWoundSprings')?.setValue(springQuantity);
  //     detail.get('leftHandWoundSprings')?.setValue(0);
  //   } else {
  //     detail.get('leftHandWoundSprings')?.setValue(springQuantity - rightHandWoundSprings);
  //   }
  // }

  /**
   * Presents an error toast message
   *
   * @param position The position of the toast
   */
  async presentErrorToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'Door was not saved, try again or contact support team...',
      duration: 2500,
      position,
    });

    await toast.present();
  }

  /**
   * Presents an initial toast message when saving
   *
   * @param position The position of the toast
   */
  async presentInitialToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'Door is saving...',
      duration: 1000,
      position,
    });

    await toast.present();
  }

  /**
   * Presents a final toast message after saving
   *
   * @param position The position of the toast
   */
  async presentLastToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'Door has been saved...',
      duration: 2500,
      position,
    });

    await toast.present();
  }

  /**
   * Helper method to create a delay
   *
   * @param ms The number of milliseconds to delay
   */
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Opens the QR scanner modal
   */
  async scanQRCode() {
    const modal = await this.modalController.create({
      component: QrScannerComponent,
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();
    if (data) {
      this.doorID = data;
      this.onSectionComplete('doorID');
    }
  }

  /*
    @Description: Method to navigate to the previous configuration screen
    @Comments:
    @Coders: jaam111000
  */
  previous() {
    if (this.tempIndex === 0) {
      return;
    }
    this.tempIndex--;
    this.handleTopTransition();
  }

  /*
      @Description: Method to navigate to the next configuration screen
      @Comments:
      @Coders: jaam111000
    */
  next() {
    this.tempIndex++;
    this.handleTopTransition();
  }

  handleTopTransition() {
    if (this.tempIndex < 4) {
      this.selectedSegment = 'generalTab';
    } else if (this.tempIndex < 12) {
      this.selectedSegment = 'doorDetails';
    } else if (this.tempIndex < 15) {
      this.selectedSegment = 'springAssembly';
    } else if (this.tempIndex < 16) {
      this.selectedSegment = 'operatorHoist';
    } else {
      this.selectedSegment = 'accessories';
    }
  }

  /**
   * Handles segment changes in the UI
   *
   * @param event The segment change event
   */
  segmentChanged(event: any) {
    this.selectedSegment = event.detail.value;
    if (this.selectedSegment === 'generalTab') {
      this.tempIndex = 0;
    } else if (this.selectedSegment === 'doorDetails') {
      this.tempIndex = 4;
    } else if (this.selectedSegment === 'springAssembly') {
      this.tempIndex = 12;
    } else if (this.selectedSegment === 'operatorHoist') {
      this.tempIndex = 15;
    } else {
      this.tempIndex = 16;
    }
  }

  checkDoorSizeCompletion() {
    const doorWidthFeet = this.doorForm.get('doorWidthFeet').value;
    const doorWidthInches = this.doorForm.get('doorWidthInches').value;
    const doorHeightFeet = this.doorForm.get('doorHeightFeet').value;
    const doorHeightInches = this.doorForm.get('doorHeightInches').value;

    if (doorWidthFeet !== '' && doorWidthInches !== '' && doorHeightFeet !== '' && doorHeightInches !== '') {
      this.onSectionComplete('doorSize');
    }
  }
}
