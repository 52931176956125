/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */
import { Injectable, Injector } from '@angular/core';
import { Auth, getAuth } from '@angular/fire/auth';
import { Observable, fromEventPattern } from 'rxjs';
import { DealerUser } from '../model/User';
// import { LoadingController } from '@ionic/angular';
// User Imports
import { UserService } from './user.service';
import { DealerService } from './dealer.service';
import { CustomerUserPolicy } from '../model/UserPrivacy';

import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  loading: HTMLIonLoadingElement | null = null;
  public user$: Observable<any>;

  // Constructor
  constructor(public auth: Auth, private dealerService: DealerService, private customerService: CustomerService) {
    this.user$ = fromEventPattern(
      (handler) => this.auth.onAuthStateChanged(handler),
      (handler, unsubscribe) => unsubscribe()
    );
  }

  /*
    @Description: Public Method to show Spinner Loader
    @Comments:
    @Coders: jaam111000
  */
  // async presentLoading() {
  //   // console.log('present loading...');
  //   if (this.loading) {
  //     console.log('already loading, so dismiss...');
  //     await this.loading.dismiss();
  //     return;
  //   }
  //   this.loading = await this.loadingController.create({
  //     message: 'Loading App...',
  //     spinner: 'circles',
  //   });
  //   // console.log('Presenting loading...');
  //   await this.loading.present();
  // }

  /*
    @Description: Public Method to dismiss spinner loader
    @Comments:
    @Coders: jaam111000
  */
  // async dismissLoading() {
  //   // console.log('dismiss loading');
  //   if (this.loading) {
  //     // console.log('there is object so dismiss...');
  //     await this.loading.dismiss();
  //     this.loading = null;
  //   }
  // }

  // getLoading() {
  //   return this.loading;
  // }

  // =========================== Start of Permissions-related methods =================

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  isUserSignedIn(): boolean {
    // Check for Route based on permissions
    if (getAuth().currentUser) {
      return true;
    } else {
      return false;
    }
  }

  /*
    @Description: Public Method to get the current user's ID
    @Comments:
    @Coders: AI Assistant
  */
  getUserId(): string | null {
    const user = getAuth().currentUser;
    return user ? user.uid : null;
  }
}
