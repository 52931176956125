/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import {
  Firestore,
  DocumentReference,
  DocumentData,
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  getDoc,
  getDocs,
  setDoc,
} from '@angular/fire/firestore';
import { OverHeadDoor } from '../model/OverHeadDoor';
import { BuildingService } from './building.service';
import { CustomerService } from './customer.service';
import { DealerService } from './dealer.service';
import { UserService } from './user.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OhdoorService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly OVER_HEAD_DOOR_COLLECTION = 'overhead-doors';

  // State Observable
  public doorSubject = new BehaviorSubject<any>(null);
  doorTemp$ = this.doorSubject.asObservable();

  private selectedDoor: OverHeadDoor = null;

  constructor(
    private firestore: Firestore,
    private dealerService: DealerService,
    private customerService: CustomerService,
    private buildingService: BuildingService,
    private userService: UserService
  ) {}

  /*
    @Description: Function to get the selected Door
    @Comments:
    @Coders: jaam111000
  */
  getSelectedDoor(): OverHeadDoor {
    return this.selectedDoor;
  }

  /*
      @Description: Function to set the selected door
      @Comments:
      @Coders: jaam111000
    */
  setSelectedDoor(tempDoor: OverHeadDoor) {
    this.doorSubject.next(tempDoor);
    this.selectedDoor = tempDoor;
  }
  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  canCreateInspection(): boolean {
    try {
      if (
        this.userService.getUserCustomerPolicy().canCreateInspections ||
        (this.userService.getUserCollectionObj().customers === undefined && this.dealerService.canCreateCustomers())
      ) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can add dealers');
    }
    return;
  }
  /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
    */
  canReadInspection(): boolean {
    try {
      if (
        this.userService.getUserCustomerPolicy().canReadInspections ||
        (this.userService.getUserCollectionObj().customers === undefined && this.dealerService.canReadCustomers())
      ) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can add dealers');
    }
    return;
  }
  /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
    */
  canUpdateInspection(): boolean {
    try {
      if (
        this.userService.getUserCustomerPolicy().canUpdateInspections ||
        (this.userService.getUserCollectionObj().customers === undefined && this.dealerService.canUpdateCustomers())
      ) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can update inspections');
    }
    return;
  }
  /*
  ` @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  canDeleteInspection(): boolean {
    try {
      if (
        this.userService.getUserCustomerPolicy().canDeleteInspections ||
        (this.userService.getUserCollectionObj().customers === undefined && this.dealerService.canDeleteCustomers())
      ) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can add dealers');
    }
  }
  // =================================== FIREBASE FUNCTIONS =========

  /*
    @Description: Function to set the selected door
    @Comments:
    @Coders: jaam111000
  */
  async create(ohd: OverHeadDoor): Promise<DocumentReference<DocumentData>> {
    const collectionRef = collection(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      this.dealerService.getSelectedDealer().id,
      CustomerService.CUSTOMERS_COLLECTION,
      this.customerService.getSelectedCustomer().id,
      BuildingService.BUILDINGS_COLLECTION,
      this.buildingService.getSelectedBuilding().id,
      OhdoorService.OVER_HEAD_DOOR_COLLECTION
    );
    return addDoc(collectionRef, ohd);
  }

  /*
    ` @Description: Public Method
      @Comments:
      @Coders: jaam111000
    */
  async read(ohdId: string): Promise<OverHeadDoor | null> {
    const docRef = doc(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      this.dealerService.getSelectedDealer().id,
      CustomerService.CUSTOMERS_COLLECTION,
      this.customerService.getSelectedCustomer().id,
      BuildingService.BUILDINGS_COLLECTION,
      this.buildingService.getSelectedBuilding().id,
      OhdoorService.OVER_HEAD_DOOR_COLLECTION,
      ohdId
    );
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const d = docSnap.data() as OverHeadDoor;
      d.id = docSnap.id;
      return d;
    } else {
      return null;
    }
  }

  /*
      @Description: Function to get Doors associated to dealer, customer, and building
      @Comments:
      @Coders: jaam111000
    */
  async getDoorAfterQRScan(
    dealerID: string,
    customerID: string,
    buildingID: string,
    doorID: string
  ): Promise<OverHeadDoor> {
    const docRef = doc(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      dealerID,
      CustomerService.CUSTOMERS_COLLECTION,
      customerID,
      BuildingService.BUILDINGS_COLLECTION,
      buildingID,
      OhdoorService.OVER_HEAD_DOOR_COLLECTION,
      doorID
    );
    const docSnap = await getDoc(docRef);
    return docSnap.data() as any;
  }

  /*
    @Description: Function to get Doors associated to dealer, customer, and building
    @Comments:
    @Coders: jaam111000
  */
  async getAllDoors(): Promise<OverHeadDoor[]> {
    const docRef = doc(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      this.dealerService.getSelectedDealer().id,
      CustomerService.CUSTOMERS_COLLECTION,
      this.customerService.getSelectedCustomer().id,
      BuildingService.BUILDINGS_COLLECTION,
      this.buildingService.getSelectedBuilding().id
    );

    const querySnapshot = await getDocs(collection(docRef, OhdoorService.OVER_HEAD_DOOR_COLLECTION));
    return querySnapshot.docs
      .filter((tempDoc) => {
        const data = tempDoc.data();
        return data.active;
      })
      .map((docTemp) => {
        const id = docTemp.id;
        const data = docTemp.data();
        data.id = id;
        return data;
      }) as OverHeadDoor[];
  }

  /*
  ` @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async update(ohdId: string, ohd: OverHeadDoor): Promise<void> {
    const docRef = doc(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      this.dealerService.getSelectedDealer().id,
      CustomerService.CUSTOMERS_COLLECTION,
      this.customerService.getSelectedCustomer().id,
      BuildingService.BUILDINGS_COLLECTION,
      this.buildingService.getSelectedBuilding().id,
      OhdoorService.OVER_HEAD_DOOR_COLLECTION,
      ohdId
    );
    ohd.id = ohdId;
    const selectedDoor = setDoc(docRef, ohd, { merge: true });
    this.setSelectedDoor(ohd);
    this.doorSubject.next(ohd);
    return selectedDoor;
    // await updateDoc(docRef, ohd);
  }

  /*
  ` @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async delete(ohdId: string): Promise<void> {
    const docRef = doc(this.firestore, OhdoorService.OVER_HEAD_DOOR_COLLECTION, ohdId);
    await deleteDoc(docRef);
  }
}
