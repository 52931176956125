/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { ref, Storage, uploadBytes, getDownloadURL } from '@angular/fire/storage';
import { InspectionItem } from 'src/app/model/SafetyInspection';
import { ModalController } from '@ionic/angular';
import { AppstateService } from 'src/app/service/appstate.service';

import { FileUploadService } from 'src/app/service/fileUploadService';
import { Router } from '@angular/router';
import { PictureModalComponent } from '../picture-modal/picture-modal.component';
import { Timestamp } from '@angular/fire/firestore';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-inspection-item',
  templateUrl: './inspection-item.component.html',
  styleUrls: ['./inspection-item.component.scss'],
})
export class InspectionItemComponent {
  @Input() inspectionItem: InspectionItem;
  @Input() inspectionId: string;
  @Input() currentIndex: number;
  @Input() canEdit: boolean;
  @Input() inspectionDate: Timestamp;
  @Input() autoSave!: (stringCaller: string) => Promise<void>;
  @Output() inspectionItemChange = new EventEmitter<InspectionItem>();
  @Output() refineText = new EventEmitter<{ index: number; text: string }>();
  photo: string | null = null;
  comment: string;
  showPictureComment: boolean = false;
  chatButtonUsageCount: number = 0;

  // eslint-disable-next-line max-len
  constructor(
    private userService: UserService,
    private fileUploadService: FileUploadService,
    private router: Router,
    public modalController: ModalController
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentIndex) {
      this.currentIndex = changes.currentIndex.currentValue;
      this.chatButtonUsageCount = 0; // Reset chat button usage count when changing inspection items
    }
  }

  handleComment() {
    this.inspectionItem.pics.push({ url: this.photo, comment: this.comment });
    this.photo = null;
    this.comment = null;
    this.autoSave('handleComment');
    this.showPictureComment = false;
  }

  showHelpText() {
    alert(this.inspectionItem.helpText);
  }

  onImageSelected(event: CustomEvent) {
    console.log('Selected image URL:', event.detail.value);
  }

  setStatus(newItem: InspectionItem): void {
    if (newItem.status !== this.inspectionItem.status) {
      this.inspectionItem.status = newItem.status;
      this.inspectionItemChange.emit(this.inspectionItem);
    }
    this.autoSave('setStatus');
  }

  async addRecommendation(textInput: CustomEvent) {
    this.inspectionItem.recommendations = textInput.detail.value;
    this.inspectionItemChange.emit(this.inspectionItem);
    this.autoSave('addRecommendation');
  }

  async viewPictures() {
    const modal = await this.modalController.create({
      component: PictureModalComponent,
      componentProps: { imageUrls: this.inspectionItem.pics, picDetails: this.inspectionItem.picDetails },
    });
    return await modal.present();
  }

  async takePicture() {
    const parts = this.router.url.split('/');
    parts.pop();
    const url = parts.join('/') + '/';
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
    });
    this.photo = image.dataUrl;
    this.showPictureComment=true;
    const path = `${url.slice(1)}/${this.currentIndex}/`;

    if (image.dataUrl) {
      const downloadUrl = await this.fileUploadService.uploadImage(path, image.dataUrl, `photo_${Date.now()}.jpeg`);
    }
  }

  refineTextClicked() {
    this.refineText.emit({
      index: this.currentIndex,
      text: this.inspectionItem.recommendations
    });
  }

  canUseChatButton(): boolean {
    return this.chatButtonUsageCount < 2;
  }

  useChatButton() {
    if (this.canUseChatButton()) {
      this.chatButtonUsageCount++;
      // Add your chat functionality here
      console.log('Chat button clicked. Usage count:', this.chatButtonUsageCount);
    }
  }
}
