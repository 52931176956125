import { Injectable } from '@angular/core';
import { Storage, ref, uploadBytes, getDownloadURL, deleteObject } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private storage: Storage) {}

  // Function to convert dataUrl to a Blob
  dataURLtoBlob(dataUrl) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  async uploadImage(path, dataUrl: string, fileName: string) {
    const filePath = `${path}${fileName}`;
    const storageRef = ref(this.storage, filePath);
    const blob = this.dataURLtoBlob(dataUrl);

    try {
      const snapshot = await uploadBytes(storageRef, blob);
      const downloadURL = await getDownloadURL(snapshot.ref);
      // console.log('File available at', downloadURL);
      // Here you can save the URL to a database or do something else with it
      return downloadURL;
    } catch (error) {
      console.error('Upload failed', error);
      return null;
    }
  }

  async deletePic(urlPath: string) {
    const fileRef = ref(this.storage, urlPath);

    try {
      await deleteObject(fileRef);
      console.log('File deleted successfully');
    } catch (error) {
      console.error('Delete failed', error);
    }
  }
}
