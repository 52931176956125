// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/app/not-found/not-found.component.css */
.not-found {
  text-align: center;
  margin-top: 50px;
}

.not-found h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.not-found p {
  font-size: 24px;
  margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/not-found/not-found.component.scss"],"names":[],"mappings":"AAAA,8CAAA;AACA;EACI,kBAAA;EACA,gBAAA;AACJ;;AACA;EACI,eAAA;EACA,mBAAA;AAEJ;;AAAA;EACI,eAAA;EACA,mBAAA;AAGJ","sourcesContent":["/* src/app/not-found/not-found.component.css */\r\n.not-found {\r\n    text-align: center;\r\n    margin-top: 50px;\r\n}\r\n.not-found h1 {\r\n    font-size: 48px;\r\n    margin-bottom: 20px;\r\n}\r\n.not-found p {\r\n    font-size: 24px;\r\n    margin-bottom: 30px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
