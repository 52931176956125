import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ChatService } from '../../service/chat.service';
import { OpenAIService } from '../../service/openai.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chat-fab',
  templateUrl: './chat-fab.component.html',
  styleUrls: ['./chat-fab.component.scss']
})
export class ChatFabComponent implements OnInit, OnDestroy {
  messages: any[] = [];
  isChatOpen: boolean = false;
  newMessage: string = '';
  private messagesSubscription: Subscription;

  constructor(
    private chatService: ChatService,
    private openAIService: OpenAIService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    console.log('ChatFabComponent initialized');
    this.loadMessages();
  }

  ngOnDestroy() {
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
  }

  loadMessages() {
    console.log('Loading messages');
    this.messagesSubscription = this.chatService.getMessages().subscribe(messages => {
      console.log('Received messages:', messages);
      this.messages = messages;
      this.changeDetectorRef.detectChanges();
    });
  }

  async sendMessage() {
    console.log('Send button clicked');
    console.log('Current newMessage:', this.newMessage);
    if (this.newMessage.trim()) {
      console.log('Sending message:', this.newMessage);
      const userMessage = {
        text: this.newMessage,
        sender: 'user',
        timestamp: new Date()
      };
      this.chatService.addMessage(userMessage);
      
      // Get AI response
      const prompt = `you are a no nonsense overhead door technician that Responds concisely : ${this.newMessage}`;
      console.log('Sending prompt to OpenAI:', prompt);
      try {
        const aiResponse = await this.openAIService.getResponse(prompt);
        console.log('Received AI response:', aiResponse);
        const assistantMessage = {
          text: aiResponse,
          sender: 'assistant',
          timestamp: new Date()
        };
        this.chatService.addMessage(assistantMessage);
      } catch (error) {
        console.error('Error getting AI response:', error);
      }
      
      this.newMessage = '';
      this.changeDetectorRef.detectChanges();
    }
  }

  toggleChat() {
    console.log('Toggling chat');
    this.isChatOpen = !this.isChatOpen;
    if (this.isChatOpen) {
      this.initializeChat();
    } else {
      this.clearChat();
    }
    this.changeDetectorRef.detectChanges();
  }

  clearChat() {
    console.log('Clearing chat');
    this.chatService.clearMessages();
    this.messages = [];
    this.changeDetectorRef.detectChanges();
  }

  initializeChat() {
    console.log('Initializing chat');
    this.clearChat();
    const initialMessage = {
      text: "Hello, how can I help you today?",
      sender: 'assistant',
      timestamp: new Date()
    };
    this.chatService.addMessage(initialMessage);
  }
}