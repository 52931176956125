/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ref, Storage, uploadBytes } from '@angular/fire/storage';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MenuController,AlertController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
// User Imports
import { InspectionService } from 'src/app/service/inspection.service';
import { InspectionItem, SafetyInspection } from 'src/app/model/SafetyInspection';
import { Timestamp } from '@angular/fire/firestore';
import { OhdoorService } from 'src/app/service/ohdoor.service';
import { Location } from '@angular/common';
import { OpenAIService } from 'src/app/service/openai.service';
import { SecurityService } from 'src/app/service/security.service';

@Component({
  selector: 'app-safety-inspection',
  templateUrl: './safety-inspection.component.html',
  styleUrls: ['./safety-inspection.component.scss'],
  providers: [DatePipe], // Add the DatePipe here
})
export class SafetyInspectionComponent implements OnInit {
  showSidebar = false;
  isReady2Display = false;
  inspection: SafetyInspection;
  inspectionForm: FormGroup;

  // currentIndex = 0; // Tracks the current item index
  currentItem: any; // Holds the current item for display
  inspectionItemId: string;
  inspectionItemHelpText: string;
  uploadProgress: Observable<number>;
  inspectionId: string;
  isListView = false;
  isAlertOpen = false;
  userId: string | null;
  public alertButtons = [
    {
      text: 'No',
      role: 'no',
      handler: () => {
        console.log('Alert canceled');
      },
    },
    {
      text: 'Yes',
      role: 'confirm',
      handler: async () => {
        this.inspection.completedDate= Timestamp.now();
        const newCompletedDate=this.inspection.completedDate.toDate();
        newCompletedDate.setDate(newCompletedDate.getDate()+1);
        this.inspection.reOpenDate= Timestamp.fromDate(newCompletedDate);
        await this.inspectionService.updateInspection(this.inspection,true); //true is for the complete flag as user wants to complete inspection
        this.inspection.status='Completed';
        const index = this.ohdoorService.getSelectedDoor().safetyInspections.findIndex(inspec => inspec.id === this.inspectionService.getSelectedInspection().id);
        this.ohdoorService.getSelectedDoor().safetyInspections[index]=this.inspection;

      },
    },
  ];

  // Class Constructor
  constructor(
    private formBuilder: FormBuilder,
    private inspectionService: InspectionService,
    private ohdoorService: OhdoorService,
    private menuCtrl: MenuController,
    private alertController: AlertController,
    private location: Location,
    private toastController: ToastController,
    private openAIService: OpenAIService,
    private securityService: SecurityService
  ) {
    this.buildForm();
  }
  // Helper method to create a delay
    delay(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
  /*
    @Description: Function that checks if there are any inspection items left unchecked
    @Comments:
    @Coders:Rohan-16
  */
    async presentAlert() {
      let alert;
      this.isAlertOpen= true;
      if(this.isInspectionCompleted()){
        alert = await this.alertController.create({
          header: 'Are you sure you want to complete inspection?',
          buttons: this.alertButtons,
        });
      }
      else{
        alert = await this.alertController.create({
          header: 'Are you sure you want to complete inspection?',
          subHeader: 'These inspection items are not checked:',
          message: this.getUncheckedInspectionItems().join(', '),
          buttons: this.alertButtons,
        });
      }

      await alert.present();
      const { role } = await alert.onDidDismiss();
      this.location.back();

    }
    /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
    */
    async presentInitialToast(position: 'top' | 'middle' | 'bottom') {
      const toast = await this.toastController.create({
        message: 'Safety inspection is saving...',
        duration: 1000,
        position,
      });
        await toast.present();
      };
  /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
  */
  async presentLastToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'Safety inspection has been saved...',
      duration: 2500,
      position,
    });

    await toast.present();
  }
  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
    async presentErrorToast(position: 'top' | 'middle' | 'bottom') {
      const toast = await this.toastController.create({
        message: 'Building was not saved, try again or contact support team...',
        duration: 2500,
        position,
      });
      await toast.present();
    }

  /*
    @Description: Public Method to get private service
    @Comments:
    @Coders: jaaam111000
  */
  getMyInspectionService(): InspectionService {
    return this.inspectionService;
  }
  /*
    @Description: Function to get if the whole inspection is completed
    @Comments:
    @Coders: Rohan-16
  */
  isInspectionCompleted(): boolean{
    return !this.inspection.inspectionItems.some((item) => item.status === 'unchecked');
  }
  /*
    @Description: Function that gets a list of unchecked inspection
    @Comments:
    @Coders: Rohan-16
  */
  getUncheckedInspectionItems(): string[]{
    const inspectionItemStatus=(this.inspection.inspectionItems.map((item) => item.status ));
    const uncheckedItems = [];
    for (let i=0;i<inspectionItemStatus.length;i++) {
      if (inspectionItemStatus[i] === 'unchecked'){
        uncheckedItems.push(this.inspection.inspectionItems[i].text);
      }
    }
    return uncheckedItems;
  }


  /*
    @Description: Public Method to save data
    @Comments:
    @Coders:
  */
  async ngOnInit() {
    this.userId = this.securityService.getUserId();
    this.inspection = await this.inspectionService.getInspection(this.inspectionService.getSelectedInspection().id);
    // console.log('Your inspections is...', this.inspection);
    this.inspectionId = this.inspection.id;
    this.updateCurrentItem(); // Initialize the currentItem with the first item
    this.inspectionForm.patchValue(this.inspection);
    this.inspectionService.checkInspectionExpiry(this.inspection);

    // Function sets flag to display Safety Menu
    this.inspectionService.storeTempInspection4Menu(this.inspection);
    this.isReady2Display = true;
  }

  /*
    @Description: Previous item in the caroussel
    @Comments:
    @Coders:Rohan-16
  */
  previous() {
    if (this.inspectionService.getIndex() > 0) {
      this.inspectionService.decreaseIndex();
      this.updateCurrentItem();
    }
  }

  /*
    @Description: Nect item in the caroussel
    @Comments:
    @Coders:Rohan-16
  */
  next() {
    if (this.inspectionService.getIndex() < this.inspection.inspectionItems.length - 1) {
      this.inspectionService.increaseIndex();
      this.updateCurrentItem();
    }
  }

  /*
    @Description: Public Method to change the current item for caroussel
    @Comments:
    @Coders:Rohan-16
  */
  updateCurrentItem() {
    this.currentItem = this.inspection.inspectionItems[this.inspectionService.getIndex()];
    this.autoSave('updateCurrentItem');
  }
  /*
    @Description: Public Method to check if we can edit inspection
    @Comments:
    @Coders:Rohan-16
  */
  canEdit() {
    return !this.inspectionService.canEditInspection(this.inspection);
  }

  /*
    @Description: Manipulate the sidebar found in safety inspection page
    @Comments:
    @Coders: jaam111000
  */
  toggleSidebar() {
    // this.showSidebar = !this.showSidebar;
    this.menuCtrl.toggle('safety-menu');
    // this.autoSave('toggleSidebar');
  }

  /*
    @Description: Public Method to change the text of your Help Icon
    @Comments:
    @Coders:
  */
  onHelpIconClick(helpText: string) {
    this.inspectionItemHelpText = helpText;
  }

  /*
    @Description: public method to change view of inspection
    @Comments:
    @Coders:Rohan-16
  */
  toggleViewMode() {
    this.isListView = !this.isListView;
  }

  /*
    @Description: Public Method to save data
    @Comments:
    @Coders:
  */
  setStatus($event) {
    console.log($event);
  }

  /*
    @Description: Public Method to save data
    @Comments:
    @Coders:Rohan-16
  */
  setOpen(isOpen: boolean) {
    this.isAlertOpen = isOpen;
  }

  /*
    @Description: Public Method to save data
    @Comments:
    @Coders: rohan-16
  */
  async onSaveButtonClick() {
    // console.log('You pressed save inspection...', this.inspection);
    try{
      this.presentInitialToast('top');
      this.inspectionService.updateInspection(this.inspection,false).then(async (result)=>{
        await this.delay(250); // Wait for 500ms to appreciate the first toast
        this.presentLastToast('top');
       }); //False is for the complete flag
    }catch(error){
      this.presentErrorToast('top');
      console.log('The error is',error);
    }

  }
    /*
    @Description: Public Method to check if we can edit inspection
    @Comments:
    @Coders:Rohan-16
  */
 async openInspection(){
   this.inspection.status= 'Re-Opened';
   await this.inspectionService.updateInspection(this.inspection,false); //False is for the complete flag
   const index = this.ohdoorService.getSelectedDoor().safetyInspections.findIndex(inspec => inspec.id === this.inspectionService.getSelectedInspection().id);
   this.ohdoorService.getSelectedDoor().safetyInspections[index]=this.inspection;
 }

     /*
    @Description: check if reopen date is valid
    @Comments:
    @Coders:Rohan-16
  */
 validReOpenDate(){
  if(this.inspection.status === 'Completed'){
    const reOpenDate=this.inspection.reOpenDate.toDate();
    const now=new Date();
    return (now <= reOpenDate && this.inspection.status === 'Completed');
  }
  else{
    return false;
  }

 }

  /*
    @Description: Public Method to save data
    @Comments:
    @Coders: jaam111000
  */
  async autoSave(stringCaller: string): Promise<void> {
    // console.log('Auto saved was called from...', stringCaller);
    // console.log('Form is autosaved now...', this.inspection);
    await this.inspectionService.updateInspection(this.inspection,false); //False is for the complete flag
  }

  /*
    @Description: Private Method to build inspection form
    @Comments:
    @Coders:
  */
  private buildForm() {
    this.inspectionForm = this.formBuilder.group({
      notes: ['', Validators.required],
      inspectedBy: [[]],
      inspectionItems: this.formBuilder.array([]),
    });
  }

  /*
    @Description: Method to handle refining text
    @Comments:
    @Coders: AI Assistant
  */
  async refineText(event: { index: number; text: string }) {
    try {
      const prompt = this.generateInspectionPrompt(this.inspection.inspectionItems[event.index]);
      const refinedText = await this.openAIService.getResponse(prompt + "\n\nOriginal text: " + event.text);
      this.inspection.inspectionItems[event.index].recommendations = refinedText;
      await this.autoSave('refineText');
      this.presentLastToast('top');
    } catch (error) {
      console.error('Error refining text:', error);
      this.presentErrorToast('top');
    }
  }

  /*
    @Description: Method to generate dynamic inspection prompt
    @Comments:
    @Coders: AI Assistant
  */
  private generateInspectionPrompt(inspectionItem: InspectionItem): string {
    const label = inspectionItem.text;
    const guidance = inspectionItem.inspectionGuidance || 'No specific guidance provided';
    
    return `As an overhead door technician, rewrite the following inspection findings to be clearer and more detailed. The inspection is about the '${label}' of an overhead door. Consider this inspection guidance: '${guidance}'. Expand on the findings using correct terminology for overhead doors. Focus on factual observations and technical details. Do not offer opinions or suggestions. Ensure the rewrite is clear, concise, and uses industry-standard terms.`;
  }
}
