import { Routes } from '@angular/router';
import { signOut } from '@angular/fire/auth';
import { ModalController, MenuController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//User Imports
import { AppRoutingModule } from 'src/app/app-routing.module';
import { DealerService } from 'src/app/service/dealer.service';
import { SecurityService } from 'src/app/service/security.service';
import { QrScannerComponent } from '../qr-scanner/qr-scanner.component';
import { CustomerService } from 'src/app/service/customer.service';
import { Dealer } from 'src/app/model/Dealer';
import { UserService } from 'src/app/service/user.service';
import { InspectionService } from 'src/app/service/inspection.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './app-side-bar.component.html',
  styleUrls: ['./app-side-bar.component.scss'],
})
export class AppSideBarComponent implements OnInit {
  showScanner = false;
  curDealerID: string;

  constructor(
    private dealerService: DealerService, // Used by HTML to build dealer routes
    private securityService: SecurityService,
    private customerService: CustomerService, // Used by HTML to build customer routes
    private routingModule: AppRoutingModule,
    private userService: UserService,
    private modalController: ModalController,
    private http: HttpClient,
    private menuController: MenuController,
    private inspectionService: InspectionService
  ) {}

  getMyDealerService(): DealerService {
    return this.dealerService;
  }

  getMyUserService(): UserService {
    return this.userService;
  }

  getMySecurityService(): SecurityService {
    return this.securityService;
  }

  getMyInspectionService(): InspectionService {
    return this.inspectionService;
  }

  getMyCustomerService(): CustomerService {
    return this.customerService;
  }

  getMyAppPages(): Routes {
    return this.routingModule.getMyAppPages();
  }

  ngOnInit() {}

  async openQrScanner() {
    const modal = await this.modalController.create({
      component: QrScannerComponent,
    });
    this.closeMenu();
    return await modal.present();
  }

  /*
    @Description: Routine that runs when user logs out by clicking button on sidebar
    @Comments:
    @Coders: jaam
  */
  async onLogout() {
    console.log('Successful Log out, see you soon...');
    await signOut(this.userService.getMyAuthObject()).catch((e) => console.log('Your error when logging out is...', e));
    this.routingModule.router.navigate(['/']);
  }

  route2CustomerAddPage() {
    this.routingModule.router.navigate(['./dealer', this.dealerService.getSelectedDealer().id, 'customer', 'create'], {
      queryParams: {
        createRoute: true,
      },
    });
  }

  async navigateAndCloseMenu(path: string[]) {
    await this.routingModule.router.navigate(path);
    this.closeMenu();
  }

  /*
      @Description: Routine that....
      @Comments:
      @Coders: Rohan-16
    */
  toggleScanner() {
    this.showScanner = !this.showScanner;
  }

  toggleViewMode() {
    console.log('Implement view mode...');
  }

  closeMenu() {
    this.menuController.close(); // Close the menu
  }

  getIconName(inspectionStatus: string): string {
    switch (inspectionStatus) {
      case 'passed':
        return 'checkmark';
      case 'failed':
        return 'close';
      case 'not-applicable':
        return 'ban';
      default:
        return 'square-outline';
    }
  }
}
