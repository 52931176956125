/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import {
  addDoc,
  getDocs,
  doc,
  collection,
  Firestore,
  DocumentReference,
  DocumentData,
  getDoc,
  updateDoc,
  deleteDoc,
  setDoc,
} from '@angular/fire/firestore';

// User Imports
import { Building } from '../model/Building';
import { CustomerService } from './customer.service';
import { DealerService } from './dealer.service';
import { UserService } from './user.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BuildingService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly BUILDINGS_COLLECTION = 'buildings';

  // State Observable
  public buildingSubject = new BehaviorSubject<any>(null);
  buildingTemp$ = this.buildingSubject.asObservable();

  private selectedBuilding: Building;
  private buildings: Building[] = [];

  // Constructor of Class
  constructor(
    private firestore: Firestore,
    private dealerService: DealerService,
    private userService: UserService,
    private customerService: CustomerService
  ) {}

  /*
    @Description: Function to get the selected building
    @Comments:
    @Coders: jaam111000
  */
  getSelectedBuilding(): Building {
    return this.selectedBuilding;
  }
  /*
    @Description: Public Method to get Buildings
    @Comments:
    @Coders:Rohan-16
  */
  getBuildings(): Building[] {
    return this.buildings;
  }
  /*
    @Description: Public Method to set Buildings
    @Comments:
    @Coders:
    */
  setBuildings(buildings: Building[]): void {
    this.buildings = buildings;
  }
  /*
    @Description: Public Method to Update Buildings
    @Comments:
    @Coders:
    */
  updateBuildings(building: Building): void {
    this.buildings.push(building);
  }

  /*
    @Description: Function to set the selected building
    @Comments:
    @Coders: jaam111000
  */
  setSelectedBuilding(tempBuilding: Building) {
    this.buildingSubject.next(tempBuilding);
    this.selectedBuilding = tempBuilding;
  }

  async create(building: Building): Promise<DocumentReference<DocumentData>> {
    const collectionRef = collection(
      doc(
        doc(this.firestore, DealerService.DEALERS_COLLECTION, this.dealerService.getSelectedDealer().id),
        CustomerService.CUSTOMERS_COLLECTION,
        this.customerService.getSelectedCustomer().id
      ),
      BuildingService.BUILDINGS_COLLECTION
    );
    this.updateBuildings(building);
    return addDoc(collectionRef, building);
  }

  async read(buildingId: string): Promise<Building | null> {
    const docRef = doc(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      this.dealerService.getSelectedDealer().id,
      CustomerService.CUSTOMERS_COLLECTION,
      this.customerService.getSelectedCustomer().id,
      BuildingService.BUILDINGS_COLLECTION,
      buildingId
    );
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data() as Building;
    } else {
      return null;
    }
  }

  async update(buildingId: string, building: Building): Promise<void> {
    const docRef = doc(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      this.dealerService.getSelectedDealer().id,
      CustomerService.CUSTOMERS_COLLECTION,
      this.customerService.getSelectedCustomer().id,
      BuildingService.BUILDINGS_COLLECTION,
      buildingId
    );
    building.id = buildingId;
    this.buildingSubject.next(building);
    this.setSelectedBuilding(building);
    const selectedBuilding = setDoc(docRef, building, { merge: true });
    return selectedBuilding;
  }

  // async delete(buildingId: string): Promise<void> {
  //   const docRef = doc(this.firestore, BuildingService.BUILDINGS_COLLECTION, buildingId);
  //   await deleteDoc(docRef);
  // }
  async getAllBuildings(): Promise<Building[]> {
    const buildingsRef = collection(
      this.firestore,
      DealerService.DEALERS_COLLECTION,
      this.dealerService.getSelectedDealer().id,
      CustomerService.CUSTOMERS_COLLECTION,
      this.customerService.getSelectedCustomer().id,
      BuildingService.BUILDINGS_COLLECTION
    );
    const buildingsSnapshot = await getDocs(buildingsRef);

    this.buildings = buildingsSnapshot.docs
      .filter((tempDoc) => {
        const data = tempDoc.data();
        return !data.deleted;
      })
      .map((tempDoc) => {
        const id = tempDoc.id;
        const data = tempDoc.data();
        data.id = id;
        return data;
      }) as Building[];
    return this.buildings;
  }
  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  canCreateDoors(): boolean {
    try {
      if (
        this.userService.isUserSmartDoorAdmin() ||
        this.userService.getUserCustomerPolicy()?.canCreateDoors ||
        (this.userService.getUserCollectionObj().customers === undefined && this.dealerService.canCreateCustomers())
      ) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can create doors', error);
    }
  }
  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  canReadDoors(): boolean {
    try {
      if (
        this.userService.isUserSmartDoorAdmin() ||
        this.userService.getUserCustomerPolicy()?.canReadDoors ||
        (this.userService.getUserCollectionObj().customers === undefined && this.dealerService.canReadCustomers())
      ) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can add dealers');
    }
    return;
  }
  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  canUpdateDoors(): boolean {
    try {
      if (
        this.userService.isUserSmartDoorAdmin() ||
        this.userService.getUserCustomerPolicy()?.canUpdateDoors ||
        (this.userService.getUserCollectionObj().customers === undefined && this.dealerService.canUpdateCustomers())
      ) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can add dealers');
    }
    return;
  }
  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  canDeleteDoors(): boolean {
    try {
      if (
        this.userService.isUserSmartDoorAdmin() ||
        this.userService.getUserCustomerPolicy()?.canDeleteDoors ||
        (this.userService.getUserCollectionObj().customers === undefined && this.dealerService.canDeleteCustomers())
      ) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('Error while checking if user can add dealers');
    }
  }
}
