import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Building } from 'src/app/model/Building';
import { BuildingService } from 'src/app/service/building.service';
import { GeolocationService } from 'src/app/service/geolocation.service';
import { ToastController } from '@ionic/angular';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { FileUploadService } from 'src/app/service/fileUploadService';
import { AddressGoogle } from 'src/app/model/AddressGoogle';

@Component({
  selector: 'app-building-ae',
  templateUrl: './building-ae.component.html',
  styleUrls: ['./building-ae.component.scss'],
})
export class BuildingAeComponent implements OnInit {
  buildingForm: UntypedFormGroup;
  tempBuilding: Building;
  photoBlobUrl: string;

  buildingID = '';
  createBuildingMode = false;

  // Picture Feature
  photo: string | null = null;
  downloadUrls: string[] = [];

  // Google Places API
  // geolocation: string;
  suggestions: Array<any> = [];
  suggestedAddress = '';

  constructor(
    private fb: UntypedFormBuilder,
    private buildingService: BuildingService,
    private geolocationService: GeolocationService,
    private router: Router,
    private route: ActivatedRoute,
    private toastController: ToastController,
    private fileUploadService: FileUploadService
  ) {}

  ngOnInit() {
    this.initForm();
    this.route.paramMap.subscribe(async (params) => {
      if (params.get('buildingID') == null) {
        this.createBuildingMode = true;
        // Instantiate Temp Object
        this.tempBuilding = {
          id: '',
          name: '',
          pictureUrl: [],
          notes: '',
          active: true,
          address: {
            displayName: '',
            formattedAddress: '',
            id: '',
            longitude: '',
            latitude: '',
            googleMapsUri: '',
          },
        };
      } else {
        this.buildingID = params.get('buildingID');
        this.getBuilding();
      }
    });
  }

  // ======================= Google Places Feature ==========

  onInput(event: any): void {
    const query = event.target.value;
    if (query.length > 0) {
      this.fetchSuggestionsFromService(query);
    } else {
      this.suggestions = [];
    }
  }

  async fetchSuggestionsFromService(event: string) {
    this.suggestions = await this.geolocationService.fetchSuggestionsAutoComplete(event);
  }

  async selectSuggestion(suggestion: any): Promise<void> {
    this.suggestions = [];
    this.suggestedAddress = suggestion.Eg.di['0'][2][0];
    this.buildingForm.controls.address.setValue(this.suggestedAddress); // Update the form

    // TURN Suggestion Into Places
    const placeTemp = await this.geolocationService.convertToPlace(suggestion);

    // Store Other Address fields in Dealer Object
    this.tempBuilding.address.formattedAddress = placeTemp.place.formattedAddress;
    this.tempBuilding.address.displayName = placeTemp.place.displayName;
    this.tempBuilding.address.id = placeTemp.place.id;
    this.tempBuilding.address.longitude = placeTemp.place.location.lng();
    this.tempBuilding.address.latitude = placeTemp.place.location.lat();

    // Generate Google Maps URI using longitude and latitude
    // eslint-disable-next-line max-len
    this.tempBuilding.address.googleMapsUri = `https://www.google.com/maps/search/?api=1&query=${placeTemp.place.location.lat()},${placeTemp.place.location.lng()}`;
  }

  // ================================ Picture Feauture

  async takePicture() {
    const parts = this.router.url.split('/');
    parts.pop();
    const url = parts.join('/') + '/';
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
    });
    this.photo = image.dataUrl;
    const path = `${url.slice(1)}/buildingPicture/`;

    // Picture Feature
    if (image.dataUrl) {
      this.downloadUrls.push(await this.fileUploadService.uploadImage(path, image.dataUrl, `photo_${Date.now()}.jpeg`));
    }
  }

  removeImage(index: number): void {
    const downloadURL = this.downloadUrls[index];
    this.downloadUrls.splice(index, 1);
    this.fileUploadService.deletePic(downloadURL);
  }

  // ================================ Firebase Class Methods ================

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  async save(): Promise<void> {
    if (this.buildingForm.invalid) {
      console.log('Invalid Input for Creating Dealer User', this.buildingForm.invalid);
      this.presentErrorToast('top');
      return;
    }

    const formValue = this.buildingForm.value;
    this.tempBuilding.name = formValue.name;
    this.tempBuilding.pictureUrl = this.downloadUrls;
    this.tempBuilding.notes = formValue.notes;

    try {
      this.presentInitialToast('top');
      if (this.createBuildingMode) {
        this.buildingForm.reset();
        await this.buildingService.create(this.tempBuilding).then(async (result) => {
          await this.delay(250); // Wait for 500ms to appreciate the first toast
          this.presentLastToast('top'); // Calling Toast to show form was saved in backend without problems
          // Navidate to Next Page
          this.router.navigate(['../', result.id, 'door'], { relativeTo: this.route });
          this.tempBuilding.id = result.id;
          this.buildingService.setSelectedBuilding(this.tempBuilding);
        });
      } else {
        await this.buildingService.update(this.buildingID, this.tempBuilding).then(async (result) => {
          await this.delay(250); // Wait for 500ms to appreciate the first toast
          this.presentLastToast('top'); // Calling Toast to show form was saved in backend without problems
        });
      }
    } catch (error) {
      this.presentErrorToast('top');
      console.log('Error ....', error);
    }
  }

  async getBuilding() {
    this.tempBuilding = await this.buildingService.read(this.buildingID);
    this.buildingForm.setValue({
      name: this.tempBuilding.name,
      address: this.tempBuilding.address.formattedAddress,
      notes: this.tempBuilding.notes,
    });

    // building Picture Feature
    this.downloadUrls = this.tempBuilding.pictureUrl;
  }

  async deleteBuilding() {
    const formValue = this.buildingForm.value;
    this.tempBuilding.name = formValue.name;
    this.tempBuilding.pictureUrl = this.downloadUrls;
    this.tempBuilding.notes = formValue.notes;
    this.tempBuilding.active = false;
    this.tempBuilding.id = this.buildingService.getSelectedBuilding().id;

    await this.buildingService.update(this.tempBuilding.id, this.tempBuilding);
  }

  // ========================= Toast Form Enhancement Feature========

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async presentErrorToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'Building was not saved, try again or contact support team...',
      duration: 2500,
      position,
    });

    await toast.present();
  }

  /*
        @Description: Public Method
        @Comments:
        @Coders: jaam111000
      */
  async presentInitialToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'Building is saving...',
      duration: 1000,
      position,
    });

    await toast.present();
  }

  /*
        @Description: Public Method
        @Comments:
        @Coders: jaam111000
      */
  async presentLastToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'Building has been saved...',
      duration: 2500,
      position,
    });

    await toast.present();
  }

  // Helper method to create a delay
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // ================================== Private Class Methods
  private initForm() {
    this.buildingForm = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      notes: [''],
      // pictureUrl: [''],
    });
  }

  // this.geolocation = this.geolocationPositionToString(this.geoLocationObj);
  private geolocationPositionToString(position: GeolocationPosition): string {
    const { latitude, longitude, accuracy, altitude, altitudeAccuracy, heading, speed } = position.coords;

    return `Geolocation Position:
    Latitude: ${latitude}
    Longitude: ${longitude}
    Accuracy: ${accuracy !== null ? accuracy : 'N/A'} meters
    Altitude: ${altitude !== null ? altitude : 'N/A'}
    Altitude Accuracy: ${altitudeAccuracy !== null ? altitudeAccuracy : 'N/A'} meters
    Heading: ${heading !== null ? heading : 'N/A'}
    Speed: ${speed !== null ? speed : 'N/A'} meters/second
    Timestamp: ${new Date(position.timestamp).toLocaleString()}`;
  }
}
