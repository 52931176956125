import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NotFoundComponent } from 'src/app/pages/not-found/not-found.component';
import {
  Auth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
  signOut
} from '@angular/fire/auth';
import {
  Firestore
} from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  signupForm: UntypedFormGroup;
  email: string;

  constructor(
    fb: UntypedFormBuilder,
    public alertController: AlertController,
    public auth: Auth,
    private router: Router,
    private route: ActivatedRoute,
    private firestore: Firestore,
    private http: HttpClient
  ) {
    this.signupForm = fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      const inviteId = params.get('inviteId');
      const functionUrl =  `${environment.baseApiUrl}${environment.invite}${inviteId}`;
     //const functionUrl =  `http://127.0.0.1:5001/smart-door-334013/us-central1/invite/${inviteId}`; Test on Emulator
      this.http.get(functionUrl)
      .pipe(
        map(response =>response),
        catchError(error => {
          console.error('Error:', error);
          this.router.navigate(['/404']);
          return of(null); // or handle error differently
        })
      )
      .subscribe({
        next: (data: any) => {
          // Handle the successful response data here
          if(!data.isValid){
            this.router.navigate(['/404']);
          }
          else{
            this.email=data.email;
          }
        },
        error: (error) => {
          // Handle the error here
          console.error('Error fetching data:', error);
        },
        complete: () => {
          // Handle completion (optional)
        }
      });
    });

  }


  async onSignup() {
    console.log(this.signupForm);


    if (!this.signupForm.valid) {
      const alert = await this.alertController.create({
        message: 'Fill the form',
        buttons: ['OK'],
      });

      await alert.present();
    }

    try {
      const { user } = await createUserWithEmailAndPassword(
        this.auth,
        this.email,
        this.signupForm.value.password
      );

      updateProfile(user, {
        displayName: this.signupForm.value.name,
      });

      //sendEmailVerification(user);
      signOut(this.auth).then(() => {
        // Sign-out successful.
        this.router.navigateByUrl('/login');
      }).catch((error) => {
        // An error happened.
        console.log('ERROR in signing out',error);
      });
    } catch (error) {
      console.log(error);
      const alert = await this.alertController.create({
        message: error.message,
        buttons: ['OK'],
      });

      await alert.present();
    }
  }

  async onGoogleLogin() {
    const provider = new GoogleAuthProvider();
    try {
      // Attempt to sign in with Google
      const result = await signInWithPopup(this.auth, provider);
      const user = result.user;
      console.log('The user email is',result.user.email);
      this.router.navigateByUrl('/home');
    } catch (error) {
      console.error('Authentication failed: ', error);
    }
  }
}
