import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Injectable, inject } from '@angular/core';
import { Auth, authState, getAuth } from '@angular/fire/auth';
import { Router, ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DealerService } from './dealer.service';

// User Modules
import { SecurityService } from './security.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private securityService: SecurityService,
    private router: Router,
    private auth: Auth,
    private userService: UserService,
    private dealerService: DealerService
  ) {}

  canActivateDefaultRoutes(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    // Subscribe to the authentication state observable defined in security service
    return authState(this.auth).pipe(
      take(1), // Ensure observable completes after receiving the first value.
      map((authenticatedUser) => {
        if (authenticatedUser) {
          // User is signed in, redirect to home
          return this.router.createUrlTree(['/home']);
        } else {
          return true;
        }
      })
    );
  }

  canActivateHomeRoute(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return authState(this.auth).pipe(
      take(1), // Ensures that the observable completes after receiving the first value.
      map((user) => {
        if (user) {
          this.router.navigate(['/dealer']);
          return true;
        } else {
          console.log('User is not signed in...');
          this.router.navigate(['/login']);
          return false;
        }
      })
    );
  }

  /*
    @Description: Public Method to implement logic to see if user can navigate to such route
    @Comments:
    @Coders: jaam111000
  */
  canActivateAdminRoutes(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log('Executing_Admin_GUARD', next);
    if (!this.securityService.isUserSignedIn()) {
      this.router.navigate(['/login']);
      return false;
    }
    if (this.userService.isUserSmartDoorAdmin()) {
      console.log('admin in auth guard');
      return true;
    }
    this.router.navigate(['/home']);
    return false;
  }

  // =================================== Dealer Auth Guards Logic ==============

  canActivateStickerCreateRoutes(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log('Executing_DealerRead_GUARD', next);
    if (this.securityService.isUserSignedIn()) {
      return true;
    }
    this.router.navigate(['/home']);
    return false;
  }

  /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
    */
  canActivateDealerReadRoutes(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log('Executing_DealerRead_GUARD', next);
    if (this.securityService.isUserSignedIn()) {
      // this.router.navigate(['/dealer']);
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }

  // ====================================== User Auth Guard Logic ==============

  /*
      @Description: Public Method to implement logic to see if user can navigate to such route
      @Comments: next.data has the route information
      @Coders: jaam111000
    */
  canActivateUserCreateRoutes(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log('Executing_USER_Create_GUARD', next);
    return true;
  }

  /*
      @Description: Public Method to implement logic to see if user can navigate to such route
      @Comments: next.data has the route information
      @Coders: jaam111000
    */
  canActivateUserReadRoutes(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log('Executing_USER_READ_GUARD', next);
    // Route Check
    // if (this.securityService.canReadUsers()) {
    //   return true;
    // }
    return true;
  }

  /*
      @Description: Public Method to implement logic to see if user can navigate to such route
      @Comments: next.data has the route information
      @Coders: jaam111000
    */
  canActivateUserUpdateRoutes(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log('Executing_USER_UPDATE_GUARD', next);
    // Route Check
    // if (this.securityService.canUpdateUsers()) {
    //   return true;
    // }
    return true;
  }

  // ====================================== Customer Auth Guard Logic ==============

  /*
      @Description: Public Method to implement logic to see if user can navigate to such route
      @Comments: next.data has the route information
      @Coders: jaam111000
    */
  canActivateCustomerCreateRoutes(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log('Executing_Customer_GUARD1', next);
    // Redirect to an unauthorized page or back home
    // this.router.navigate(['/home']);
    return true;
  }

  /*
      @Description: Public Method to implement logic to see if user can navigate to such route
      @Comments: next.data has the route information
      @Coders: jaam111000
    */
  canActivateCustomerReadRoutes(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return true;
  }

  // ====================================== Building Auth Guard Logic ==============

  /*
      @Description: Public Method to implement logic to see if user can navigate to such route
      @Comments: next.data has the route information
      @Coders: jaam111000
    */
  canActivateBuildingRoutes(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log('Executing AUTH_BUILDING_GUARD', next);
    // console.log('Executing AUTH_BUILDING_GUARD1', state);

    // // Check for Route based on permissions
    // if (next.url[0].path === 'building') {
    //   if (this.securityService.canReadBuildings(userRole.userPolicy.buildingPolicies)) {
    //     return true;
    //   }
    // } else if (next.url[0].path === 'building-add') {
    //   if (this.securityService.canCreateBuildings(userRole.userPolicy.buildingPolicies)) {
    //     return true;
    //   }
    // }

    // Redirect to an unauthorized page or back home
    // this.router.navigate(['/home']);
    return true;
  }

  /*
      @Description: Public Method to implement logic to see if user can navigate to such route
      @Comments: next.data has the route information
      @Coders: jaam111000
    */
  canActivateDoorRoutes(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Basic Auth Check

    // // Check for Route based on permissions
    // if (next.url[0].path === 'door') {
    //   if (this.securityService.canReadDoors(userRole.userPolicy.doorPolicies)) {
    //     return true;
    //   }
    // } else if (next.url[0].path === 'door-add') {
    //   if (this.securityService.canCreateDoors(userRole.userPolicy.doorPolicies)) {
    //     return true;
    //   }
    // }

    // Redirect to an unauthorized page or back home
    // this.router.navigate(['/home']);
    return true;
  }

  /*
      @Description: Public Method to implement logic to see if user can navigate to such route
      @Comments: next.data has the route information
      @Coders: jaam111000
    */
  canActivateInspectionRoutes(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return true;
  }
}

// =================================== Auth Guards ====================================
export const MAIN_AUTH_GUARD: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(AuthGuardService).canActivateDefaultRoutes(next, state);

export const AUTH_ADMIN_GUARD: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(AuthGuardService).canActivateAdminRoutes(next, state);

export const AUTH_STICKER_GUARD: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(AuthGuardService).canActivateStickerCreateRoutes(next, state);

// -------------------- Home & Dealer Auth Guards ---------------

export const AUTH_HOME_GUARD: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(AuthGuardService).canActivateHomeRoute(next, state);

export const AUTH_DEALER_READ_GUARD: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(AuthGuardService).canActivateDealerReadRoutes(next, state);

// -------------------- User Auth Guards ---------------

export const AUTH_USER_CREATE_GUARD: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(AuthGuardService).canActivateUserCreateRoutes(next, state);

export const AUTH_USER_READ_GUARD: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(AuthGuardService).canActivateUserReadRoutes(next, state);

export const AUTH_USER_UPDATE_GUARD: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(AuthGuardService).canActivateUserUpdateRoutes(next, state);

// -------------------- Customer Auth Guards ---------------

export const AUTH_CUSTOMER_CREATE_GUARD: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(AuthGuardService).canActivateCustomerCreateRoutes(next, state);

export const AUTH_CUSTOMER_READ_GUARD: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(AuthGuardService).canActivateCustomerReadRoutes(next, state);

// -------------------- Building Auth Guards ---------------

export const AUTH_BUILDING_GUARD: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(AuthGuardService).canActivateBuildingRoutes(next, state);

// -------------------- Door Auth Guards ---------------

export const AUTH_DOOR_GUARD: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(AuthGuardService).canActivateDoorRoutes(next, state);

// -------------------- Inspection Auth Guards ---------------

export const AUTH_INSPECTION_GUARD: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(AuthGuardService).canActivateInspectionRoutes(next, state);
