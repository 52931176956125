import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonAccordionGroup } from '@ionic/angular';
import { Building } from 'src/app/model/Building';
import { BuildingService } from 'src/app/service/building.service';
import { CustomerService } from 'src/app/service/customer.service';
import { DealerService } from 'src/app/service/dealer.service';

@Component({
  selector: 'app-building-details',
  templateUrl: './building-details.component.html',
  styleUrls: ['./building-details.component.scss'],
})
export class BuildingDetailsComponent implements OnInit {
  @ViewChild('accordionGroup', { static: true }) accordionGroup: IonAccordionGroup;
  buildingDetailsIsExpanded = false;
  tempBuilding: Building;

  constructor(
    private buildingService: BuildingService,
    private customerService: CustomerService,
    private dealerService: DealerService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // this.tempBuilding = this.buildingService.getSelectedBuilding();

    // Cached Object Observable
    this.buildingService.buildingTemp$.subscribe((building) => {
      // console.log('Your building in details page...', building);
      this.tempBuilding = building;
    });
  }

  toggleBuildingDetails() {
    this.buildingDetailsIsExpanded = !this.buildingDetailsIsExpanded;
  }

  getMyCustomerService() {
    return this.customerService;
  }

  getMyDealerService() {
    return this.dealerService;
  }

  getMyBuildingService() {
    return this.buildingService;
  }

  async navigate(path: string[]) {
    await this.router.navigate(path, { relativeTo: this.route });
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  handleDoorLength(event: number) {
    if (event === 0) {
      this.toggleAccordion();
    }
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  toggleAccordion = () => {
    const nativeEl = this.accordionGroup;
    if (nativeEl.value === 'first') {
      nativeEl.value = undefined;
    } else {
      nativeEl.value = 'first';
    }
  };
}
