// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
  display: flex;
  justify-content: space-between;
}

.button-container ion-button {
  flex: 1;
  margin-right: 10px;
}

.rightPaddedTitle {
  padding-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/dealer-ae/dealer-ae.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;AACJ;;AAEA;EACI,OAAA;EACA,kBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ","sourcesContent":[".button-container {\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n  \r\n.button-container ion-button {\r\n    flex: 1;\r\n    margin-right: 10px;\r\n}\r\n\r\n.rightPaddedTitle{\r\n    padding-right: 15px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
