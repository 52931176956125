import { Component, Input } from '@angular/core';
import { ModalController,PopoverController } from '@ionic/angular';
import { InspectionImage } from 'src/app/model/SafetyInspection';
import { ImageViewerComponent } from 'src/app/pages/image-viewer/image-viewer.component';

@Component({
  selector: 'app-picture-modal',
  templateUrl: './picture-modal.component.html',
  styleUrls: ['./picture-modal.component.scss'],
})
export class PictureModalComponent {
  @Input() imageUrls: InspectionImage[]; // Array of image URLs
  constructor(private modalController: ModalController, private popOverController: PopoverController) {}

  // Method to open this modal
  async openModal() {
    const modal = await this.modalController.create({
      component: PictureModalComponent,
      componentProps: { imageUrls: this.imageUrls}
    });
    return await modal.present();
  }

  dismissModal() {
    this.modalController.dismiss();
  }
  async openImage(imageUrl: string) {
    const popOver = await this.popOverController.create({
      component: ImageViewerComponent,
      componentProps: {
        imageUrl
      }
    });
    return await popOver.present();
  }
}
