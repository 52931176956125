// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-title {
  padding: 0 0px; /* Adjust spacing around the title */
  font-weight: bold; /* Make the title bold */
  text-align: center;
  flex-grow: 1; /* Allow the title to take up available space */
}

ion-toolbar {
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between; /* Ensures that elements are spaced out evenly */
  align-items: center; /* Vertically center the content */
}

ion-item {
  min-width: 150px;
  max-height: 45px;
  /* --border: none; Removes any default borders */
  --background: transparent; /* Ensures no background is applied to the item */
}

ion-select {
  --border-radius: 10px; /* Rounded corners */
  /* --background: white; Background color */
  /* --placeholder-color: gray; Placeholder color when no option is selected */
  padding-left: 10px;
}

.menu-icon {
  width: 34px;
  height: 24px;
}

@media (max-width: 600px) {
  ion-title {
    font-size: 16px; /* Adjust font size for smaller screens */
  }
  .menu-icon {
    width: 34px;
    height: 24px;
  }
  ion-toolbar {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/app-tool-bar/app-tool-bar.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA,EAAA,oCAAA;EACA,iBAAA,EAAA,wBAAA;EACA,kBAAA;EACA,YAAA,EAAA,+CAAA;AACF;;AAEA;EAEE,mBAAA;EACA,aAAA;EACA,8BAAA,EAAA,gDAAA;EACA,mBAAA,EAAA,kCAAA;AAAF;;AAGA;EACE,gBAAA;EACA,gBAAA;EACA,gDAAA;EACA,yBAAA,EAAA,iDAAA;AAAF;;AAGA;EACE,qBAAA,EAAA,oBAAA;EACA,0CAAA;EACA,4EAAA;EACA,kBAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;AAAF;;AAGA;EACE;IACE,eAAA,EAAA,yCAAA;EAAF;EAGA;IACE,WAAA;IACA,YAAA;EADF;EAIA;IACE,gBAAA;IACA,mBAAA;EAFF;AACF","sourcesContent":["ion-title {\r\n  padding: 0 0px; /* Adjust spacing around the title */\r\n  font-weight: bold; /* Make the title bold */\r\n  text-align: center;\r\n  flex-grow: 1; /* Allow the title to take up available space */\r\n}\r\n\r\nion-toolbar {\r\n  // padding-top: 10px;\r\n  padding-bottom: 5px;\r\n  display: flex;\r\n  justify-content: space-between; /* Ensures that elements are spaced out evenly */\r\n  align-items: center; /* Vertically center the content */\r\n}\r\n\r\nion-item {\r\n  min-width: 150px;\r\n  max-height: 45px;\r\n  /* --border: none; Removes any default borders */\r\n  --background: transparent; /* Ensures no background is applied to the item */\r\n}\r\n\r\nion-select {\r\n  --border-radius: 10px; /* Rounded corners */\r\n  /* --background: white; Background color */\r\n  /* --placeholder-color: gray; Placeholder color when no option is selected */\r\n  padding-left: 10px;\r\n}\r\n\r\n.menu-icon {\r\n  width: 34px;\r\n  height: 24px;\r\n}\r\n\r\n@media (max-width: 600px) {\r\n  ion-title {\r\n    font-size: 16px; /* Adjust font size for smaller screens */\r\n  }\r\n\r\n  .menu-icon {\r\n    width: 34px;\r\n    height: 24px;\r\n  }\r\n\r\n  ion-toolbar {\r\n    padding-top: 0px;\r\n    padding-bottom: 0px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
