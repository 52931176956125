import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Firestore, doc, getDoc, updateDoc } from '@angular/fire/firestore';
import { Auth, getAuth } from '@angular/fire/auth';
import { CustomerUserPolicy, DealerUserPolicy } from '../model/UserPrivacy';

// User Imports
import { User, DealerUser, CustomerUser } from '../model/User';
import { Observable, fromEventPattern } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private authenticatedUser$: Observable<any>;
  private userCollectionObj: User = null;
  private dealerUserCollectionObj: DealerUser = null;

  private dealerUserPolicy: DealerUserPolicy = null;
  private customerUserPolicy: CustomerUserPolicy = null;

  private isAdmin: boolean;

  private readonly collectionName = 'users';

  // Constructor of Class
  constructor(private auth: Auth, private firestore: Firestore) {
    // Set up an observable user$ that listens to authentication state changes using fromEventPattern
    this.authenticatedUser$ = fromEventPattern(
      (handler) => this.auth.onAuthStateChanged(handler),
      (handler, unsubscribe) => unsubscribe()
    );
  }

  // ============================== Class Related Methods =================

  /*
    @Description: Public Method to check if the user is an admin
    @Comments: Do it once, and store into the User Object
    @Coders: rohan-16
  */
  async getSDAdmin() {
    await getAuth()
      .currentUser.getIdTokenResult(true) // Force refresh to get latest claims
      .then((idTokenResult) => {
        const claims = idTokenResult.claims;
        if (claims.sdAdmin) {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }
      })
      .catch((error) => {
        console.error('Error fetching claims:', error);
      });
    // this.isAdmin = true;
    // return true;
  }
  /*
      @Description: Public Method
      @Comments:
      @Coders: jaam111000
    */
  isUserSmartDoorAdmin(): boolean {
    return this.isAdmin;
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  getMyAuthObject(): Auth {
    return this.auth;
  }

  /*
      @Description: Public Method to get User Object
      @Comments:
      @Coders: jaam111000
    */
  public getUserCollectionObj(): User {
    return this.userCollectionObj;
  }

  /*
    @Description: Public Method to get User Object
    @Comments:
    @Coders: jaam111000
  */
  public setUserCollectionObj(tempUser: User): void {
    this.userCollectionObj = tempUser;
  }

  /*
    @Description: Public Method to get DealerUser Object
    @Comments:
    @Coders: Rohan-16
  */
  public getDealerUserCollectionObj(): DealerUser {
    return this.dealerUserCollectionObj;
  }

  // =============================== Firebase-Related Function ============

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  public setUserDealerPolicy(du: DealerUserPolicy): void {
    this.dealerUserPolicy = du;
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  public getUserDealerPolicy(): DealerUserPolicy {
    return this.dealerUserPolicy;
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  public getUserCustomerPolicy(): CustomerUserPolicy {
    return this.customerUserPolicy;
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  public setUserCustomerPolicy(cu: CustomerUserPolicy): void {
    this.customerUserPolicy = cu;
  }

  /*
    @Description: Public Method to get the User Object from User's Main Collection
    @Comments: FirebaseError: No matching allow statements (no route) or FirebaseError: false for 'get' @ L7 (no rights)
    @Coders:
  */
  async getUserFromCollection(userId: string): Promise<User> {
    try {
      const userDocRef = doc(this.firestore, this.collectionName, userId);
      const userDoc = await getDoc(userDocRef);
      return userDoc.data() as User;
    } catch (error) {
      console.log('Error while reading from users root collection...', error);
    }
  }

  /*
    @Description: Public Method to
    @Comments:
    @Coders:
  */
  async updateUser(user: Partial<User>): Promise<void> {
    const userDocRef = doc(this.firestore, this.collectionName, user.email);
    return updateDoc(userDocRef, user);
  }
}
