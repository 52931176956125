// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
  display: flex;
  justify-content: space-between;
}

.button-container ion-button {
  flex: 1;
  margin-right: 10px;
}

.imageView {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.displayCenterFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.deletePictureButton {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/building-ae/building-ae.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;AACJ;;AAEA;EACI,OAAA;EACA,kBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ","sourcesContent":[".button-container {\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n  \r\n.button-container ion-button {\r\n    flex: 1;\r\n    margin-right: 10px;\r\n}\r\n\r\n.imageView{\r\n    width: 100%;\r\n    height: auto;\r\n    object-fit: cover;\r\n}\r\n\r\n.displayCenterFlex{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.deletePictureButton{\r\n    margin-top: 10px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n// .suggestions-list {\r\n//     position: absolute;\r\n//     top: 100%;\r\n//     left: 0;\r\n//     right: 0;\r\n//     background-color: white;\r\n//     border: 1px solid #ccc;\r\n//     border-top: none;\r\n//     max-height: 200px;\r\n//     overflow-y: auto;\r\n//     z-index: 1000; /* Ensure it stays on top */\r\n//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n//   }\r\n  \r\n//   .suggestions-list ion-item {\r\n//     --padding-start: 16px;\r\n//     --inner-padding-end: 16px;\r\n//     --inner-padding-top: 8px;\r\n//     --inner-padding-bottom: 8px;\r\n//   }\r\n  \r\n//   .suggestions-list ion-item ion-label {\r\n//     display: block;\r\n//   }\r\n  \r\n//   .suggestions-list ion-item:hover {\r\n//     background-color: #f0f0f0;\r\n//   }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
