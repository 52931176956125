import { Component, OnInit } from '@angular/core';
import { OverHeadDoor } from 'src/app/model/OverHeadDoor';
import { OhdoorService } from 'src/app/service/ohdoor.service';
import { InspectionService } from 'src/app/service/inspection.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'src/app/service/customer.service';
import { BuildingService } from 'src/app/service/building.service';
import { SafetyInspection } from 'src/app/model/SafetyInspection';

@Component({
  selector: 'app-door-details',
  templateUrl: './door-details.component.html',
  styleUrls: ['./door-details.component.scss'],
})
export class DoorDetailsComponent implements OnInit {
  overheadDoor: OverHeadDoor = null;
  dealerID = '';
  customerID = '';
  buildingID = '';
  doorID = '';
  index = 0;
  loadingInspections = true;
  tempDoor: OverHeadDoor;
  tempInspection: SafetyInspection;

  constructor(
    private ispcService: InspectionService,
    private ohdService: OhdoorService,
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private buildingService: BuildingService
  ) {}

  ngOnInit() {

    const params1 = this.route.snapshot.params;
    if (this.ohdService.getSelectedDoor() === null) {
      console.log('You have scanned a door sticker');
      this.dealerID = params1.dealerID;
      this.customerID = params1.customerID;
      this.buildingID = params1.buildingID;
      this.doorID = params1.doorID;
      this.ohdService
        .getDoorAfterQRScan(this.dealerID, this.customerID, this.buildingID, this.doorID)
        .then(async (door) => {
          this.overheadDoor = door;
          this.loadInspections(true);
          const tempCustomer = await this.customerService.getCustomer(this.dealerID, this.customerID);
          this.customerService.setSelectedCustomer(tempCustomer);

          const tempBuilding = await this.buildingService.read(this.buildingID);
          tempBuilding.id = this.buildingID;
          this.buildingService.setSelectedBuilding(tempBuilding);
          this.loadingInspections= false;
          door.id = this.doorID;
          this.ohdService.setSelectedDoor(door);
        });
    } else {
      //this.overheadDoor = this.ohdService.getSelectedDoor();
      this.ohdService.doorTemp$.subscribe((door) => {
        this.tempDoor = door;
      });
      this.loadInspections(false);

    }

    // Cached Object Observable
    this.ohdService.doorTemp$.subscribe((door) => {
      // console.log('Your door in details page...', door);
      this.overheadDoor = door;
    });
  }

  async loadInspections(isQRscan: boolean) {
    if (isQRscan) {
      this.overheadDoor.safetyInspections = await this.ispcService.getAllinspectionsAfterScan(
        this.dealerID,
        this.customerID,
        this.buildingID,
        this.doorID
      );
    } else {
      this.tempDoor.safetyInspections = await this.ispcService.getAllInspections();
      this.loadingInspections= false;
    }
  }

  async createInspection() {
    const inspectionCreated = await this.ispcService.createSafetyInspection();
    // console.log(inspectionCreated);
    this.ispcService.setSelectedInspection(inspectionCreated);
    this.router.navigate(['../inspection', inspectionCreated.id, 'view'], { relativeTo: this.route });
  }

  setInspection(inspection) {
    this.ispcService.setSelectedInspection(inspection);
  }

  async navigate(path: string[]) {
    await this.router.navigate(path, { relativeTo: this.route });
  }

  getMyOhDoorService(){
    return this.ohdService;
  }
}
