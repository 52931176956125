// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
  display: flex;
  justify-content: space-between;
}

.button-container ion-button {
  flex: 1;
}

.dataModelCategory {
  padding-right: 5px;
  max-width: 115px;
}

.dataModelCategoryTitle {
  font-weight: bolder;
  font-size: large;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/customer-users-ae/customer-users-ae.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,OAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;AACF;;AACA;EACE,mBAAA;EACA,gBAAA;EACA,0BAAA;AAEF","sourcesContent":[".button-container {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.button-container ion-button {\r\n  flex: 1;\r\n}\r\n\r\n.dataModelCategory{\r\n  padding-right: 5px;\r\n  max-width: 115px;\r\n}\r\n.dataModelCategoryTitle{\r\n  font-weight: bolder;\r\n  font-size: large;\r\n  text-decoration: underline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
