// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  flex-wrap: wrap; /* Ensures buttons wrap to the next line */
}

.details-container {
  flex: 1;
  width: 100%;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .button-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
  .button-container ion-button {
    width: 100%;
    text-align: center;
  }
}
.displayFlex {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title-label {
  flex: 1;
  font-size: 1.42rem;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/door-details/door-details.component.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,uBAAA;EACA,SAAA;EACA,aAAA;EACA,eAAA,EAAA,0CAAA;AAAJ;;AAGE;EACE,OAAA;EACA,WAAA;AAAJ;;AAGE,mCAAA;AACA;EACE;IACE,aAAA;IACA,qCAAA;IACA,SAAA;EAAJ;EAGE;IACE,WAAA;IACA,kBAAA;EADJ;AACF;AAIE;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AAFJ;;AAKE;EACE,OAAA;EACA,kBAAA;EACA,iBAAA;AAFJ","sourcesContent":["// Button Group CSS\r\n.button-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    gap: 20px; \r\n    padding: 10px; \r\n    flex-wrap: wrap; /* Ensures buttons wrap to the next line */\r\n  }\r\n  \r\n  .details-container {\r\n    flex: 1;\r\n    width: 100%;\r\n  }\r\n    \r\n  /* Media Query for Mobile Devices */\r\n  @media (max-width: 768px) {\r\n    .button-container {\r\n      display: grid;\r\n      grid-template-columns: repeat(1, 1fr); \r\n      gap: 10px; \r\n    }\r\n  \r\n    .button-container ion-button {\r\n      width: 100%; \r\n      text-align: center; \r\n    }\r\n  }\r\n\r\n  .displayFlex {\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 100%;\r\n  }\r\n\r\n  .title-label {\r\n    flex: 1;\r\n    font-size: 1.42rem;\r\n    font-weight: bold;\r\n  }\r\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
