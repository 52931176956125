import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  CollectionReference,
  DocumentData,
  Firestore,
  addDoc,
  collection,
  doc,
  getDoc,
  updateDoc,
} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class BarcodeService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static readonly DOOR_STICKERS_COLLECTION = 'door-stickers';

  // Class Members
  stickerIDsAR: string[] = [];
  selectedStickerDoorID: string;

  constructor(private readonly firestore: Firestore, private router: Router) {}

  // Method to Generate Stcikers from admin-sticker page
  async generateStickers(numberOfStickers: number) {
    const collectionRef: CollectionReference<DocumentData> = collection(
      this.firestore,
      BarcodeService.DOOR_STICKERS_COLLECTION
    );

    for (let i = 0; i < numberOfStickers; i++) {
      try {
        const docRef = await addDoc(collectionRef, { isInUse: false });
        console.log(`Generated sticker with ID: ${docRef.id}`);
        this.stickerIDsAR.push(docRef.id);
      } catch (error) {
        console.error(`Error generating sticker:`, error);
      }
    }
    console.log('Your StickerIDs are:', this.stickerIDsAR);
  }

  // Read QR Code & Retrieve DocumentID from Cloud
  async processQRcode(documentID: string) {
    try {
      const docRef = doc(this.firestore, 'door-stickers', documentID);
      const docSnap = (await getDoc(docRef)).data();
      docSnap.ID = documentID;
      this.processStickerDocumentFromFirebase(docSnap);
    } catch (error) {
      console.log('Failed while processing QRcode...', error);
    }
  }

  // Process fields of document to route accordingly
  processStickerDocumentFromFirebase(dataDoc: DocumentData) {
    if (dataDoc.isInUse) {
      const route2Navigate =
        '/dealer/' +
        dataDoc.dealerID +
        '/customer/' +
        dataDoc.customerID +
        '/building/' +
        dataDoc.buildingID +
        '/door/' +
        dataDoc.doorID +
        '/view';
      this.router.navigateByUrl(route2Navigate);
    } else {
      const routeToNavigate = './door-sticker/' + dataDoc.ID;
      this.router.navigate([routeToNavigate]);
    }
  }

  storeCompleteQRDocInFirebase(obj2Store: any) {
    // console.log('this is your door stickerID...', this.selectedStickerDoorID);
    // console.log('this is your object to store...', obj2Store);
    obj2Store.isInUse = true;

    try {
      const docRef = doc(this.firestore, BarcodeService.DOOR_STICKERS_COLLECTION, this.selectedStickerDoorID);
      return updateDoc(docRef, obj2Store);
    } catch (error) {
      console.log('Error occurred while storing doc in Door Stickjer...', error);
    }
  }

  setSelectedDoorStickerID(doorStickerID: string) {
    this.selectedStickerDoorID = doorStickerID;
  }
  getSelectedDoorStickerID(): string {
    return this.selectedStickerDoorID;
  }
}
