import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpenAIService {
  private apiUrl = 'https://api.openai.com/v1/chat/completions';

  constructor(private http: HttpClient) {}

  async getResponse(message: string): Promise<string> {
    console.log('OpenAIService: Sending request to OpenAI');
    const headers = {
      'Authorization': `Bearer ${environment.openAIKey}`,
      'Content-Type': 'application/json'
    };
    const body = {
      model: "gpt-3.5-turbo",
      messages: [{"role": "user", "content": message}]
    };

    try {
      console.log('OpenAIService: Request body:', body);
      const response: any = await firstValueFrom(this.http.post(this.apiUrl, body, { headers }));
      console.log('OpenAIService: Received response:', response);
      return response.choices[0].message.content;
    } catch (error) {
      console.error('Error calling OpenAI API:', error);
      if (error instanceof HttpErrorResponse) {
        console.error('Status:', error.status);
        console.error('Error body:', error.error);
      }
      return 'I apologize, but I encountered an error. Could you please try again?';
    }
  }
}