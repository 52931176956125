// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ion-card-view {
  background-color: #f1f1f1;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ion-card-view:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ion-card-header-view {
  background-color: rgba(0, 123, 255, 0.168627451);
  border-bottom: 1px solid #e0e0e0;
  padding: 16px;
}

.ion-card-title-view {
  font-size: 1.2rem;
  font-weight: lighter;
}

.ion-card-content-view {
  padding: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/building-list/building-list.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,2CAAA;EACA,eAAA;EACA,wCAAA;AACJ;;AAEA;EACI,2BAAA;EACA,wCAAA;AACJ;;AAEA;EACI,gDAAA;EACA,gCAAA;EACA,aAAA;AACJ;;AAEA;EACI,iBAAA;EACA,oBAAA;AACJ;;AAEA;EACI,aAAA;AACJ","sourcesContent":[".ion-card-view {\r\n    background-color: #f1f1f1;\r\n    transition: transform 0.2s, box-shadow 0.2s;\r\n    cursor: pointer;\r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.ion-card-view:hover {\r\n    transform: translateY(-3px);\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\r\n}\r\n\r\n.ion-card-header-view {\r\n    background-color: #007bff2b;\r\n    border-bottom: 1px solid #e0e0e0; \r\n    padding: 16px; \r\n}\r\n\r\n.ion-card-title-view {\r\n    font-size: 1.2rem; \r\n    font-weight: lighter;\r\n}\r\n\r\n.ion-card-content-view {\r\n    padding: 16px; \r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
