// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Example CSS adjustments */
.safetyMenu {
  --min-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/app-side-bar/app-side-bar.component.scss"],"names":[],"mappings":"AAAA,4BAAA;AAiBE;EACE,iBAAA;AAfJ","sourcesContent":["/* Example CSS adjustments */\r\n// .button-normal {\r\n//     --background: white;\r\n//   }\r\n  \r\n//   .button-passed {\r\n//     --background: rgb(32, 181, 32);\r\n//   }\r\n  \r\n//   .button-failed {\r\n//     --background: rgb(242, 61, 61);\r\n//   }\r\n  \r\n//   .button-nonApplicable {\r\n//     --background: gray;\r\n//   }\r\n\r\n  .safetyMenu{\r\n    --min-width: 100%;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
