import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InspectionService } from 'src/app/service/inspection.service';
import { LookupService } from 'src/app/service/lookup.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute, private lookupService: LookupService) {}

  ngOnInit() {}

  getLookUpService(): LookupService {
    return this.lookupService;
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        try {
          const jsonContent = JSON.parse(e.target.result);
          this.lookupService.uploadLookupToFirestore(jsonContent);
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      };
      reader.readAsText(file);
    }
  }

  onFileSelectedChecklist(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        try {
          const jsonContent = JSON.parse(e.target.result);
          this.lookupService.uploadChecklistToFirestore(jsonContent);
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      };
      reader.readAsText(file);
    }
  }

  navigateToDealerRoute() {
    this.router.navigate(['../dealer'], {
      queryParams: {
        adminRoute: true,
      },
      relativeTo: this.route,
    });
  }

  navigateToDealerCreateRoute() {
    this.router.navigate(['../dealer/add'], {
      queryParams: {
        adminCreateRoute: true,
      },
      relativeTo: this.route,
    });
  }

  navigateToCustomerRoute() {
    this.router.navigate(['../customer/dealer'], {
      queryParams: {
        adminRoute: false,
      },
      relativeTo: this.route,
    });
  }

  navigateToBuildingRoute() {
    this.router.navigate(['../building/dealer'], {
      queryParams: {
        adminRoute: true,
      },
      relativeTo: this.route,
    });
  }

  navigateToDoorRoute() {
    this.router.navigate(['../door/dealer'], {
      queryParams: {
        adminRoute: true,
      },
      relativeTo: this.route,
    });
  }
}
