import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Geolocation, PositionOptions } from '@capacitor/geolocation';

// Google Maps API Feature
declare const google: any;

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  geoLocationObj: any;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  placeLibrary: any;

  constructor() {
    if (typeof google !== 'undefined' && google.maps) {
      // console.log('Google Maps API loaded');
    } else {
      console.error('Google Maps API not loaded');
    }

    try {
      this.checkPermissions().then(async (result) => {
        if (result) {
          this.getCurrentPosition().then((position) => {
            // console.log('Your geoposition is...',position);
            this.geoLocationObj = position;
          });
        } else {
          this.geoLocationObj = {
            coords: {
              latitude: 47.56872,
              longitude: -52.73212,
            },
          };
        }
        this.placeLibrary = await google.maps.importLibrary('places');
      });
    } catch (error) {
      console.log('Your error is...', error);
      this.geoLocationObj = {
        coords: {
          latitude: 47.56872,
          longitude: -52.73212,
        },
      };
    }
  }
  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  async getCurrentPosition(): Promise<GeolocationPosition> {
    const options: PositionOptions = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    const result = await Geolocation.getCurrentPosition(options);
    return result;
  }

  /*
    @Description: Function to handle permissions
    @Comments:
    @Coders: jaam111000
  */
  async checkPermissions(): Promise<boolean> {
    if (Capacitor.isNativePlatform()) {
      const result = await Geolocation.checkPermissions();

      if (result.location === 'granted') {
        return true;
      } else if (result.location === 'denied') {
        return false;
      } else {
        // console.log('Requesting permissions...');
        const request = await Geolocation.requestPermissions();
        // console.log('Your request is...', request);

        if (request.location === 'granted') {
          return true;
        } else {
          return false;
        }
      }
    } else {
      // We are on browser, permissions are handled by browser
      try {
        const position = await Geolocation.getCurrentPosition();
        // console.log('Position acquired:', position);
        return true;
      } catch (error) {
        console.error('Error getting position:', error);
        return false;
      }
    }
  }

  /*
    @Description: Function to handle permissions
    @Comments:
    @Coders: jaam111000
  */
  async fetchSuggestions(event: string): Promise<any[]> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { Place } = this.placeLibrary; // Variable Name has to be Place for API Call to work
    const latitude = this.geoLocationObj.coords.latitude;
    const longitude = this.geoLocationObj.coords.longitude;

    const request = {
      textQuery: event,
      fields: ['displayName', 'formattedAddress', 'id', 'location'],
      locationBias: { lat: latitude, lng: longitude },
      region: 'ca',
      language: 'en-US',
      maxResultCount: 8,
      // includedType: ['point_of_interest', 'establishment'],
      // isOpenNow: true,
      // minRating: 3.2,
      // useStrictTypeFiltering: false,
    };

    try {
      const { places } = await Place.searchByText(request);
      const tempSuggestions = places.map((place: any) => ({
        displayName: place.displayName,
        formattedAddress: place.formattedAddress,
        longitude: place.location.lng().toString(),
        latitude: place.location.lat().toString(),
        id: place.id,
        // addressComponents: place.addressComponents,
      }));
      return tempSuggestions;
      // console.log('Your places are...', places);
    } catch (error) {
      console.error('Error fetching places', error);
    }
  }

  async fetchSuggestionsAutoComplete(event: string): Promise<any[]> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { AutocompleteSessionToken, AutocompleteSuggestion } = await google.maps.importLibrary('places');

    // Add an initial request body.
    const latitude = this.geoLocationObj.coords.latitude;
    const longitude = this.geoLocationObj.coords.longitude;
    const request = {
      input: event,
      // locationRestriction: { west: -122.44, north: 37.8, east: -122.39, south: 37.78 },
      origin: { lat: latitude, lng: longitude },
      // includedPrimaryTypes: ['restaurant'],
      // fields: ['displayName', 'formattedAddress', 'id', 'location'],
      language: 'en-US',
      region: 'ca',
    };

    // Create a session token.
    const token = new AutocompleteSessionToken();
    // Add the token to the request.
    // @ts-ignore
    request.sessionToken = token;
    // Fetch autocomplete suggestions.
    const { suggestions } = await AutocompleteSuggestion.fetchAutocompleteSuggestions(request);

    // const tempAr1 = [];
    // for (const suggestion2 of suggestions) {
    //   console.log(suggestion2);
    //   const place = suggestion2.placePrediction.toPlace(); // Get first predicted place.
    //   const result = await place.fetchFields({
    //     fields: ['displayName', 'formattedAddress', 'id', 'location'],
    //   });
    //   tempAr1.push(result);
    // }

    return suggestions;
  }

  async convertToPlace(tempSuggestion: any) {
    const place = tempSuggestion.placePrediction.toPlace(); // Get first predicted place.
    const result = await place.fetchFields({
      fields: ['displayName', 'formattedAddress', 'id', 'location'],
    });
    return result;
  }
}
