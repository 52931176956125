import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// User imports
import { OverHeadDoor } from 'src/app/model/OverHeadDoor';
import { OhdoorService } from 'src/app/service/ohdoor.service';
import { DealerService } from 'src/app/service/dealer.service';
import { BuildingService } from 'src/app/service/building.service';
import { SegmentValue } from '@ionic/angular';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-door-list',
  templateUrl: './door-list.component.html',
  styleUrls: ['./door-list.component.scss'],
})
export class DoorListComponent implements OnInit {
  @Output() doorListEventEmitter = new EventEmitter<number>();
  title2DisplayInPage = 'My Doors';
  doors: OverHeadDoor[] = [];
  canCreateDoors = false;
  selectedDealerID = '';
  buildingID = '';

  isSearchEnabled = false;
  filteredDoors: OverHeadDoor[] = [];
  viewMode: SegmentValue = 'tile';

  // Routing Variables
  isDoorStickerRoute = false;

  constructor(
    private ohdService: OhdoorService,
    private route: ActivatedRoute,
    private dealerService: DealerService,
    private buildingService: BuildingService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      if (params.doorStickerRoute) {
        this.isDoorStickerRoute = true;
      }
    });

    this.route.data.subscribe(async (data) => {
      if (data.title2DisplayInPage !== undefined) {
        this.title2DisplayInPage = data.title2DisplayInPage;
        this.selectedDealerID = this.dealerService.getSelectedDealer().id;
        this.route.paramMap.subscribe(async (params) => {
          this.buildingID = params.get('buildingID');
          if (this.buildingID && this.buildingService.getSelectedBuilding() == null) {
            try {
              const building = await this.buildingService.read(this.buildingID);
              this.buildingService.setSelectedBuilding(building);
              this.loadDoors();
            } catch (error) {
              console.error('Failed to load building:', error);
            }
          } else {
            this.loadDoors();
          }
        });
      } else {
        this.loadDoors();
      }

      if (this.userService.isUserSmartDoorAdmin()) {
        this.canCreateDoors = true;
      } else {
        this.canCreateDoors = this.buildingService.canCreateDoors();
      }
    });
  }

  /*
    @Description: Function to set door
    @Comments:
    @Coders: jaam111000
  */
  async loadDoors() {
    await this.ohdService.getAllDoors().then((data) => {
      this.doors = data;
      this.doorListEventEmitter.emit(data.length);
    });
  }

  /*
    @Description: Function to set door
    @Comments:
    @Coders: jaam111000
  */
  setDoor(ohd: OverHeadDoor) {
    this.ohdService.setSelectedDoor(ohd);
    if (this.isDoorStickerRoute) {
      this.router.navigate(['link'], {
        relativeTo: this.route,
        queryParams: {
          doorStickerRoute: true,
        },
      });
      return;
    } else {
      this.router.navigate([ohd.id, 'view'], { relativeTo: this.route });
    }
  }

  route2CreatePage() {
    this.ohdService.setSelectedDoor(undefined);
    this.router.navigate(['./create'], {
      relativeTo: this.route,
    });
  }

  /*
    @Description
    @Comments:
    @Coders: jaam111000
  */
  searchChange(event: any) {
    const searchTerm = event.toLowerCase();
    if (searchTerm.length > 0) {
      this.isSearchEnabled = true;
      // console.log('Filtering your dealers...', searchTerm);
      this.filteredDoors = this.doors.filter((door) => door.location.toLowerCase().includes(searchTerm));
    } else {
      this.isSearchEnabled = false;
      this.filteredDoors = [];
    }
  }

  /*
        @Description
        @Comments:
        @Coders: jaam111000
      */
  getCurrentDoors() {
    return this.isSearchEnabled ? this.filteredDoors : this.doors;
  }

  /*
    @Description
    @Comments:
    @Coders: jaam111000
  */
  changeViewMode(isListView: boolean): void {
    if (isListView) {
      this.viewMode = 'list';
    } else {
      this.viewMode = 'tile';
    }
  }

  /*
    @Description
    @Comments:
    @Coders: jaam111000
  */
  navigateAddSticker() {
    this.router.navigate(['add'], {
      queryParams: { doorStickerRoute: true },
      relativeTo: this.route,
    });
  }
}
