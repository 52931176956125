import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// User Imports
import { User } from 'src/app/model/User';
import { UserService } from 'src/app/service/user.service';
import { DealerService } from 'src/app/service/dealer.service';

@Component({
  selector: 'app-dealer-users',
  templateUrl: './dealer-users.component.html',
  styleUrls: ['./dealer-users.component.scss'],
})
export class DealerUsersComponent implements OnInit {
  users: User[];
  isLoading = true;
  canAddDealerUsers = false;
  constructor(private dealerService: DealerService, private route: ActivatedRoute, private userService: UserService) {}

  /*
    @Description: Angular function to execute after component mount
    @Comments:
    @Coders:
  */
  ngOnInit() {
    this.getUsersFromService();
  }

  /*
    @Description: Public Method get all users associated to dealer
    @Comments:
    @Coders: jaam111000
  */
  async getUsersFromService() {
    this.route.paramMap.subscribe(async (params) => {
      await this.dealerService.getAllUsersForDealer(params.get('dealerID')).then((users) => {
        this.isLoading = false;
        this.users = users;
        // Check Permissions
        this.canAddDealerUsers = this.dealerService.canCreateDealerUsers() || this.userService.isUserSmartDoorAdmin();
      });
    });
  }
}
