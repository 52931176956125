/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// User Imports
import { filter } from 'rxjs';
import { UserService } from './service/user.service';
import { DealerService } from './service/dealer.service';
import { SecurityContext } from '@angular/core';
import { Auth, getAuth } from '@angular/fire/auth';
import { SecurityService } from './service/security.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isAdminPage = false;
  isLoginPage = false;
  constructor(private router: Router, private userService: UserService, public auth: Auth) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.isAdminPage = event.urlAfterRedirects.startsWith('/admin');
      this.isLoginPage = event.urlAfterRedirects.startsWith('/login');
      if (!this.isLoginPage) {
        this.defineUserObj();
        this.userService.getSDAdmin();
      }
    });
  }

  ngOnInit(): any {
    const API_KEY = environment.mapKey;

    this.loadScript(API_KEY)
      .then(() => {
        console.log('Google Maps API successfully loaded');
        // Initialize Google Maps or Places API related functions here
      })
      .catch((error) => {
        console.error('Error loading Google Maps API', error);
      });
  }

  loadScript(apiKEY: string) {
    return new Promise<void>((resolve, reject) => {
      // Check if the script is already loaded
      if (document.querySelector(`script[src*="maps.googleapis.com"]`)) {
        resolve();
        return;
      }

      // Create the script tag, set the appropriate attributes
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKEY}&libraries=places&v=beta&callback=initMap`;
      script.async = true;
      script.defer = true;

      // Attach your callback function to the `window` object
      (window as any).initMap = () => {
        resolve();
      };

      script.onerror = (error) => {
        reject(error);
      };

      // Append the 'script' element to 'head'
      document.head.appendChild(script);
    });
  }

  async defineUserObj() {
    this.userService.setUserCollectionObj(await this.userService.getUserFromCollection(getAuth().currentUser.uid));
  }
}
