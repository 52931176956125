import { ModalController } from '@ionic/angular';
import { BarcodeService } from 'src/app/service/barcode.service';
import { BrowserMultiFormatReader, Result } from '@zxing/library';
import { Component, AfterViewInit, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss'],
})
export class QrScannerComponent implements AfterViewInit, OnDestroy {
  isActive = false;
  @ViewChild('videoElement', { static: false }) videoElement: ElementRef<HTMLVideoElement>;
  private reader = new BrowserMultiFormatReader();

  constructor(
    private cd: ChangeDetectorRef,
    private barcodeService: BarcodeService,
    private modalController: ModalController
  ) {}

  ngAfterViewInit() {
    this.startScanning();
  }

  getMyModalController(): ModalController {
    return this.modalController;
  }

  toggleScanning() {
    this.isActive = !this.isActive;
    this.cd.detectChanges();
    if (this.isActive && this.videoElement && this.videoElement.nativeElement) {
      this.startScanning();
    }
  }

  startScanning() {
    try {
      if (this.videoElement && this.videoElement.nativeElement) {
        this.isActive = true;
        this.reader.decodeFromVideoDevice(null, this.videoElement.nativeElement, (result: Result, err: any) => {
          if (result) {
            this.barcodeService.processQRcode(result.getText());
            this.stopScanning();
            this.modalController.dismiss(); // Close the modal after scanning
          }
          if (err) {
            // Handle error if necessary
          }
        });
      }
    } catch (error) {
      // Handle error if necessary
    }
  }

  stopScanning() {
    this.reader.reset();
    this.isActive = false;
  }

  ngOnDestroy() {
    this.stopScanning();
  }
}
