import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private messages: any[] = [];
  private messagesSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor() {
    this.loadMessages();
  }

  private loadMessages(): void {
    const storedMessages = localStorage.getItem('chatMessages');
    if (storedMessages) {
      this.messages = JSON.parse(storedMessages);
      this.messagesSubject.next(this.messages);
    }
  }

  private saveMessages(): void {
    localStorage.setItem('chatMessages', JSON.stringify(this.messages));
  }

  getMessages(): Observable<any[]> {
    return this.messagesSubject.asObservable();
  }

  addMessage(message: any): void {
    this.messages.push(message);
    this.messagesSubject.next(this.messages);
    this.saveMessages();
  }

  clearMessages(): void {
    this.messages = [];
    this.messagesSubject.next(this.messages);
    this.saveMessages();
  }
}