import { Dealer } from 'src/app/model/Dealer';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DealerService } from 'src/app/service/dealer.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { GeolocationService } from 'src/app/service/geolocation.service';
import { AddressGoogle } from 'src/app/model/AddressGoogle';

@Component({
  selector: 'app-dealer-ae',
  templateUrl: './dealer-ae.component.html',
  styleUrls: ['./dealer-ae.component.scss'],
})
export class DealerAeComponent implements OnInit {
  dealerLogo: Event;
  tempDealer: Dealer;
  dealerForm: UntypedFormGroup;

  dealerID = '';
  createDealerFlag = false;

  // Address variables
  suggestions: Array<any> = [];
  suggestedAddress = '';

  constructor(
    private dealerService: DealerService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastController: ToastController,
    private geolocationService: GeolocationService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.route.paramMap.subscribe(async (params) => {
      if (params.get('dealerID') == null) {
        this.createDealerFlag = true;
      } else {
        this.dealerID = params.get('dealerID');
        this.getDealer2Edit();
      }
    });

    // Instantiate Temp Object
    this.tempDealer = {
      id: '',
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      contactPhone: '',
      active: true,
      logo: '',
      address: {
        displayName: '',
        formattedAddress: '',
        id: '',
        longitude: '',
        latitude: '',
        googleMapsUri: '',
      },
    };
  }

  /*
    @Description:
    @Comments:
    @Coders: allansmith
  */
  buildForm(): void {
    this.dealerForm = this.formBuilder.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contactPhone: ['', Validators.required],
      logo: [''],
    });
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  async getDealer2Edit() {
    if (this.dealerService.getSelectedDealer() != null) {
      this.tempDealer = await this.dealerService.getById(this.dealerID);
      this.dealerForm.setValue({
        name: this.tempDealer.name,
        address: this.tempDealer.address.formattedAddress,
        firstName: this.tempDealer.firstName,
        lastName: this.tempDealer.lastName,
        email: this.tempDealer.email,
        contactPhone: this.tempDealer.contactPhone,
        logo: this.tempDealer.logo || '',
      });
    }
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  async onSubmit() {
    if (this.dealerForm.invalid) {
      this.presentErrorToast('middle'); // Calling Toast to show inform about erorr
      console.log('Invalid Input for Creating Dealer', this.dealerForm.invalid);
      return;
    }
    this.presentInitialToast('top');

    // Handle Form Inputs
    this.tempDealer.name = this.dealerForm.get('name').value;
    this.tempDealer.firstName = this.dealerForm.get('firstName').value;
    this.tempDealer.lastName = this.dealerForm.get('lastName').value;
    this.tempDealer.email = this.dealerForm.get('email').value;
    this.tempDealer.contactPhone = this.dealerForm.get('contactPhone').value;
    if (this.dealerLogo) {
      const downloadUrl = await this.dealerService.addDealerLogo(this.dealerLogo, this.dealerForm.get('name').value);
      this.dealerForm.patchValue({ logo: downloadUrl });
      this.dealerForm.get('logo').updateValueAndValidity();
      this.tempDealer.logo = this.dealerForm.get('logo').value;
    }

    try {
      if (!this.createDealerFlag) {
        await this.dealerService
          .update(this.dealerService.getSelectedDealer().id, this.tempDealer)
          .then(async (result) => {
            await this.delay(250); // Wait for 500ms to appreciate the first toast
            this.presentLastToast('top'); // Calling Toast to show form was saved in backend without problems
          });
      } else {
        await this.dealerService.create(this.tempDealer).then(async (result) => {
          await this.delay(250); // Wait for 500ms to appreciate the first toast
          this.presentLastToast('top'); // Calling Toast to show form was saved in backend without problems
        });
      }
    } catch (error) {
      console.log('Error when adding dealer...', error);
      this.presentErrorToast('middle'); // Calling Toast to show inform about erorr
    }
    this.resetForm();
  }

  // ========================= Toast Form Enhancement Feature========

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async presentErrorToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'Dealer was not saved, try again or contact support team...',
      duration: 2500,
      position,
    });

    await toast.present();
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async presentInitialToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'Dealer is saving...',
      duration: 1000,
      position,
    });

    await toast.present();
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async presentLastToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: 'Dealer has been saved...',
      duration: 2500,
      position,
    });

    await toast.present();
  }

  /*
    @Description: Helper method to create a delay
    @Comments:
    @Coders: jaam111000
  */
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // ======================= Google Places Feature ==========

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  onInput(event: any): void {
    const query = event.target.value;
    if (query.length > 0) {
      this.fetchSuggestionsfromService(query);
    } else {
      this.suggestions = [];
    }
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  async fetchSuggestionsfromService(event: string) {
    this.suggestions = await this.geolocationService.fetchSuggestionsAutoComplete(event);
    // console.log('your suggestions returned are...', this.suggestions[0].Eg.di['0']);
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  async selectSuggestion(suggestion: any): Promise<void> {
    // console.log('Your picked address is...', suggestion);
    // Define address
    this.suggestions = [];
    // this.suggestedAddress = `${suggestion.displayName} — ${suggestion.formattedAddress}`;
    this.suggestedAddress = suggestion.Eg.di['0'][2][0];

    // Update the form
    this.dealerForm.controls.address.setValue(this.suggestedAddress);

    // TURN Suggestion Into Places
    const placeTemp = await this.geolocationService.convertToPlace(suggestion);
    // console.log('Your temp place is...', placeTemp);

    // Store Other Address fields in Dealer Object
    // console.log('Your place object returned by the api is...', suggestion);
    this.tempDealer.address.formattedAddress = placeTemp.place.formattedAddress;
    this.tempDealer.address.displayName = placeTemp.place.displayName;
    this.tempDealer.address.id = placeTemp.place.id;
    this.tempDealer.address.longitude = placeTemp.place.location.lng();
    this.tempDealer.address.latitude = placeTemp.place.location.lat();

    // Generate Google Maps URI using longitude and latitude
    // eslint-disable-next-line max-len
    this.tempDealer.address.googleMapsUri = `https://www.google.com/maps/search/?api=1&query=${placeTemp.place.location.lat()},${placeTemp.place.location.lng()}`;

    // console.log('Your temp dealer to save...', this.tempDealer.address);
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  async deleteDealer() {
    this.tempDealer.active = false;
    await this.dealerService.update(this.tempDealer.id, this.tempDealer);
    this.resetForm();
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  async takePicture(event: any): Promise<void> {
    console.log('The event is:', event);
    this.dealerLogo = event;
  }

  /*
    @Description:
    @Comments:
    @Coders: jaam111000
  */
  resetForm(): void {
    // this.dealerForm.reset();
    // this.router.navigate(['./home']);
  }
}
