import { NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import {
  MAIN_AUTH_GUARD,
  AUTH_ADMIN_GUARD,
  // Dealer Guards
  // AUTH_DEALER_CREATE_GUARD,
  AUTH_DEALER_READ_GUARD,
  // User Guards
  AUTH_USER_CREATE_GUARD,
  AUTH_USER_READ_GUARD,
  AUTH_USER_UPDATE_GUARD,
  // Customer Guards
  AUTH_CUSTOMER_CREATE_GUARD,
  AUTH_CUSTOMER_READ_GUARD,
  // Building Guards
  AUTH_BUILDING_GUARD,
  // Door Guards
  AUTH_DOOR_GUARD,
  // Inspection Guards
  AUTH_INSPECTION_GUARD,
  AUTH_HOME_GUARD,
  AUTH_STICKER_GUARD,
} from './service/auth-guard.service';

// User Components
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

import { DealerSelectComponent } from './pages/dealer-select/dealer-select.component';
import { DealerUsersComponent } from './pages/dealer-users/dealer-users.component';
import { DealerDetailsComponent } from './pages/dealer-details/dealer-details.component';

// import { CustomerListComponent } from './pages/admin-customer-list/customer-list.component';
import { CustomerUsersListComponent } from './pages/customer-users-list/customer-users-list.component';
import { CustomerDetailsComponent } from './pages/customer-details/customer-details.component';

// import { BuildingListComponent } from './pages/admin-building-list/building-list.component';
import { BuildingAeComponent } from './pages/building-ae/building-ae.component';

import { DoorListComponent } from './pages/door-list/door-list.component';
// import { DoorAddComponent } from './pages/door-add/door-add.component';
// import { OhdoorViewComponent } from './pages/ohdoor-view/ohdoor-view.component';
import { DoorDetailsComponent } from './pages/door-details/door-details.component';

import { InspectionItemComponent } from './pages/inspection-item/inspection-item.component';
import { SafetyInspectionComponent } from './pages/safety-inspection/safety-inspection.component';
import { AdminComponent } from './pages/admin/admin.component';
import { AdminStickerComponent } from './pages/admin-sticker/admin-sticker.component';
import { DoorStickerLinkComponent } from './pages/door-sticker-link/door-sticker-link.component';
import { DoorStickerLinkEndComponent } from './pages/door-sticker-link-end/door-sticker-link-end.component';
import { DoorAEComponent } from './pages/door-ae/door-ae.component';
import { DealerAeComponent } from './pages/dealer-ae/dealer-ae.component';
import { CustomerAeComponent } from './pages/customer-ae/customer-ae.component';
import { CustomerUsersAeComponent } from './pages/customer-users-ae/customer-users-ae.component';
import { DealerUsersAeComponent } from './pages/dealer-users-ae/dealer-users-ae.component';
import { BuildingDetailsComponent } from './pages/building-details/building-details.component';
import { CustomerListComponent } from './pages/customer-list/customer-list.component';
import { DealerListComponent } from './pages/dealer-list/dealer-list.component';
import { BuildingListComponent } from './pages/building-list/building-list.component';

export const defaultRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    canActivate: [MAIN_AUTH_GUARD],
    component: LoginComponent,
  },
  {
    path: 'signup/:inviteId',
    component: SignupComponent,
  },
  { path: '404', component: NotFoundComponent },
];

export const sideBarRoutes: Routes = [
  {
    path: 'home',
    canActivate: [AUTH_HOME_GUARD],
    component: HomeComponent,
    data: {
      title: 'Home',
      icon: 'home',
    },
  },
  {
    path: 'dealer',
    component: DealerSelectComponent,
    canActivate: [AUTH_DEALER_READ_GUARD],
    data: {
      title: 'Dealers',
      icon: 'globe',
    },
  },
];

export const appRoutes: Routes = [
  {
    path: 'door-sticker/:doorStickerID',
    component: DoorStickerLinkComponent,
    canActivate: [AUTH_STICKER_GUARD],
    children: [
      {
        path: 'dealers',
        component: DealerListComponent,
      },
      {
        path: 'dealers/:dealerID/customers',
        component: CustomerListComponent,
      },
      {
        path: 'dealers/:dealerID/customers/:customerID/buildings',
        component: BuildingListComponent,
      },
      {
        path: 'dealers/:dealerID/customers/:customerID/buildings/:buildingID/doors',
        component: DoorListComponent,
      },
      {
        path: 'dealers/:dealerID/customers/:customerID/buildings/:buildingID/doors/add',
        component: DoorAEComponent,
      },
      {
        path: 'dealers/:dealerID/customers/:customerID/buildings/:buildingID/doors/link',
        component: DoorStickerLinkEndComponent,
        canActivate: [AUTH_STICKER_GUARD],
      },
    ],
  },
  // ===================== Other Routes ==================
  {
    path: 'admin',
    canActivate: [AUTH_ADMIN_GUARD],
    children: [
      {
        path: 'view',
        component: AdminComponent,
      },
      {
        path: 'sticker/add',
        component: AdminStickerComponent,
      },
      //  Dealer CRUD Routes
      // {
      //   path: 'dealer',
      //   component: DealerListComponent,
      // },
      {
        path: 'dealer/add',
        component: DealerAeComponent,
      },
      {
        path: 'dealer/:dealerID',
        component: DealerAeComponent,
      },
      // Customer CRUD Routes
      // {
      //   path: 'customer/dealer',
      //   component: DealerListComponent,
      // },
      // {
      //   path: 'customer/dealer/:dealerID',
      //   component: CustomerListComponent,
      // },
      {
        path: 'customer/dealer/:dealerID/create',
        component: CustomerAeComponent,
      },
      {
        path: 'customer/dealer/:dealerID/:customerID',
        component: CustomerAeComponent,
      },
      // Building CRUD Routes
      // {
      //   path: 'building/dealer',
      //   component: DealerListComponent,
      // },
      // {
      //   path: 'building/dealer/:dealerID',
      //   component: CustomerListComponent,
      // },
      // {
      //   path: 'building/dealer/:dealerID/:customerID',
      //   component: BuildingListComponent,
      // },
      {
        path: 'building/dealer/:dealerID/:customerID/create',
        component: BuildingAeComponent,
      },
      {
        path: 'building/dealer/:dealerID/:customerID/:buildingID',
        component: BuildingAeComponent,
      },
      // Door CRUD Routes
      // {
      //   path: 'door/dealer',
      //   component: DealerListComponent,
      // },
      // {
      //   path: 'door/dealer/:dealerID',
      //   component: CustomerListComponent,
      // },
      // {
      //   path: 'door/dealer/:dealerID/:customerID',
      //   component: BuildingListComponent,
      // },
      {
        path: 'door/dealer/:dealerID/:customerID/:buildingID',
        component: DoorListComponent,
      },
      {
        path: 'door/dealer/:dealerID/:customerID/:buildingID/create',
        component: DoorAEComponent,
      },
      {
        path: 'door/dealer/:dealerID/:customerID/:buildingID/:doorID/view',
        component: DoorAEComponent,
      },
    ],
  },
  {
    path: 'dealer/:dealerID',
    canActivate: [AUTH_DEALER_READ_GUARD],
    children: [
      { path: 'view', component: DealerDetailsComponent },
      { path: 'edit', component: DealerAeComponent },
      {
        path: 'users',
        component: DealerUsersComponent,
        canActivate: [AUTH_USER_READ_GUARD],
        data: {
          title: 'View Users',
          icon: 'globe',
        },
      },
      {
        path: 'users/invite',
        component: DealerUsersAeComponent,
        data: {
          title: 'Invite Users',
          icon: 'globe',
        },
      },
      {
        path: 'user/:userID/edit',
        component: DealerUsersAeComponent,
        canActivate: [AUTH_USER_UPDATE_GUARD],
        data: {
          title: 'Edit User',
          icon: 'add',
        },
      },
      // {
      //   path: 'customer',
      //   component: CustomerListComponent,
      //   canActivate: [AUTH_CUSTOMER_READ_GUARD],
      //   data: {
      //     title: 'Customers',
      //     icon: 'globe',
      //   },
      // },
      {
        path: 'customer/create',
        component: CustomerAeComponent,
        canActivate: [AUTH_CUSTOMER_CREATE_GUARD],
        data: {
          title: 'New Customer',
          icon: 'add',
        },
      },
      {
        path: 'customer/:customerID',
        children: [
          {
            path: 'view',
            component: CustomerDetailsComponent,
            canActivate: [AUTH_CUSTOMER_READ_GUARD],
          },
          {
            path: 'edit',
            component: CustomerAeComponent,
            canActivate: [AUTH_CUSTOMER_READ_GUARD],
          },
          {
            path: 'users',
            component: CustomerUsersListComponent,
            canActivate: [AUTH_CUSTOMER_READ_GUARD],
            data: {
              title: 'Customer Users',
              icon: 'list',
            },
          },
          {
            path: 'users/invite',
            component: CustomerUsersAeComponent,
            canActivate: [AUTH_CUSTOMER_CREATE_GUARD],
            data: {
              title: 'Add Customer User',
              icon: 'list',
            },
          },
          {
            path: 'users/:uuid/edit',
            component: CustomerUsersAeComponent,
            canActivate: [AUTH_CUSTOMER_CREATE_GUARD],
            data: {
              title: 'Edit Customer User',
              icon: 'list',
            },
          },
          // {
          //   path: 'building',
          //   component: BuildingListComponent,
          //   canActivate: [AUTH_BUILDING_GUARD],
          //   data: {
          //     title: 'Buildings',
          //     icon: 'list',
          //   },
          // },
          {
            path: 'building/create',
            canActivate: [AUTH_BUILDING_GUARD],
            component: BuildingAeComponent,
            data: {
              title: 'New Building',
              icon: 'add',
            },
          },
          {
            path: 'building/:buildingID',
            children: [
              { path: 'edit', component: BuildingAeComponent, canActivate: [AUTH_BUILDING_GUARD] },
              {
                path: 'door',
                canActivate: [AUTH_DOOR_GUARD],
                component: BuildingDetailsComponent,
                data: {
                  title: 'Doors',
                  icon: 'list',
                },
              },
              { path: 'door/create', component: DoorAEComponent, canActivate: [AUTH_DOOR_GUARD] },
              {
                path: 'door/:doorID',
                children: [
                  { path: 'view', component: DoorDetailsComponent, canActivate: [AUTH_INSPECTION_GUARD] },
                  { path: 'edit', component: DoorAEComponent, canActivate: [AUTH_DOOR_GUARD] },
                  {
                    path: 'inspection/:inspectionId',
                    children: [{ path: 'view', component: SafetyInspectionComponent }],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

// Define Routes for App
export const allRoutes: Routes = [...defaultRoutes, ...sideBarRoutes, ...appRoutes];

@NgModule({
  imports: [RouterModule.forRoot(allRoutes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  // Members of Class
  private appPages = sideBarRoutes;

  // Constructor
  constructor(public router: Router) {}

  /*
    @Description: Public Method to get array of pages
    @Comments:
    @Coders: jaam111000
  */
  getMyAppPages() {
    return this.appPages;
  }
}
