import { getAuth } from '@angular/fire/auth';
import { IonAccordionGroup, MenuController } from '@ionic/angular';
import { Component, OnInit, ViewChild } from '@angular/core';

// User Imports
import { Dealer } from 'src/app/model/Dealer';
import { DealerService } from 'src/app/service/dealer.service';
import { SecurityService } from 'src/app/service/security.service';
import { CustomerService } from 'src/app/service/customer.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-dealer-details',
  templateUrl: './dealer-details.component.html',
  styleUrls: ['./dealer-details.component.scss'],
})
export class DealerDetailsComponent implements OnInit {
  @ViewChild('accordionGroup', { static: true }) accordionGroup: IonAccordionGroup;
  isLoading = true;
  tempDealer: Dealer = null;
  accessToDealerMenuFlag = false;
  dealerDetailsIsExpanded = false;

  // Class construtor
  constructor(
    private securityService: SecurityService,
    private dealerService: DealerService,
    private customerService: CustomerService,
    private userService: UserService,
    private menuCtrl: MenuController,
    private router: Router
  ) {}

  // Angular function
  ngOnInit() {
    this.loadUpDealerDetails();

    // Cached Object Observable
    this.dealerService.dealerTemp$.subscribe((dealer) => {
      // console.log('Your updated dealer is...', dealer);
      this.tempDealer = dealer;
    });
  }

  openSecondMenu() {
    /**
     * Open the menu by menu-id
     * We refer to the menu using an ID
     * because multiple "start" menus exist.
     */
    this.menuCtrl.open('second-menu');
  }

  getMyDealerService(): DealerService {
    return this.dealerService;
  }

  getMySecurityService(): SecurityService {
    return this.securityService;
  }
  getMyCustomerService(): CustomerService {
    return this.customerService;
  }
  getMyUserService(): UserService {
    return this.userService;
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async loadUpDealerDetails() {
    if (this.userService.isUserSmartDoorAdmin()) {
      this.accessToDealerMenuFlag = true;
    } else {
      const userID = getAuth().currentUser.uid;
      const user = this.userService.getUserCollectionObj();
      if (user.customers === undefined) {
        await this.dealerService.loadDealerUserSecurity(userID);
      }
      this.accessToDealerMenuFlag = this.dealerService.canAccessDealerMenu();
    }
    this.isLoading = false;
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  toggleAccordion = () => {
    const nativeEl = this.accordionGroup;
    if (nativeEl.value === 'first') {
      nativeEl.value = undefined;
    } else {
      nativeEl.value = 'first';
    }
  };

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  async navigate(path: string[]) {
    await this.router.navigate(path);
  }

  /*
    @Description: Public Method
    @Comments:
    @Coders: jaam111000
  */
  handleCustomeLength(event: number) {
    if (event === 0) {
      // console.log('We got your event...', event);
      this.toggleAccordion();
    }
  }
}
