import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SegmentValue } from '@ionic/angular';
import { Building } from 'src/app/model/Building';
import { BuildingService } from 'src/app/service/building.service';
import { DealerService } from 'src/app/service/dealer.service';

@Component({
  selector: 'app-building-list',
  templateUrl: './building-list.component.html',
  styleUrls: ['./building-list.component.scss'],
})
export class BuildingListComponent implements OnInit {
  buildings: Building[] = [];
  filteredBuildings: Building[] = [];
  isSearchEnabled = false;
  viewMode: SegmentValue = 'tile';

  // Routing Variables
  isDoorStickerRoute = false;

  constructor(
    private route: ActivatedRoute,
    private dealerService: DealerService,
    private buildService: BuildingService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      if (params.doorStickerRoute) {
        this.isDoorStickerRoute = true;
      }
    });
    await this.buildService.getAllBuildings();
  }

  /*
    @Description: Public Method to set Building Service
    @Comments:
    @Coders: Rohan-16
  */
  getMyBuildingService(): BuildingService {
    return this.buildService;
  }

  /*
    @Description: Public Method to set Building Object
    @Comments:
    @Coders: jaam111000
  */
  setBuilding(building: Building) {
    this.buildService.setSelectedBuilding(building);
    if (this.isDoorStickerRoute) {
      this.router.navigate([building.id, 'doors'], {
        relativeTo: this.route,
        queryParams: {
          doorStickerRoute: true,
        },
      });
    } else {
      this.router.navigate(['../building', building.id, 'door'], { relativeTo: this.route });
    }
  }

  /*
    @Description
    @Comments:
    @Coders: jaam111000
  */
  searchChange(event: any) {
    const searchTerm = event.toLowerCase();
    if (searchTerm.length > 0) {
      this.isSearchEnabled = true;
      // console.log('Filtering your dealers...', searchTerm);
      this.filteredBuildings = this.buildService
        .getBuildings()
        .filter((building) => building.address.formattedAddress.toLowerCase().includes(searchTerm));
    } else {
      this.isSearchEnabled = false;
      this.filteredBuildings = [];
    }
  }

  /*
      @Description
      @Comments:
      @Coders: jaam111000
    */
  getCurrentBuildings() {
    return this.isSearchEnabled ? this.filteredBuildings : this.buildService.getBuildings();
  }

  /*
      @Description
      @Comments:
      @Coders: jaam111000
    */
  changeViewMode(isListView: boolean): void {
    if (isListView) {
      this.viewMode = 'list';
    } else {
      this.viewMode = 'tile';
    }
  }
}
