// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-grid, ion-col {
  padding: 0px;
  margin: 0px;
}

#image {
  width: 100%;
  min-height: 420px;
  height: 100vh;
  padding: 0px;
  margin: 0px;
  display: flex;
}

ion-row {
  justify-content: center;
  align-items: center;
}

ion-card {
  height: -moz-fit-content;
  height: fit-content;
  max-width: 500px;
  margin: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

ion-card h1 {
  font-weight: 700;
}

@media screen and (max-width: 575px) {
  #background {
    width: 100%;
  }
  #foreground {
    position: fixed;
  }
  ion-card {
    margin: 4px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/login/login.component.scss"],"names":[],"mappings":"AACA;EAEG,YAAA;EACA,WAAA;AADH;;AAGA;EACG,WAAA;EACA,iBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;AAAH;;AAGA;EACG,uBAAA;EACA,mBAAA;AAAH;;AAEA;EACG,wBAAA;EAAA,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,yCAAA;AACH;;AAGA;EAEG,gBAAA;AADH;;AAKA;EACG;IACG,WAAA;EAFJ;EAIC;IACG,eAAA;EAFJ;EAIE;IAEG,WAAA;EAHL;AACF","sourcesContent":[" \r\nion-grid, ion-col{\r\n   \r\n   padding: 0px;\r\n   margin: 0px;\r\n}\r\n#image{\r\n   width: 100%;\r\n   min-height: 420px;\r\n   height: 100vh;\r\n   padding: 0px;\r\n   margin: 0px;\r\n   display: flex;\r\n}\r\n\r\nion-row{\r\n   justify-content: center;\r\n   align-items: center;\r\n}\r\nion-card{\r\n   height: fit-content;\r\n   max-width: 500px;\r\n   margin: auto;\r\n   box-shadow: 0 8px 16px rgba(0,0,0,0.5);\r\n \r\n}\r\n\r\nion-card h1{\r\n  \r\n   font-weight: 700;\r\n  \r\n}\r\n \r\n@media screen and (max-width: 575px) {\r\n   #background{\r\n      width: 100%;\r\n   }\r\n   #foreground{\r\n      position: fixed;\r\n   }\r\n    ion-card{\r\n \r\n       margin: 4px;\r\n    }\r\n  \r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
